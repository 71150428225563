import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import * as moment from 'moment';
import { withTranslation } from "react-i18next"
import "react-toastify/dist/ReactToastify.css";
import { getProjectStatus, getAllProjectAction, deleteProject, getSearchAllProject, updateProjectStatus } from "../../../store/actions"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { API_URL } from 'helpers/app_url';
import axios from "axios"
import TitleDropdowns from "components/dropdowns/Other/titleDropdowns"
import RsLink from "components/buttons/rsLink"
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../../components/Common/DeleteModal";
import Switch from "react-switch"
import ProjectChart from "./chart/project-dashboard";
import ProjectMenu from "./menu/all-project"
import {
  Col,
  Container,
  Row,
  Table,
  Card,
  CardBody,
  Label,
  Input,
  FormGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardFooter,
  CardTitle,
  Form
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import Moment from 'react-moment';
import RsCard from "components/Rscards/Rscard";
import RSBarChart from "components/charts/RSBarChart";
import ProjectStatusGridOption from "components/dropdowns/project/ProjectStatus-grid";
import AppoinmentObject from "store/settings/Appointment/reducer";

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalProject: 0,
      totalClient: 0,
      totalLead: 0,
      totalLeadtoClient: 0,
      projectMonthNames: [],
      projectCounts: [],
      projecttopfive: [],
      projectMonths: [],
      projectLead: [],
      projectLeadClient: [],
      projects: [],
      project: '',
      projectStatus: [],
      checked: false,
      complitionYear: '',
      city: '',
      strintingPrice: '',
      status: '',
      reports: [
      ],
      filtered: [],
      modal: false,
    }

    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleComplitionYear = (event) => {
    this.setState({ complitionYear: event.target.value });
  };

  handleCityChange = (event) => {
    this.setState({ city: event.target.value });
  };

  handlePriceChange = (event) => {
    this.setState({ strintingPrice: event.target.value });
  };
  handleStatusChange = (event) => {
    this.setState({ status: event.target.value });
  };


  componentDidMount() {
    const { onGetAllProject, onGetSearchAllProject, onGetProjectStatus } = this.props;
    // var object = {
    //   complitionYear: this.state.complitionYear,
    //   city: this.state.city,
    //   strintingPrice: this.state.strintingPrice,
    //   status: this.state.status,
    //   agentId:3,
    //   brokerId:2,
    //   isActive:true
    // };
    // const objString = '?' + new URLSearchParams(object).toString();
    // onGetSearchAllProject(objString)
    onGetAllProject(localStorage.getItem('userId'), localStorage.getItem('brokerId'));
    onGetProjectStatus(localStorage.getItem('userId'));

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;

    axios.get(`${API_URL}ProjectExt/getProjectLunchMonthByAgent`, {
      params: {
        agentId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ projectMonthNames: res.data.months, projectCounts: res.data.counts });
      });

    axios.get(`${API_URL}ProjectExt/getProjectCountByAgent`, {
      params: {
        agentId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {

        this.setState({ totalProject: res.data.countProject, totalClient: res.data.countClient, totalLead: res.data.countLead, totalLeadtoClient: res.data.countLeadtoClient });
      });

    axios.get(`${API_URL}ProjectExt/getProjectTopFiveProjectsByAgent`, {
      params: {
        agentId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ projecttopfive: res.data });
      });

    axios.get(`${API_URL}ProjectExt/getProjectCitiesDistributionByAgent`, {
      params: {
        agentId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ projectMonths: res.data.cities, projectLead: res.data.leads, projectLeadClient: res.data.clientLeads });
      });
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (project) => {
    this.setState({ project: project });
    this.setState({ deleteModal: true });
  };

  handleDeleteProject = () => {
    const { onDeleteProject } = this.props;
    const { project } = this.state;
    //console.log(project);
    if (project.id !== undefined) {
      onDeleteProject(project.id);
      this.setState({ deleteModal: false });
    }
  };
  handleChange(e) {
    var value = e.target.value;
    this.setState({
      searchString: value,
      filtered: this.state.data.filter(e =>
        Object.values(e)
          .join(" ")
          .toLowerCase()
          .match(value)
      )
    });
  }
  handleSearchSubmit = (event) => {
    event.preventDefault();



  };

  handleChangeStaus = arg => {
    const project = arg;
    const { onUpdateStatus } = this.props;
    const updateSt = {
      id: project.id,
      isActive: (project.isActive == true) ? false : true,
    }
    onUpdateStatus(updateSt);
  }
  handleStatusChange = async (projectId, projectStatus) => {
    const payload = {
      id: projectId,
      projectStatus: parseInt(projectStatus),
    };

    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj?.resultData?.bearerToken;
      const response = await fetch(API_URL + '/Project/updateprojectcurrentstatus', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.loading("Please wait...")
        toast.dismiss();
        toast.success("Status updated successfully!");
        this.props.onGetAllProject(localStorage.getItem('userId'), localStorage.getItem('brokerId'));
        //console.log('Status updated successfully!');

      } else {
        console.error('Failed to update status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  render() {

    const { totalProject, totalClient, totalLead, totalLeadtoClient, projectMonthNames, projectCounts, projecttopfive, projectMonths, projectLead, projectLeadClient } = this.state;

    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 13
          }}
        >
          {" "}
          No
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: -2
          }}
        >
          {" "}
          Yes
        </div>
      )
    }

    const reports = [
      { title: "# Active projects", iconClass: "bx bx-copy-alt", description: totalProject },
      {
        title: "# Clients",
        iconClass: "bx bx-purchase-tag-alt",
        description: totalClient,
      },
      {
        title: "# Leads",
        iconClass: "bx bx-purchase-tag-alt",
        description: totalLead,
      },
      {
        title: "Leads to client conversion",
        iconClass: "bx bx-purchase-tag-alt",
        description: totalLeadtoClient + "%",
      },

    ]

    const { projects, docstatuses } = this.props;
    const { deleteModal } = this.state;
    const userModule = localStorage.getItem('userModule');
    const barchartdata = {
      series: [
        {

          data: projectCounts,
        }
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },

        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8"],
        xaxis: {
          categories: projectMonthNames,
        },
      },
    };
    const CityBardata = {
      series: [
        {
          name: "Clients",
          data: projectLeadClient,
        },
        {
          name: "Lead",
          data: projectLead,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },

        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8", "#41B8D5"],
        xaxis: {
          categories: projectMonths,
        },
      },
    };
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteProject}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            {/* Render Breadcrumbs */}

            <Row className="project-header mb20">
              <Col lg="6">
                <Breadcrumbs
                  title={this.props.t("Project")}
                  breadcrumbItem={this.props.t("Dashboard")}
                />
              </Col>

              <Col lg="6">

                {userModule.indexOf("BrokerAgent.RealtorPreConsProjects.Add") > -1 ?
                  (
                    <div className="clearfix mt-2 pt-2">
                      <RsLink to="/pre-Construction/create-new-project" className="btn btn btn-light btn-md float-end" iconClass="bx bx-plus">Add New Project</RsLink>

                    </div>
                  ) : <div className="clearfix mt-2 pt-2">
                    <RsLink to="/pre-Construction/create-new-project" className="btn btn btn-light btn-md float-end" iconClass="bx bx-plus">Add New Project</RsLink>

                  </div>
                }
              </Col>
            </Row>
            <Row className="mb20" style={{ marginTop: 20 }}>
              {this.state.reports.map((report, key) => (
                <Col md="3" className="" key={key}>
                  <RsCard title={report.title} description={report.description} iconClass={report.iconClass} />
                </Col>
              ))}
            </Row>
            <Row className="mb20">
              <Col lg={4}>
                <RSBarChart title="Projects launches" filter={false} height="370" series={barchartdata.series} options={barchartdata.options} />
              </Col>
              <Col lg="4">
                <RSBarChart title="Distribution by City" filter={false} height="370" series={CityBardata.series} options={CityBardata.options} />
              </Col>
              <Col lg={4}>
                <div className="card">
                  <div className="card-body">
                    <div className="card-title mb-4 float-start card-bottom-border">Top 5 Projects</div>
                    <div className="clearfix"></div>
                    <DataTable value={projecttopfive} className="table table-bordered data-table align-middle table-nowrap  mb-4">
                      <Column field="projectName" header="Project Name"></Column>
                      <Column field="city" header="City" ></Column>
                      <Column field="noOfClient" header="#Client" ></Column>
                      <Column field="noOfLead" header="#Lead" ></Column>
                    </DataTable>

                  </div>
                </div>
              </Col>
            </Row>


            <Card>
              <Row>
                <Col sm="6">

                  <CardBody>
                    <h5 className="card-title card-bottom-border">New leads</h5>
                    <div className="table-responsive">
                      <table className="table mb-0 table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Project</th>
                            <th>Source</th>


                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>14 June 2024</td>
                            <td>Mohit Grover</td>
                            <td>Demo Project</td>
                            <td>Mail Campaign</td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </CardBody>

                </Col>

                <Col sm="6">

                  <CardBody>
                    <h5 className="card-title card-bottom-border">Follow Up Activity</h5>
                    <div className="table-responsive">
                      <table className="table mb-0 table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Note</th>


                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td>1st March,2024</td>
                            <td>1:00 pm</td>

                          </tr>



                        </tbody>
                      </table>
                    </div>
                  </CardBody>

                </Col>


              </Row>
            </Card>
            <ProjectMenu />
            <Card>
              <CardBody>
                <Row>
                  <Col lg="12">
                    <CardTitle className="mb-4 float-start">All Projects</CardTitle>
                    <Form className="row gy-2 gx-3 float-end" onSubmit={this.handleSearchSubmit}>

                      <div className="col-sm-auto">

                        <Input type="text" name="complitionYear" className="form-control" id="autoSizingInput" placeholder="Project Name" value={this.state.complitionYear} onChange={this.handleComplitionYear} />
                      </div>
                      <div className="col-sm-auto">

                        <Input type="text" name="complitionYear" className="form-control" id="autoSizingInput" placeholder="Completion Year" value={this.state.complitionYear} onChange={this.handleComplitionYear} />
                      </div>
                      <div className="col-sm-auto">

                        <Input type="text" name="city" className="form-control" id="autoSizingInput" placeholder="Builder" value={this.state.city} onChange={this.handleCityChange} />
                      </div>

                      <div className="col-sm-auto">

                        <Input type="text" name="city" className="form-control" id="autoSizingInput" placeholder="Name of City" value={this.state.city} onChange={this.handleCityChange} />
                      </div>

                      {/* <div className="col-sm-auto">
          
           <Input type="text" name="strintingPrice" className="form-control" id="autoSizingInput" placeholder="Starting Price From" value={this.state.strintingPrice} onChange={this.handlePriceChange} />
         </div> */}

                      <div className="col-sm-auto">
                        <label className="visually-hidden" htmlFor="autoSizingSelect">Status</label>
                        <select name="status" defaultValue="0" className="form-select" onChange={this.handleStatusChange}>
                          <option value="0">Status...</option>
                          {
                            this.state.projectStatus
                              .map(projectStat =>
                                <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                              )
                          }
                        </select>
                      </div>

                      <div className="col-sm-auto">
                        <button type="submit" className="btn btn-primary w-md">Search</button>
                      </div>
                    </Form>
                    <div className="clearfix"></div>
                    <div className="">
                      <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={projects} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >

                        <Column field="lastContact" header="Project Name" body={(rowData) => (
                          <div>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={"/pre-construction/project-detail/" + rowData.projectId}
                                className="text-dark"
                              >
                                {rowData.projectName}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">
                              {rowData.city}
                            </p>
                          </div>
                        )}></Column>

                        <Column field="lastContact" header="# Leads" body={(rowData) => (
                          rowData.noOfLeads
                        )}></Column>
                        <Column field="lastContact" header="# Worksheets pending" body={(rowData) => (
                          rowData.noOfPendingWorksheets
                        )}></Column>
                        <Column field="lastContact" header="# Submitted Worksheets" body={(rowData) => (
                          rowData.noOFSubmittedWorkSheets
                        )}></Column>
                        <Column field="lastContact" header="# Clients" body={(rowData) => (
                          rowData.noOfClient
                        )}></Column>
                        <Column field="projectStatus" header="Status" body={(rowData) => (
                          <ProjectStatusGridOption
                            select={rowData.projectStatus}
                            docstatuses={docstatuses}
                            onStatusChange={(newStatus) => this.handleStatusChange(rowData.id, newStatus)}
                          />
                          // <Switch onColor="#50C878"  width={65}  uncheckedIcon={<Offsymbol />} checkedIcon={<OnSymbol />} onChange={isActive => this.handleChangeStaus(rowData)} checked={rowData.isActive} />
                        )}></Column>
                        <Column field="lastContact" header="Action" body={(rowData) => (
                          <Link className="btn btn-action" to={"/pre-construction/campaign/" + rowData.projectId}>Manage Project</Link>

                        )}></Column>

                      </DataTable>

                    </div>

                  </Col>
                </Row>
              </CardBody>
            </Card>



          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsList.propTypes = {

  className: PropTypes.any,
  t: PropTypes.any,
  projects: PropTypes.array,
  onGetAllProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  loading: PropTypes.object,
  setProjectSearchComplitionYear: PropTypes.func,
  setProjectSearchCity: PropTypes.func,
  onGetSearchAllProject: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  docstatuses: PropTypes.array,
  onGetProjectStatus: PropTypes.func
}

const mapStateToProps = ({ Project, AppoinmentObject }) => ({
  projects: Project.projects,
  loading: Project.loading,
  docstatuses: AppoinmentObject.docstatuses
})

const mapDispatchToProps = dispatch => ({
  onGetAllProject: (agentId, brokerId) => dispatch(getAllProjectAction(agentId, brokerId)),
  onDeleteProject: (id) => dispatch(deleteProject(id)),
  onGetSearchAllProject: (data) => dispatch(getSearchAllProject(data)),
  onUpdateStatus: (data) => dispatch(updateProjectStatus(data)),
  onGetProjectStatus: (agentId) => dispatch(getProjectStatus(agentId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectsList)))