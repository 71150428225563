import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import * as Yup from "yup";
import {
  getProjectUUIDAction,
  addProjectMedia, 
  getProjectMedia,
  addProjectLink, 
  getProjectLink,
  getProjectOpenHouseFiles,
  deleteProjectOpenHouseFile
} from "../../../store/projects/actions";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import classnames from "classnames";
import axios from "axios"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import FileBase64 from "../../../components/Common/FileBase64";
import WebpagesMenu from "./menu/webpages-menu";
import WebPageBreadcrumbs from "components/Common/WebpageBreadcrumb";
import { BlobServiceClient } from '@azure/storage-blob';
import Swal from 'sweetalert2'
import CopyText from "components/buttons/copyBtn";
import SaveButton from "components/buttons/save";
class ProjectsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      isRight: false,
      activeTab1: "1",
      fileUploaded: false,
    };
    //this.onFileChange = this.onFileChange.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.handleProjectDocumentSubmit = this.handleProjectDocumentSubmit.bind(this)
    this.handleProjectLinkSubmit = this.handleProjectLinkSubmit.bind(this)
    this.toggle1 = this.toggle1.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (opfile) => {
    this.setState({ opfile: opfile });
    this.setState({ deleteModal: true });
  };

  handleDeleteFile = () => {
    const { onDeleteFile } = this.props;
    const { opfile } = this.state;
    //console.log(opfile);
    if (opfile.id !== undefined) {
      onDeleteFile(opfile);
      this.setState({ deleteModal: false });
    }
  };
  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
    //this.toggle();
  }
  getFiles(files) {
    this.setState({ files: files });
    console.log(files);
  }
  handleChange = setValue => {
    this.setState({ setValue });
  };

  handleProjectDocumentSubmit(value){
    this.props.addProjectMedia(value, this.props.history);
    this.toggle();
  }
  handleProjectLinkSubmit(value){
    this.props.addProjectLink(value, this.props.history);
    this.toggle();
  }
  componentDidMount() {
    const { match: { params }, ongetProjectUUIDAction, onGetProjectMedia, onGetProjectLink, onGetProjectOpenHouseFiles } = this.props;
    if (params && params.projectId) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectMedia(params.projectId);
      onGetProjectLink(params.projectId);
      onGetProjectOpenHouseFiles(localStorage.getItem("userId"))
    }
  }

  handleUncheckedCheckbox(document){
    console.log(document);
  }
  async handleFileChange(event, setFieldValue) {
    const file = event.currentTarget.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      const allowedTypes = ['application/pdf', 'application/msword', 'image/jpeg', 'image/png'];
      const allowedExtensions = ['.pdf', '.doc', '.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
      if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          title: 'File type or extension not allowed',
          text: 'Please upload a PDF, DOC, JPG, or PNG file.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        //alert('Invalid file type. Please upload a PDF, DOC, JPG, or PNG file.');
        return;
      }
      if (file.size > maxSize) {
        Swal.fire({
          title: 'Size not allowed',
          text: 'Please Upload Maximum 5MB File',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
       
      }
      this.setState({ btnloading: true });
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-06-22T02:18:02Z&st=2024-06-21T18:18:02Z&spr=https,http&sig=Srg7JmJ7EfJQWUNadO188IgXNeCFEzSmQQmctuyjGxA%3D";
      const containerName = "images";
      const storageAccountName = "realtyspace";
      const fileName = `${Date.now()}_${file.name}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });

     
        if (setFieldValue) {
          const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
          setFieldValue(`projectDocumentLink`, fileUrl);
          setFieldValue(`projectDocumentContent`, fileUrl);
          this.setState({ fileUploaded: true });
        }
      } catch (error) {
        console.error('Error uploading file:', error);

      }
    }
  }
  render() {
    const { projectData, documents, links, opfiles, onGetProjectOpenHouseFiles, onGetProjectMedia, onGetProjectLink } = this.props;
    const { deleteModal, fileUploaded} = this.state;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteFile}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
            <Row>
              <Col sm="2" className="pe-0">
                <ProjectSidebar />
              </Col>
              <Col sm="10" className="ps-0">
                <Row className="project-header">
                  <Col sm="7">
                    <WebPageBreadcrumbs
                      title={this.props.t("Projects")}
                      breadcrumbItem={this.props.t("Open House")}
                    />
                  </Col>
                  <Col sm="4">
                    <h2 className="">
                      {this.props.t(projectData.projectName)}
                    </h2>
                  </Col>
                </Row>
                <WebpagesMenu />
                <Card>
                  <CardBody>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <div className="link-page">
                      <h5>
                        <span><a target="_blank" rel="noreferrer" href={"https://openhouse.realtyspacehub.com/" + this.props.match.params.projectId}>Preview Open House page</a></span> URL: <a target="_blank" rel="noreferrer" href={"https://openhouse.realtyspacehub.com/" + this.props.match.params.projectId}>{"https://openhouse.realtyspacehub.com/" + this.props.match.params.projectId}</a> <CopyText textToCopy={"https://openhouse.realtyspacehub.com/" + this.props.match.params.projectId} />
                      </h5>
                      <div className="open-bg">
                        <h4>Generate leads using the Open House page provided and auto integrate them in the CRM</h4>

                        <h5>Benefits</h5>

                        <ul>
                            <li>Easily and digitally capture lead information</li>
                            <li>No need to print expensive brochures as giveaways. Every user who fills out the online form will be auto sent email with attached brochures.</li>
                            <li>No risk of human error or labour intensive time of updating prospect information into another system. Every form filled automatically is entered as a Lead into the project.</li>
                        </ul>
                        <h5>Steps to manage open houses</h5>
                        <ul>
                            <li>Preview your current Open House page for the Project.</li>
                            <li>Select the documents that a user should receive after they fill the open house form. You can also upload any new document.</li>
                      
                        </ul>
                        
                      </div>
                      <h5 className="normal-text">Documents  <Link to="#" onClick={this.toggleRightCanvas} className="btn btn-yellow">Upload/link new document</Link></h5>
                      
                     

                      <h6 className="normal-text">
                        Select documents below
                      </h6>
                     
                      <Row>
                        <Col sm="12">
                        <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:parseInt(localStorage.getItem("userId")),
                    documentType: 0,
                    projectId: parseInt(projectData.id),
                    projectGuid:projectData.preConsProjectGuid,
                    projectLinkUrl: (this.state && this.state.projectLinkTitle) || "",
                    checkedItems: []

                  }}
                  validationSchema={Yup.object().shape({
                    
                  })}

                  onSubmit={this.handleProjectLinkSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                        <Row>
                        {map(documents, (document, dockey) => (
                          <div className="col-sm-3">

                          <div className="form-check form-check-primary mb-3" key={"doc" + dockey}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"document_" + document.id}
                              onChange={() => {
                                const checkedItems = values.checkedItems.includes(document.id)
                                  ? values.checkedItems.filter(item => item !== document.id)
                                  : [...values.checkedItems, document.id];
                                setFieldValue('checkedItems', checkedItems);
                                if (!values.checkedItems.includes(document.id)) {
                                  const obj = JSON.parse(localStorage.getItem("authUser"));
                                      const token = obj.resultData.bearerToken;
                                      const str = 'Bearer ' + token;
                                     
                                      axios.put('https://rscoreapi.azurewebsites.net/api/ProjectDocument/UpdateOpenHouseProjectDocument', {
                                        id: document.id,
                                        isImageType: 0,
                                        isProjectOpenHouse: true,
                                        isProjectDownloadPrice: false
                                      }, {
                                        headers: {
                                          'Content-Type': 'application/json',
                                          'Authorization': str
                                        }
                                      })
                                    .then(response => {
                                      onGetProjectMedia(this.props.match.params.projectId);
                                    })
                                    .catch(error => {
                                      console.error(error);
                                      // Handle error
                                    });
                                }else {
                                  const obj = JSON.parse(localStorage.getItem("authUser"));
                                  const token = obj.resultData.bearerToken;
                                  const str = 'Bearer ' + token;
                                 
                                  axios.put('https://rscoreapi.azurewebsites.net/api/ProjectDocument/UpdateOpenHouseProjectDocument', {
                                    id: document.id,
                                    isImageType: 0,
                                    isProjectOpenHouse: false,
                                    isProjectDownloadPrice: false
                                  }, {
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': str
                                    }
                                  })
                                .then(response => {
                                  onGetProjectMedia(this.props.match.params.projectId);
                                  //onGetProjectOpenHouseFiles(localStorage.getItem("userId"))
                                })
                                .catch(error => {
                                  console.error(error);
                                  // Handle error
                                });
                                }
                              }}
                              defaultValue={document.id}
                              data-type="Document"
                              data-name={document.projectDocumentTitle}
                              checked={document.isProjectOpenHouse}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={"document_" + document.id}
                            >
                            </label>
                            <a target="_blank" rel="noreferrer" href={document.projectDocumentLink}> {document.projectDocumentTitle}</a>
                            
                          </div>
                       </div>
                                ))}
                          
                        {map(links, (link, linkkey) => (
                            <div className="col-sm-3">
                            <div className="form-check form-check-primary mb-3" key={"link" + linkkey}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"link_" + link.id}
                              onChange={() => {
                                const checkedItems = values.checkedItems.includes(link.id)
                                  ? values.checkedItems.filter(item => item !== link.id)
                                  : [...values.checkedItems, link.id];
                                setFieldValue('checkedItems', checkedItems);
                                if (!values.checkedItems.includes(link.id)) {
                                      const obj = JSON.parse(localStorage.getItem("authUser"));
                                      const token = obj.resultData.bearerToken;
                                      const str = 'Bearer ' + token;
                                     
                                      axios.put('https://rscoreapi.azurewebsites.net//api/ProjectLink/UpdateOpenHouseProjectLink', {
                                        id: link.id,
                                        isImageType: 0,
                                        isProjectOpenHouse: true,
                                        isProjectDownloadPrice: false
                                      }, {
                                        headers: {
                                          'Content-Type': 'application/json',
                                          'Authorization': str
                                        }
                                      })
                                    .then(response => {
                                      onGetProjectLink(this.props.match.params.projectId)
                                    
                                    })
                                    .catch(error => {
                                      console.error(error);
                                    
                                    });
                                }else {
                                  const obj = JSON.parse(localStorage.getItem("authUser"));
                                      const token = obj.resultData.bearerToken;
                                      const str = 'Bearer ' + token;
                                     
                                      axios.put('https://rscoreapi.azurewebsites.net//api/ProjectLink/UpdateOpenHouseProjectLink', {
                                        id: link.id,
                                        isImageType: 0,
                                        isProjectOpenHouse: false,
                                        isProjectDownloadPrice: false
                                      }, {
                                        headers: {
                                          'Content-Type': 'application/json',
                                          'Authorization': str
                                        }
                                      })
                                    .then(response => {
                                      onGetProjectLink(this.props.match.params.projectId)
                                    
                                    })
                                    .catch(error => {
                                      console.error(error);
                                    
                                    });
                                }
                              }}
                              checked={link.isProjectOpenHouse}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={"link_" + link.id}
                            >
                              <a target="_blank" rel="noreferrer" href={link.projectLinkUrl}>{link.projectLinkTitle}</a>
                            </label>
                          </div>
                          </div>
                        ))}
                         </Row>
                        </Form>

                      )}
                      </Formik>
                        </Col>                  
                      </Row>

                     {/* <Row>
                     {map(opfiles, (opfile, opfilekey) => (
                      <Col sm="2" key={ 'file_' + opfilekey}>
                        <h3 style={{fontSize:13}}>{opfile.documentName} <span className="float-end"><Link to="#" onClick={() => this.onClickDelete(opfile)}><i className="mdi mdi-close-circle"></i></Link></span></h3>
                      </Col>
                     ))}
                     </Row> */}

                     
                        </div>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Upload New Document 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab1 === "1",
                          })}
                          onClick={() => {
                            this.toggle1("1");
                          }}
                        >
                          Document
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab1 === "2",
                          })}
                          onClick={() => {
                            this.toggle1("2");
                          }}
                        >
                          Link
                        </NavLink>
                      </NavItem>
                    
                    </Nav>
                    <TabContent
                      activeTab={this.state.activeTab1}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                           <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                      projectDocumentTitle: (this.state && this.state.projectDocumentTitle) || "",
                                                      projectDocumentTypeId: 0,
                                                      projectId: projectData.id,
                                                      projectGuid:projectData.preConsProjectGuid,
                                                      agentId: localStorage.getItem('userId'),
                                                      //projectDocumentContent:this.state.files.base64
                          
                                                    }}
                                            validationSchema={Yup.object().shape({
                                              projectDocumentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                                            })}
                          
                                            onSubmit={this.handleProjectDocumentSubmit}
                                          >
                                            {({ errors, touched, values, handleChange, setFieldValue }) => (
                                              <Form
                                                className="needs-validation"
                                              >
                                   
                                          
                                        
                                             <Row>
                                                  <Col sm="12">
                                                  <FormGroup className="mb-3">
                                                      <Label htmlFor="projectDocumentTitle">
                                                        {this.props.t("Title*")}
                                                      </Label>
                                                      <Field
                                                        name="projectDocumentTitle"
                                                        onChange={handleChange}                            
                                                        type="text"
                                                        
                                                        className={
                                                          "form-control" +
                                                          (errors.projectDocumentTitle && touched.projectDocumentTitle
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                       
                                                        id="projectDocumentTitle"
                                                      />
                                                       <ErrorMessage
                                                        name="projectDocumentTitle"
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  {/* <Col sm="12">
                                                  <FormGroup className="mb-3">
                                                      <Label htmlFor="projectDocumentTypeId">
                                                        {this.props.t("Type")}
                                                      </Label>
                                                      <Field as="select" name="projectDocumentTypeId" className="form-control" onChange={handleChange}>
                                                          <option value="0">Select</option>
                                                          {
                                                                      this.state.docTypes
                                                                        .map(docType =>
                                                                          <option key={docType.id} value={docType.id}>{docType.projectDocumentTypeTitle}</option>
                                                                        )
                                                                    }
                                                      </Field>
                                                    </FormGroup>
                                                  </Col> */}
                                                  <Col sm="12">
                                                  <div className="form-group mb-3">
                                                    <Label htmlFor="file">File upload</Label>
                                                    <input
                                                              type="file"
                                                              className="form-control"
                                                              onChange={event => this.handleFileChange(event, setFieldValue)}
                                                            />
                                                    
                                                    
                                                  </div>
                                                 
                                                  </Col>
                          
                                                 
                                                  
                                                  <div className="clearfix">
                                                  {fileUploaded && <SaveButton>Upload</SaveButton>}
                                                    
                                                    </div>
                                                  
                                              </Row>
                          
                                          
                                     
                                          
                                      </Form>
                                            )}
                                              </Formik>

                      </TabPane>
                      <TabPane tabId="2">
                            <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            projectId: parseInt(projectData.id),
                                            projectGuid:this.props.match.params.projectId,
                                            projectLinkTitle: (this.state && this.state.projectLinkTitle) || "",
                                            projectLinkUrl: (this.state && this.state.projectLinkTitle) || "",
                        
                                          }}
                                          validationSchema={Yup.object().shape({
                                            projectLinkTitle: Yup.string().required("This is Required"),
                                            projectLinkUrl: Yup.string().required("This is Required")
                                          })}
                        
                                          onSubmit={this.handleProjectLinkSubmit}
                                        >
                                          {({ errors, touched, values, handleChange, setFieldValue }) => (
                                            <Form
                                              className="needs-validation"
                                            >
                                   
                                        
                                      
                                           <Row>
                                                <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="projectLinkTitle">
                                                      {this.props.t("Title*")}
                                                    </Label>
                                                    <Field
                                                      name="projectLinkTitle"
                                                      onChange={handleChange}                            
                                                      type="text"
                                                      
                                                      className={
                                                        "form-control" +
                                                        (errors.projectLinkTitle && touched.projectLinkTitle
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                     
                                                      id="projectLinkTitle"
                                                    />
                                                     <ErrorMessage
                                                      name="projectLinkTitle"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </FormGroup>
                                                </Col>
                                               
                                                
                        
                                                <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="projectLinkUrl">
                                                      {this.props.t("Link*")}
                                                    </Label>
                                                    <Field
                                                      name="projectLinkUrl"
                                                      onChange={handleChange}                             
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.projectLinkUrl && touched.projectLinkUrl
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                     
                                                      id="projectLinkUrl"
                                                    />
                                                     <ErrorMessage
                                                      name="projectLinkUrl"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                <div className="clearfix">
                                                  <SaveButton>Add Link</SaveButton>
                                                
                                                  
                                                  </div>
                                                </Col>
                                            </Row>
                        
                                        
                                   
                                        
                                    </Form>
                                          )}
                                            </Formik>                    
                      </TabPane>
                      
                    </TabContent>
                       
                      </OffcanvasBody>
                      </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ProjectsCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  apiError: PropTypes.any,
  ongetProjectUUIDAction: PropTypes.func,
  match: PropTypes.object,
  projectData: PropTypes.object,
  loading: PropTypes.object,
  documents:PropTypes.array,
  onGetProjectMedia:PropTypes.func,
  addProjectMedia:PropTypes.func,
  addProjectLink:PropTypes.func,
  links:PropTypes.array,
  onGetProjectLink:PropTypes.func,
  opfiles:PropTypes.array,
  onGetProjectOpenHouseFiles:PropTypes.func,
  onDeleteFile:PropTypes.func
};
const mapStateToProps = ({ Project }) => (
{
  links: Project.links,
  documents: Project.documents,
  projectData: Project.projectData,
  opfiles:Project.opfiles,
  loading: Project.loading,

});
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: id => dispatch(getProjectUUIDAction(id)),
  addProjectMedia: (data) => dispatch(addProjectMedia(data)),
  onGetProjectMedia:(projectId) => dispatch(getProjectMedia(projectId)),
  addProjectLink: (data) => dispatch(addProjectLink(data)),
  onGetProjectLink:(projectId) => dispatch(getProjectLink(projectId)),
  onGetProjectOpenHouseFiles:(agentId) => dispatch(getProjectOpenHouseFiles(agentId)),
  onDeleteFile:(id) => dispatch(deleteProjectOpenHouseFile(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectsCreate)));
