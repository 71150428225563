import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Row, Col, CardBody, Card, CardText, Container, FormGroup, CardTitle, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from "reactstrap"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import SaveButton from "components/buttons/save";
import RsAdNew from "components/buttons/Adnew";
import RSBarChart from "components/charts/RSBarChart";
import RSPieChart from "components/charts/RSPieChart";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { API_URL } from 'helpers/app_url';
import axios from "axios"
import TitleDropdowns from "components/dropdowns/Other/titleDropdowns"
import RsLink from "components/buttons/rsLink"
import { toast, ToastContainer } from "react-toastify";

class agentDashoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      HeaderText: "Add",
      ButtonText: "Save",
      agents: [],
      topAgents: [],
      id: 0,
      title: '',
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      mobile: "",
      fullAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      postal: '',
      country: '',
      longitude: 0,
      latitude: 0
    }
    this.addNewCanvas = this.addNewCanvas.bind(this);
    this.editCanvas = this.editCanvas.bind(this);
    this.deleteCanvas = this.deleteCanvas.bind(this);
    this.handleSubmitLead = this.handleSubmitLead.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus = (data, newStatus) => {
    console.log(data);
  };

  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;

        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);

        const modifiedAddress = `${streetNumber} ${route}`;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setFieldValue(`fullAddress`, place.formatted_address);
        setFieldValue(`addressLine1`, modifiedAddress);
        setFieldValue(`addressLine2`, city);
        setFieldValue(`city`, city);
        setFieldValue(`province`, province);
        setFieldValue(`postal`, postalCode);
        setFieldValue(`country`, country);
        setFieldValue(`longitude`, longitude);
        setFieldValue(`latitude`, latitude);

      })
      .catch(error => console.error('Error', error));
  };
  handleSubmitLead = (values, actions) => {
    toast.loading("Please wait...");
    this.setState({ isAddNew: !this.state.isAddNew });
    if (this.state.ButtonText == "Save") {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        primaryEmail: values.email,
        phone: values.phone,
        phoneExt: values.phoneExt,
        mobile: values.mobile,
        createdBy: parseInt(localStorage.getItem("userId")),
        groupId: parseInt(localStorage.getItem("userId")),
        officeBranchId: parseInt(localStorage.getItem("userId")),
        notes: values.notes,
        title: values.title,
        fullAddress: values.fullAddress,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        nearestIntersection: values.nearestIntersection,
        city: values.city,
        province: values.province,
        postal: values.postal,
        country: values.country,
        longitude: values.longitude,
        latitude: values.latitude
      }
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      var authOptions = {
        method: 'post',
        url: `${API_URL}AgentExt`,
        data: payload,
        headers: { Authorization: str }
      };

      axios(authOptions)
        .then((response) => {
          if (response.data.success) {
            toast.dismiss();
            toast.success("Agent Created");
            this.loadData();
          }
          else {
            toast.dismiss();
            toast.success(response.data.validationErrors[0]);
            this.loadData();
          }
        })
        .catch((error) => {
          toast.dismiss();
          toast.success("Error" + error);
        })
    }
    else {
      const payload = {
        id: this.state.id,
        firstName: values.firstName,
        lastName: values.lastName,
        primaryEmail: values.email,
        phone: values.phone,
        phoneExt: values.phoneExt,
        mobile: values.mobile,
        notes: values.notes,
        title: values.title,
        fullAddress: values.fullAddress,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        nearestIntersection: values.nearestIntersection,
        city: values.city,
        province: values.province,
        postal: values.postal,
        country: values.country,
        longitude: values.longitude,
        latitude: values.latitude
      }
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      var authOptions = {
        method: 'put',
        url: `${API_URL}AgentExt`,
        data: payload,
        headers: { Authorization: str }
      };

      axios(authOptions)
        .then((response) => {
          if (response.data.success) {
            toast.dismiss();
            toast.success("Agent Updated");
            this.loadData();
          }
          else {
            toast.dismiss();
            toast.success(response.data.validationErrors[0]);
            this.loadData();
          }
        })
        .catch((error) => {
          toast.dismiss();
          toast.success("Error" + error);
        })
    }
  }


  renderActions = (rowData) => {
    return (
      <UncontrolledDropdown direction="up">
        <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
          Action <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag={Link} to={`/agent-detail/${rowData.id}`}>Performance</DropdownItem>
          <DropdownItem tag={Link} to="#" onClick={() => this.editCanvas(rowData)} >Edit</DropdownItem>
          <DropdownItem href="#" onClick={() => this.deleteCanvas(rowData, 3)}>Delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
  deleteCanvas(data) {
    toast.success("API need to create!");
  }

  editCanvas(data) {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;

    axios.get(`${API_URL}AgentExt/` + data.id, {
      params: {
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ id: res.data.id });
        this.setState({ title: res.data.title });
        this.setState({ firstName: res.data.firstName });
        this.setState({ lastName: res.data.lastName });
        this.setState({ email: res.data.primaryEmail });
        this.setState({ phone: res.data.agentContact.cContact.phone });
        this.setState({ mobile: res.data.agentContact.cContact.mobile });
        this.setState({ fullAddress: res.data.agentAddress.fullAddress });
        this.setState({ addressLine1: res.data.agentAddress.addressLine1 });
        this.setState({ addressLine2: res.data.agentAddress.addressLine2 });
        this.setState({ city: res.data.agentAddress.city });
        this.setState({ province: res.data.agentAddress.province });
        this.setState({ postal: res.data.agentAddress.postal });
        this.setState({ country: res.data.agentAddress.country });
        this.setState({ longitude: res.data.agentAddress.longitude });
        this.setState({ latitude: res.data.latitude });
        this.setState({ isAddNew: !this.state.isAddNew, HeaderText: "Edit", ButtonText: "Update" });
      });
  }

  addNewCanvas() {
    this.setState({ id: 0 });
    this.setState({ title: "" });
    this.setState({ firstName: "" });
    this.setState({ lastName: "" });
    this.setState({ email: "" });
    this.setState({ phone: "" });
    this.setState({ mobile: "" });
    this.setState({ fullAddress: "" });
    this.setState({ addressLine1: "" });
    this.setState({ addressLine2: "" });
    this.setState({ city: "" });
    this.setState({ province: "" });
    this.setState({ postal: "" });
    this.setState({ country: "" });
    this.setState({ longitude: "" });
    this.setState({ latitude: "" });

    this.setState({ isAddNew: !this.state.isAddNew, HeaderText: "Add", ButtonText: "Save" });
  }
  loadData() {
    //'https://rscoreapi.azurewebsites.net/api/ProjectEmailTemplate/alltemplates' 0;//parseInt(localStorage.getItem("userId")),
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(`${API_URL}AgentExt/topFiveAgentByOffice`, {
      params: {
        clientId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ topAgents: res.data });
      });
    //localStorage.getItem("userId")
    axios.get(`${API_URL}AgentExt/AllAgentByOffice`, {
      params: {
        clientId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ agents: res.data });
      });
  }
  componentDidMount() {
    this.loadData();
  }
  render() {
    const { agents, topAgents } = this.state;

    return (
      <React.Fragment>
        <ToastContainer autoClose={2000}></ToastContainer>
        <div className="page-content">
          <MetaTags>
            <title>Broker Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row className="project-header mb20">
              <Col xl="7">
                <Breadcrumbs
                  title={this.props.t("Dachboard")}
                  breadcrumbItem={this.props.t("Agent")}
                />
              </Col>
              <Col xl="5">
                {/* <WelcomeComp /> */}
                <div className="pt-2 float-end mt-2">

                  <div className="">
                    <RsAdNew to="#" onClick={this.addNewCanvas} className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>

                  </div>

                </div>
              </Col>
            </Row>

            <Row className="mb20">
              <Col lg={12}>
                <div className="card">
                  <div className="card-body">
                    <div className="card-title mb-4 float-start">Top 5 perfoming agents</div>
                    <div className="clearfix"></div>
                    <DataTable value={topAgents} className="table table-bordered data-table align-middle table-nowrap  mb-4">
                      <Column field="firstName" header="Name"></Column>
                      <Column field="phone" header="Phone" ></Column>
                      <Column field="primaryEmail" header="Email" ></Column>
                      <Column field="city" header="Branch" ></Column>
                      <Column field="noOfLead" header="#Office leads" ></Column>
                      <Column field="noOfClient" header="#Clients"></Column>
                    </DataTable>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="card-title mb-4 float-start">Agents List ({agents.length} / 20)</div>

                    <div className="clearfix"></div>
                    <DataTable value={agents} className="table table-bordered data-table align-middle table-nowrap  mb-4">
                      <Column field="firstName" header="Name"></Column>
                      <Column field="phone" header="Phone" ></Column>
                      <Column field="primaryEmail" header="Email" ></Column>
                      <Column field="city" header="Branch" ></Column>
                      <Column field="noOfLead" header="#Office leads" ></Column>
                      <Column field="noOfClient" header="#Clients"></Column>
                      <Column header="Action" body={this.renderActions}></Column>
                    </DataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>


        <Offcanvas
          isOpen={this.state.isAddNew}
          direction="end"
          toggle={this.addNewCanvas}
        > 
          <OffcanvasHeader toggle={this.addNewCanvas}>
            {this.state.HeaderText} Agent
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: this.state.id,
                title: this.state.title,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                mobile: this.state.mobile,
                fullAddress: this.state.fullAddress,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                city: this.state.city,
                province: this.state.province,
                postal: this.state.postal,
                country: this.state.country,
                longitude: this.state.longitude,
                latitude: this.state.latitude
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().required('This field is required'),
                firstName: Yup.string().required("This Field in required"),
                lastName: Yup.string().required("This Field in required"),
                email: Yup.string().email('Invalid email').required('This Field in required'),
                mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid'),
              })}

              onSubmit={this.handleSubmitLead}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <TitleDropdowns
                        name="title"
                        label="Title *"
                        fieldName="Agent:Title"

                      />

                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="firstName">
                          {this.props.t("First Name *")}
                        </Label>
                        <Field
                          name="firstName"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.firstName && touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                          id="firstName"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="lastName">
                          {this.props.t("Last Name *")}
                        </Label>
                        <Field
                          name="lastName"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.lastName && touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                          id="lastName"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="fullAddress">
                          Location
                        </Label>
                        <PlacesAutocomplete
                          value={values.fullAddress}
                          onChange={(value) => setFieldValue('fullAddress', value)}
                          onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address...',
                                  className: 'form-control',

                                })}
                              />
                              <div className="">
                                {loading ? <div>Loading...</div> : null}

                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                    : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>


                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="email">
                          {this.props.t("Email *")}
                        </Label>
                        <Field
                          name="email"
                          type="text"

                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.email && touched.email
                              ? " is-invalid"
                              : "")
                          }
                          id="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="mobile">
                          {this.props.t("Mobile *")}
                        </Label>
                        <Field
                          name="mobile"
                          type="text"

                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.mobile && touched.mobile
                              ? " is-invalid"
                              : "")
                          }
                          id="mobile"
                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="phone">
                          {this.props.t("Secondary Phone")}
                        </Label>
                        <Field
                          name="phone"
                          type="text"

                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.phone && touched.phone
                              ? " is-invalid"
                              : "")
                          }
                          id="phone"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="phoneExt">
                          {this.props.t("Ext")}
                        </Label>
                        <Field
                          name="phoneExt"
                          type="text"
                          //value={values.phone}
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.phoneExt && touched.phoneExt
                              ? " is-invalid"
                              : "")
                          }
                          id="phoneExt"
                        />
                        <ErrorMessage
                          name="phoneExt"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="notes">
                          {this.props.t("Notes ")}
                        </Label>
                        <Field
                          name="notes"
                          onChange={handleChange}
                          as="textarea"

                          className="form-control"

                          id="notes"

                        />
                      </FormGroup>
                    </Col>



                  </Row>






                  <div><SaveButton>{this.state.ButtonText}</SaveButton>{" "} </div>

                </Form>
              )}
            </Formik>

          </OffcanvasBody>
        </Offcanvas>
      </React.Fragment >
    )
  }
}
agentDashoard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(agentDashoard))
