import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import SettingSidebar from "../../../components/VerticalLayout/SettingSidebar";
import Switch from "react-switch"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap"
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import GeneralSettingMenu from "../menu/general-setting-menu";
import UpdateButton from "components/buttons/updateBtn";
import { toast, ToastContainer } from "react-toastify";
import { API_URL } from 'helpers/app_url';
import axios from "axios"
import { isEmpty, map, size } from "lodash";

class EmailMapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailMapperList: []
    }

    this.handleSubmitMapper = this.handleSubmitMapper.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    //'https://rscoreapi.azurewebsites.net/api/ProjectEmailTemplate/alltemplates' 0;//parseInt(localStorage.getItem("userId")),
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(`${API_URL}CustomEmailTemplate/alltemplates`, {
      params: {
        agentId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ emailMapperList: res.data });
      });
  }

  handleSubmitMapper = (values, actions) => {
    toast.loading("Please wait...");
    const payload = {
      id: 0,
      customEmailTemplateId: "b107ace4-ceb2-4d7d-acb6-28454c66c431",
      from: values.mappingFromEmail,
      to: values.mappingToEmail,
      templateId: parseInt(values.mappingTemplate),
      agentId: parseInt(localStorage.getItem("userId")),
      isActive: true
    }
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    var authOptions = {
      method: 'post',
      url: `${API_URL}CustomEmailTemplate`,
      data: payload,
      headers: { Authorization: str }
    };

    axios(authOptions)
      .then((response) => {
        if (response.data.success) {
          toast.dismiss();
          toast.success("Email Mapped Created");
          this.loadData();
        }
        else {
          toast.dismiss();
          toast.success(response.data.validationErrors[0]);
          this.loadData();
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.success("Error" + error);
      })
  }

  handleDelete = (emailM) => {
    toast.loading("Please wait...");
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    var authOptions = {
      method: 'delete',
      url: `${API_URL}CustomEmailTemplate/` + emailM.id,
      headers: { Authorization: str }
    };

    axios(authOptions)
      .then((response) => {
        if (response.data.success) {
          toast.dismiss();
          toast.success("Email Mapped Delete");
          this.loadData();
        }
        else {
          toast.dismiss();
          toast.success(response.data.validationErrors[0]);
          this.loadData();
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.success("Error" + error);
      })
  };

  render() {
    const { emailMapperList } = this.state;

    return (
      <React.Fragment>
        <ToastContainer autoClose={2000}></ToastContainer>
        <div className="page-content">
          <MetaTags>
            <title>General Setting Security | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row className="mb20">
              <Col xl="6">
                <Breadcrumbs
                  title={this.props.t("Agent")}
                  breadcrumbItem={this.props.t("General Settings")}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>
              <Col xl="9">
                <GeneralSettingMenu />
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    mappingFromEmail: "",
                    mappingToEmail: "",
                    mappingTemplate: ""
                  }}
                  validationSchema={Yup.object().shape({
                    mappingFromEmail: Yup.string().email('Invalid email').required('This Field in required'),
                    mappingToEmail: Yup.string().email('Invalid email').required('This Field in required'),
                    mappingTemplate: Yup.string().required("This Field in required"),
                  })}

                  onSubmit={this.handleSubmitMapper}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Card>
                        <CardBody>
                          <h5 className="card-title">Email Mapper</h5>

                          <Row>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="mappingFromEmail">
                                  From Email
                                </Label>
                                <Field
                                  name="mappingFromEmail"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.mappingFromEmail && touched.mappingFromEmail
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="mappingFromEmail"
                                />
                                <ErrorMessage
                                  name="mappingFromEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="mappingToEmail">
                                  To Email
                                </Label>
                                <Field
                                  name="mappingToEmail"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.mappingToEmail && touched.mappingToEmail
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="mappingToEmail"
                                />
                                <ErrorMessage
                                  name="mappingToEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="2">

                              <FormGroup className="mb-3">
                                <Label htmlFor="mappingTemplate">
                                  Template
                                </Label>
                                <Field as="select" name="mappingTemplate"
                                  className={
                                    "form-control" +
                                    (errors.mappingTemplate && touched.mappingTemplate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={handleChange}>
                                  <option value="">Select Template</option>
                                  <option value="1">Toronto Board</option>
                                </Field>

                                <ErrorMessage
                                  name="mappingTemplate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <UpdateButton btnClass="mt-27">Add</UpdateButton>

                            </Col>


                          </Row>




                        </CardBody>
                      </Card>


                      <Card>
                        <CardBody>
                          <h5 className="card-title">Email Mapper List</h5>
                          <div className="">
                            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                              <thead className="">
                                <tr>
                                  <th>#</th>

                                  <th>From</th>

                                  <th>To</th>
                                  <th>Template</th>

                                  <th>Action</th>


                                </tr>
                              </thead>
                              <tbody>
                                {map(emailMapperList, (emailM, classkey) => (
                                  <tr key={"_list_" + classkey}>
                                    <th>{classkey + 1}</th>
                                    <td>{emailM.from}</td>
                                    <td>{emailM.to}</td>
                                    <td>{emailM.templateId === 1 ? "Toronto Board" : ""}</td>
                                    <td>
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleDelete(emailM);
                                        }}
                                        className="action-button"
                                      >
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
EmailMapper.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
}
const mapStateToProps = () => (
  {
  })
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailMapper))
