import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import {getMasterTemp} from "../../../store/templates/actions"
import { map } from "lodash"
import axios from "axios"
import { API_URL } from "../../../helpers/app_url";
import cm from "../../../assets/img/page-1.png"
import lt from "../../../assets/img/page-2.png"
import { getProjectUUIDAction} from "../../../store/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import cm1 from "../../../assets/img/cm1.jpg"
import cm2 from "../../../assets/img/cm2.jpg"
import cm3 from "../../../assets/img/cm3.jpg"
import ld1 from "../../../assets/img/ld-1.jpg"
import ld2 from "../../../assets/img/ld-2.jpg"
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    CardFooter,

  } from "reactstrap"

import Moment from "react-moment";
import WebpagesMenu from "./menu/webpages-menu";
import WebPageBreadcrumbs from "components/Common/WebpageBreadcrumb";
import CopyText from "components/buttons/copyBtn";

class projectLandingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          alltemplates:[]
   
        }

      }

     
        
      componentDidMount() {

        const { match: { params }, ongetProjectUUIDAction, onGetMasterTemp} = this.props;
    
        if (params && params.projectId) {
          ongetProjectUUIDAction(params.projectId);

          const obj = JSON.parse(localStorage.getItem("authUser"));
          const token = obj.resultData.bearerToken;
          const str = 'Bearer ' + token;
          
            axios.get('https://rscoreapi.azurewebsites.net/api/ProjectMasterLandingPageTemplate/alltemplates', { headers: { Authorization: str } })
              .then(res => {
                const alltemplates = res.data;
                this.setState({ alltemplates });
              })
        }
      }  
      

      render() {
        const { projectData, templates } = this.props;
       

        return (
          <React.Fragment>
              <div className="page-content project-page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <WebPageBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Landing Page")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
                <WebpagesMenu />
                <Row>
                   
                    <Col lg="12">
                   
                        <Card>
                       <CardBody>
                        
                         
                          <Row>
                          
                                <Col sm="12">
                                  <h5><strong>Active Url: </strong> <a target="_blank" rel="noreferrer" href={`http://pages.realtyspacehub.com/`+ this.props.match.params.projectId }>{`http://pages.realtyspacehub.com/`+ this.props.match.params.projectId }</a> <CopyText textToCopy={`http://pages.realtyspacehub.com/`+ this.props.match.params.projectId} /></h5>
                                </Col>
                               </Row>
                         
                        
                              
                           
                        </CardBody> 
                      
                        </Card>
                        <Card>
                          <CardBody>
                              <h4 className="card-title mb-4">Select your landing page theme</h4>

                              <Row>
                              {
                              this.state.alltemplates.map(alltemplate => {
                                const imageMap = {
                                  4: cm2,
                                  3: cm3,
                                  6: cm1, 
                                  1: cm1, 
                                  7: cm1, 
                                  8: ld1, 
                                  9: ld1, 
                                  10: ld1, 
                                };

                                const defaultImage = lt;

                                return (
                                  <Col sm="3" key={alltemplate.id}>
                                    <Card className="landingPageThumb">
                                      <CardBody>
                                      <div className="imgThubm">
                                        <Link to="#">
                                          <img 
                                            src={imageMap[alltemplate.id] || defaultImage}
                                            className="img-fluid"
                                            alt={`Template ${alltemplate.id}`}
                                          />
                                        </Link>
                                      </div>
                                      </CardBody>
                                      <CardFooter>
                                        <Row>
                                          <Col sm="6">
                                            <Link to="#">Preview</Link>
                                          </Col>

                                          <Col sm="6">
                                          <Link className="float-end" to={`/pre-construction/landing-editor/${this.props.match.params.projectId}/${alltemplate.id}`}>Select</Link>
                                          </Col>
                                        </Row>
                                      </CardFooter>
                                      
                                    </Card>
                                  </Col>
                                );
                              })
                            }
                                  
                              </Row>

                             
                          </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Col>
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectLandingPage.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    className: PropTypes.any,
    onGetMasterTemp:PropTypes.func,

    templates:PropTypes.array

  }
  const mapStateToProps = ({ Project,Template }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading,
      templates: Template.templates,

  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    onGetMasterTemp:(agentId) => dispatch(getMasterTemp(agentId))

  });
export default connect(mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(projectLandingPage)))
