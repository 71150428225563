import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import videohelpImg from "../../assets/img/video-help-icon.png";
import helpvideo from "../../assets/video/RS-Tutorial.mp4"
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const RsHelp = ({ to, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.preventDefault(); 
    setIsOpen(true);
    if (onClick) onClick();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Link to={to} className="btn btn-edit" onClick={handleOpenModal}>
        <img src={videohelpImg} style={{ width: 48 }} alt="Help" />
      </Link>

      {isOpen && (
        <ModalVideo
          channel="custom" 
          isOpen={isOpen}
          onClose={handleCloseModal}
        >
        
          <div className="custom-video-container">
            <video
              controls
              width="100%"
              src={helpvideo}
              type="video/mp4"
              style={{ borderRadius: "8px" }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </ModalVideo>
      )}
    </>
  );
};

RsHelp.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
 
};

RsHelp.defaultProps = {
  to: "#",
  onClick: () => {},
  videoSrc: "", // Default to no video source
};

export default RsHelp;
