import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getProjectUUIDAction, addProjectInvite, getProjectInvite, deleteProjectInvite, getProjectInviteAtt, updateProjectInvite } from "../../../store/projects/actions"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Moment from 'react-moment';

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  TabContent,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { event } from "jquery";
import WebpagesMenu from "./menu/webpages-menu";
import WebPageBreadcrumbs from "components/Common/WebpageBreadcrumb";
import SaveButton from "components/buttons/save";
import UpdateButton from "components/buttons/updateBtn";

class projectRsvp extends Component {
  constructor(props) {
    super(props)
    this.state = {
        event:''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.showallAtt = this.showallAtt.bind(this);
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleAtt() {
    this.setState(prevState => ({
      isAtt: !prevState.isAtt
    }));
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (event) => {
    this.setState({ event: event });
    this.setState({ deleteModal: true });
  };

  handleDelete = () => {
    const { onDelete } = this.props;
    const { event } = this.state;
   
    if (event.id !== undefined) {
      onDelete(event);
      this.setState({ deleteModal: false });
    }
  };
  handleStartTime = (setFieldValue, values) => {
    const { startHrs, startMin, startDif } = values;
    const hours = parseInt(startHrs, 10);
    const minutes = parseInt(startMin, 10);
    const meridiem = startDif === 'PM' && hours < 12 ? hours + 12 : hours;
    const time = `${meridiem}:${minutes < 10 ? '0' + minutes : minutes}`;

    setFieldValue('eventStartTime', time);
  };

  handleEndTime = (setFieldValue, values) => {
    const { endHrs, endMin, endDif } = values;
    const hours = parseInt(endHrs, 10);
    const minutes = parseInt(endMin, 10);
    const meridiem = endDif === 'PM' && hours < 12 ? hours + 12 : hours;
    const time = `${meridiem}:${minutes < 10 ? '0' + minutes : minutes}`;

    setFieldValue('eventEndTime', time);
  };
  

  
  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction, onGetProjectInvite } = this.props;

    if (params && params.projectId) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectInvite(params.projectId)
    
    }
  }
 
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setFieldValue('address', place.formatted_address);
        setFieldValue('longitude', longitude);
        setFieldValue('latitude', latitude);
      })
      .catch(error => console.error('Error', error));
  };
  handleSubmit = (values, actions) => {
    const payload = {
      projectId: values.projectId,
      projectName: values.projectName,
      eventName: values.eventName,
      eventDate: values.eventDate,
      eventStartTime: values.eventStartTime,
      eventEndTime: values.eventEndTime,
      eventType: parseInt(values.eventType),
      address: values.address,
      location: values.location,
      longitude: values.longitude.toString(),
      latitude: values.latitude.toString(),
      AgentId:parseInt(localStorage.getItem("userId")),
      IsActive:true
    };
    this.props.addProjectInvite(payload)
  }
  toggleRightCanvas() {
    this.setState({ event: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const event = arg;
    this.setState({
      event: {
          id: event.id,
          projectEventInviteId:event.projectEventInviteId,
          projectId:event.projectId,
          projectName:event.projectName,
          eventName:event.eventName,
          eventDate:event.eventDate,
          eventStartTime:event.eventStartTime,
          eventEndTime:event.eventEndTime,
          eventType:event.eventType,
          address:event.address,
          location:event.location,
          longitude:event.longitude,
          latitude:event.latitude,
          agentId:event.agentId,
          isActive:event.isActive
      },
      isEdit: true,
    });
    this.toggle();
  };
  showallAtt() {
    this.setState({ event: "", isEdit: false, isAtt: !this.state.isAtt });
    this.toggleAtt();
  }
  showallAtt = arg => {
    const event = arg;
    const { onGetProjectInviteAtt } = this.props;
    this.setState({
      event: {
          id:event.id
      }
    });
    onGetProjectInviteAtt(event.id);
    this.toggleAtt();
  };
  parseTime(time) {
    if (!time) {
      return { hour: '', minute: '', amPm: 'AM' }; // Default values if time is not provided
    }
    const [hour, minute] = time.split(":").map(Number);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
    const amPm = isPM ? "PM" : "AM";
    return { hour: formattedHour, minute, amPm };
  }
  render() {
    const { projectData, invitesdata, attdatas, onUpdateProjectInvite } = this.props;
    const { isEdit, deleteModal } = this.state;
    const event = this.state.event;
    console.log(event);
    const { hour: startHrs, minute: startMin, amPm: startDif } = this.parseTime(event?.eventStartTime);
    const { hour: endHrs, minute: endMin, amPm: endDif } = this.parseTime(event?.eventEndTime);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
       
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDelete}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <WebPageBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Invite / RSVP Page")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
                <WebpagesMenu />
            <Row>

              <Col lg="12">
            <Card>
                  <CardBody>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                            <div className="link-page">
                      
                      <div className="open-bg">
                        <h4>Collect responses from potential attendees for an event</h4>

                        <h5>Benefits</h5>

                        <ul>
                            <li>Efficient attendee management.</li>
                            <li>Lead generation and contact information collection.</li>
                            <li>Enhanced targeted marketing and personalized communication.</li>
                        </ul>
                        <h5>Steps to manage Invite/RSVP page</h5>
                        <ul>
                            <li>Use the “Email Campaign” page to send mass emails to Leads, Contacts and Clients and inform them of the event.</li>
                            <li>Once a Prospect fills his Invite, his information is available as a “Lead” in the Project “Activity”.</li>
                      
                        </ul>
                        
                      </div>
                      <Formik
              enableReinitialize={true}
              initialValues={{
                projectId:projectData.id,
                projectGuid:projectData.preConsProjectGuid,
                projectName:projectData.projectName,
                eventType:'',
                address:'',
                longitude:'',
                latitude:'',
                eventStartTime:'',
                eventEndTime:'',
                eventName: (this.state && this.state.eventName) || "",
                eventDate: (this.state && this.state.eventDate) || "",
                startHrs: (this.state && this.state.startHrs) || "",
                startMin: (this.state && this.state.startMin) || "",
                endHrs: (this.state && this.state.endHrs) || "", 
                endMin: (this.state && this.state.endMin) || "", 
                location: (this.state && this.state.location) || "",
               

              }}
              validationSchema={Yup.object().shape({
                eventName: Yup.string().required("This is Required"),
                eventDate: Yup.string().required("This is Required"),
                startHrs : Yup.string().required("This is Required"),
                startMin : Yup.string().required("This is Required"),
                endHrs : Yup.string().required("This is Required"),
                endMin : Yup.string().required("This is Required"),
                eventType : Yup.string().required("This is Required"),
                address: Yup.string().when('eventType', {
                  is: '1',
                  then: Yup.string().required('This is Required'),
                }),
                location: Yup.string().when('eventType', {
                  is: '2',
                  then: Yup.string().required('This is Required'),
                }),
              })}

              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                    <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="eventName">
                          {this.props.t("Name *")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="2">
                    <FormGroup className="mb-3">
                                       
                                       <Field
                                         name="eventName"
                                         className={
                                          "form-control" +
                                          (errors.eventName && touched.eventName
                                          ? " is-invalid"
                                          : "")
                                        }
                                         onChange={handleChange}
                                         type="text"
                                       />

                                       <ErrorMessage
                                         name="eventName"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </FormGroup>
                       
                    </Col>
                  </Row>

                  <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="eventDate *">
                          {this.props.t("Enter date for invite *")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="2">
                    <Flatpickr
                        className={
                            "form-control" +
                            (errors.eventDate && touched.eventDate
                            ? " is-invalid"
                            : "")
                        }
                        placeholder=""
                        onChange={(value) => setFieldValue('eventDate', value[0])}
                        options={{
                            altInput: false,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            minDate: "today"
                        }}
                                  />
                        <ErrorMessage
                          name="eventDate"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="startHrs">
                          {this.props.t("Enter start time *")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <Field
                        name="startHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startHrs && touched.startHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="startHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="1">
                    <Field
                        name="startMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startMin: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startMin && touched.startMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="startMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="1">
                    <Field
                        name="startDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startDif && touched.startDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="startDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="endHrs">
                          {this.props.t("Enter end time *")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <Field
                        name="endHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endHrs && touched.endHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="endHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="1">
                    <Field
                        name="endMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endMin && touched.endMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="endMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="1">
                    <Field
                        name="endDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endDif && touched.endDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="endDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="eventType">
                          {this.props.t("Event Type")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="3">
                    <div className="btn-group" role="group">
                        <Field
                          type="radio"
                          className="btn-check"
                          name="eventType"
                          id="btnradio4"
                          value="1"
                          checked={values.eventType === '1'}
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('address', '');
                            setFieldValue('location', '');
                            setFieldValue('longitude', '');
                            setFieldValue('latitude', '');
                          }}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio4">In-person</label>

                        <Field
                          type="radio"
                          className="btn-check"
                          name="eventType"
                          id="btnradio5"
                          value="2"
                          checked={values.eventType === '2'}
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('address', '');
                            setFieldValue('location', '');
                            setFieldValue('longitude', '');
                            setFieldValue('latitude', '');
                          }}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio5">Online</label>
                      </div>
                    <ErrorMessage
                          name="eventType"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    </Row>
                    {values.eventType === '1' && (
                    <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Event Address")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="3">
                    <FormGroup className="mb-3">
                                       
                    <PlacesAutocomplete
                            value={values.address}
                              onChange={(value) => setFieldValue('address', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    </Row>
                    )}
                  {values.eventType === '2' && (
                    <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Event Link")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="3">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="location"
                                          className={
                                            "form-control" +
                                            (errors.location && touched.location
                                            ? " is-invalid"
                                            : "")
                                        }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="location"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    </Row>
                    )}
                  <div className="clearfix">
                    <SaveButton>Save</SaveButton>
                   </div>


                </Form>
              )}
                      </Formik>
                        </div>
                     
                    </TabContent>
                  </CardBody>
                </Card>

                <Card>
                        <CardBody>
                          <h5 className="card-title" style={{marginBottom:20}}>All Events</h5>
                          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Date</th>
                                                                    <th>Start Time</th>
                                                                    <th>End Time</th>
                                                                    <th>Type</th>
                                                                    <th>Location</th>
                                                                    <th>Link</th>
                                                                    <th>Send invites</th>
                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {map(invitesdata, (event, prokey) => (
                                                             <tr key={prokey}>
                                                              <td>{event.eventName}</td>
                                                              <td><Moment format="D MMM YY">{event.eventDate}</Moment></td>
                                                              <td><Moment format="hh:mm A" parse="HH:mm">{event.eventStartTime}</Moment></td>
                                                              <td><Moment format="hh:mm A" parse="HH:mm">{event.eventEndTime}</Moment></td>
                                                              <td>{event.eventType === 1 ? 'In-person': 'Online'}</td>
                                                              <td>{event.eventType === 1 ? event.address : event.location}</td>
                                                              <td><a href={"https://openhouseinvite.realtyspacehub.com/" + event.projectEventInviteId} rel="noreferrer" target="_blank">View</a></td>
                                                              <td><Link to={"/pre-construction/invite-editor/" + event.projectId + "/" + event.projectEventInviteId + "/14"} className="btn btn-primary">Send Invite</Link></td>
                                                              <td>
                                                              <UncontrolledDropdown direction="top">
                                                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                      Action <i className="mdi mdi-chevron-down"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                     <DropdownItem tag={Link} onClick={() => this.toggleRightCanvas(event)}>Edit</DropdownItem> 
                                                                      <DropdownItem tag={Link} onClick={() => this.showallAtt(event)}>View Attendees</DropdownItem>
                                                                      <DropdownItem tag={Link} onClick={() => this.onClickDelete(event)}>Delete</DropdownItem>


                                                                    </DropdownMenu>
                                                                  </UncontrolledDropdown>
                                                              </td>
                                                             </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                           
                         
                       
                        </CardBody>
                      </Card>
              </Col>
            </Row>


            <Row>

            </Row>
            </Col>
            </Row>
          </Container>
          <Offcanvas
              isOpen={this.state.isAtt}
              direction="end"
              toggle={this.showallAtt}
              className="attCanvas"
             
            >
              <OffcanvasHeader className="submittedWorksheet" toggle={this.showallAtt}>
              View Attendees  <span className="float-end"><i className=" bx bx-download"></i></span>
              </OffcanvasHeader>
              <OffcanvasBody>
              <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Phone</th>
                                                                    <th>Email</th>
                                                                    <th>Event Slot</th>
                                                                    <th>Is Realtor</th>
                                                                    

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {map(attdatas, (attdata, prokey) => (
                                                             <tr key={prokey}>
                                                              <td>{attdata.name}</td>
                                                              <td>{attdata.phoneNo}</td>
                                                              <td>{attdata.email}</td>
                                                              <td>{attdata.eventSlot}</td>
                                                              <td>{attdata.isRealator ? 'Yes' : 'No' }</td>
                                                              
                                                              
                                                             </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
              </OffcanvasBody>
            </Offcanvas>
          <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Event
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
              enableReinitialize={true}
              initialValues={{
                id: event.id,
                projectEventInviteId:event.projectEventInviteId,
                projectId:event.projectId,
                projectName:event.projectName,
                eventName:event.eventName,
                eventDate:event.eventDate,
                eventStartTime:event.eventStartTime,
                eventEndTime:event.eventEndTime,
                startHrs: startHrs.toString(),
                startMin: startMin.toString().padStart(2, "0"), // Ensure "00" format
                startDif,
                endHrs: endHrs.toString(),
                endMin: endMin.toString().padStart(2, "0"),
                endDif,
                eventType:parseInt(event.eventType),
                address:event.address,
                location:event.location,
                longitude:event.longitude,
                latitude:event.latitude,
                agentId:event.agentId,
                isActive:event.isActive
               

              }}
              validationSchema={Yup.object().shape({
                eventName: Yup.string().required("This is Required"),
                eventDate: Yup.string().required("This is Required"),
                startHrs : Yup.string().required("This is Required"),
                startMin : Yup.string().required("This is Required"),
                endHrs : Yup.string().required("This is Required"),
                endMin : Yup.string().required("This is Required"),
                eventType : Yup.string().required("This is Required"),
                address: Yup.string().when('eventType', {
                  is: '1',
                  then: Yup.string().required('This is Required'),
                }),
                location: Yup.string().when('eventType', {
                  is: '2',
                  then: Yup.string().required('This is Required'),
                }),
              })}

              onSubmit={values => {
                //console.log(isEdit);
               
                  const updateAllocation = {
                  
                    id: values.id,
                    projectEventInviteId:values.projectEventInviteId,
                    projectId:values.projectId,
                    projectName:values.projectName,
                    eventName:values.eventName,
                    eventDate:values.eventDate,
                    eventStartTime:values.eventStartTime,
                    eventEndTime:event.eventEndTime,
                    eventType:event.eventType,
                    address:values.address,
                    location:values.location,
                    longitude:values.longitude.toString(),
                    latitude:values.latitude.toString(),
                    agentId:values.agentId,
                    isActive:values.isActive
                  };

                  onUpdateProjectInvite(updateAllocation);
                
                this.toggle();
              }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                    <Row>
                   
                    <Col md="12">
                    <FormGroup className="mb-3">
                    <Label htmlFor="eventName">
                          {this.props.t("Name")}
                        </Label>  
                                       <Field
                                         name="eventName"
                                         className={
                                          "form-control" +
                                          (errors.eventName && touched.eventName
                                          ? " is-invalid"
                                          : "")
                                        }
                                         onChange={handleChange}
                                         type="text"
                                       />

                                       <ErrorMessage
                                         name="eventName"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </FormGroup>
                       
                    </Col>
                  </Row>

                  <Row>
                   
                    <Col md="12">
                    <FormGroup className="mb-3">
                        <Label htmlFor="eventDate">
                          {this.props.t("Enter date for invite")}
                        </Label>
                       
                     
                    <Flatpickr
                        className={
                            "form-control" +
                            (errors.eventDate && touched.eventDate
                            ? " is-invalid"
                            : "")
                        }
                       value={values.eventDate}
                        onChange={(value) => setFieldValue('eventDate', value[0])}
                        options={{
                            altInput: false,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            minDate:'today'
                        }}
                                  />
                       </FormGroup>
                        <ErrorMessage
                          name="eventDate"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                  </Row>
                  <Row style={{marginTop:10}}>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="startHrs">
                          {this.props.t("Enter start time")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="4">
                    <Field
                        name="startHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startHrs && touched.startHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="startHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="startMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startMin: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startMin && touched.startMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="startMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="startDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleStartTime(setFieldValue, { ...values, startDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.startDif && touched.startDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="startDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row style={{marginTop:10}}>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="endHrs">
                          {this.props.t("Enter end time")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="4">
                    <Field
                        name="endHrs"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endHrs && touched.endHrs
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Hrs</option>
                              {[...Array(12)].map((_, i) => (
                                <option key={i + 1} value={i + 1} label={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                        </Field>
                        <ErrorMessage
                          name="endHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="endMin"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endHrs: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endMin && touched.endMin
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                         <option value="">Mins</option>
                         {[0, 15, 30, 45].map((value) => (
                          <option key={value} value={value}>
                              {value === 0 ? '00' : value}
                          </option>
                        ))}
                        </Field>
                        <ErrorMessage
                          name="endMin"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4">
                    <Field
                        name="endDif"
                        as="select"
                        
                        onChange={e => {
                          handleChange(e);
                          this.handleEndTime(setFieldValue, { ...values, endDif: e.target.value });
                        }}
                        className={
                            "form-control" +
                            (errors.endDif && touched.endDif
                            ? " is-invalid"
                            : "")
                        }
                        id="hrs"
                        >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                        </Field>
                        <ErrorMessage
                          name="endDif"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                  </Row>
                  <Row style={{marginTop:20}}>
                    <Col md="12">
                    <Label htmlFor="eventType">
                          {this.props.t("Event Type")}
                        </Label>
                    </Col>
                    <Col md="6">
                    <div className="btn-group" role="group">
                        <Field
                          type="radio"
                          className="btn-check"
                          name="eventType"
                          id="btnradio5"
                          value={1}
                          checked={values.eventType === 1}
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('address', '');
                            setFieldValue('location', '');
                            setFieldValue('longitude', '');
                            setFieldValue('latitude', '');
                          }}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio5">In-person</label>

                        <Field
                          type="radio"
                          className="btn-check"
                          name="eventType"
                          id="btnradio6"
                          value={2}
                          checked={values.eventType === 2}
                          onChange={e => {
                            handleChange(e);
                            setFieldValue('address', '');
                            setFieldValue('location', '');
                            setFieldValue('longitude', '');
                            setFieldValue('latitude', '');
                          }}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio6">Online</label>
                      </div>
                    <ErrorMessage
                          name="eventTypes"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    </Row>
                    {values.eventType === 1 && (
                    <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Event Address")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="12">
                    <FormGroup className="mb-3">
                                       
                    <PlacesAutocomplete
                            value={values.address}
                              onChange={(value) => setFieldValue('address', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                                        <ErrorMessage
                                          name="eventLocation"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    </Row>
                    )}
                  {values.eventType === 2 && (
                    <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Event Link")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="12">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="location"
                                          className={
                                            "form-control" +
                                            (errors.location && touched.location
                                            ? " is-invalid"
                                            : "")
                                        }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="location"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    </Row>
                    )}
                  <div className="clearfix"><UpdateButton>Update</UpdateButton></div>


                </Form>
              )}
            </Formik>                                            
              </OffcanvasBody>
            </Offcanvas>
        </div>
      </React.Fragment>
    )
  }
}

projectRsvp.propTypes = {
  t: PropTypes.any,
  history: PropTypes.func,
  match: PropTypes.object,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
 loading: PropTypes.object,
 addProjectInvite:PropTypes.func,
 onGetProjectInvite:PropTypes.func,
 invitesdata:PropTypes.array,
 onDelete:PropTypes.func,
 onGetProjectInviteAtt:PropTypes.func,
 attdatas:PropTypes.array,
 onUpdateProjectInvite:PropTypes.func
}
const mapStateToProps = ({ Project }) => (
  {
    projectData: Project.projectData,
    invitesdata:Project.invitesdata,
    loading: Project.loading,
    attdatas:Project.attdatas


  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectInvite: (data) => dispatch(addProjectInvite(data)),
  onGetProjectInvite:(projectId) => dispatch(getProjectInvite(projectId)),
  onDelete:(id) => dispatch(deleteProjectInvite(id)),
  onGetProjectInviteAtt:(EventId) => dispatch(getProjectInviteAtt(EventId)),
  onUpdateProjectInvite: (data) => dispatch(updateProjectInvite(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectRsvp)))
