import { template } from "lodash"
import { CREATE_MASTER_EMAIL_TEMPLATE, CREATE_MASTER_EMAIL_TEMPLATE_FAIL, CREATE_MASTER_EMAIL_TEMPLATE_SUCCESS, GET_MASTER_EMAIL_TEMPLATE, GET_MASTER_EMAIL_TEMPLATE_BY_ID, GET_MASTER_EMAIL_TEMPLATE_BY_ID_FAIL, GET_MASTER_EMAIL_TEMPLATE_BY_ID_SUCCESS, GET_MASTER_EMAIL_TEMPLATE_FAIL, GET_MASTER_EMAIL_TEMPLATE_SUCCESS, GET_MASTER_LANDING_PAGE_TEMPLATE, GET_MASTER_LANDING_PAGE_TEMPLATE_FAIL, GET_MASTER_LANDING_PAGE_TEMPLATE_SUCCESS, GET_TEMPLATES, GET_TEMPLATES_FAIL, GET_TEMPLATES_SUCCESS, GET_TEMPLATE_BY_ID, GET_TEMPLATE_BY_ID_FAIL, GET_TEMPLATE_BY_ID_SUCCESS, GET_TEMPLATE_BY_PROJECT_ID, GET_TEMPLATE_BY_PROJECT_ID_FAIL, GET_TEMPLATE_BY_PROJECT_ID_SUCCESS, GET_TEMPLATE_BY_TYPE, GET_TEMPLATE_BY_TYPE_FAIL, GET_TEMPLATE_BY_TYPE_SUCCESS, LOAD_INVITE_DATA_TEMPLATE, LOAD_INVITE_DATA_TEMPLATE_FAIL, LOAD_INVITE_DATA_TEMPLATE_SUCCESS, LOAD_MASTER_EMAIL_TEMPLATE, LOAD_MASTER_EMAIL_TEMPLATE_FAIL, LOAD_MASTER_EMAIL_TEMPLATE_SUCCESS, LOAD_MASTER_LANDINGPAGE_TEMPLATE, LOAD_MASTER_LANDINGPAGE_TEMPLATE_FAIL, LOAD_MASTER_LANDINGPAGE_TEMPLATE_SUCCESS } from "./actionTypes"
  
  
  export const getAllTemplates = () => ({
    type: GET_TEMPLATES
  })
  
  export const getAllTemplatesSuccess = templates => ({
    type: GET_TEMPLATES_SUCCESS,
    payload: templates,
  })
  
  export const getAllTemplatesFail = error => ({
    type: GET_TEMPLATES_FAIL,
    payload: error,
  })



  export const getAllTemplateByType = (typeId,agentId) => ({
    type: GET_TEMPLATE_BY_TYPE,
    payload: { typeId, agentId }
  })
  
  export const getAllTemplateByTypeSuccess = typetemplates => ({
    type: GET_TEMPLATE_BY_TYPE_SUCCESS,
    payload: typetemplates,
  })
  
  export const getAllTemplateByTypeFail = error => ({
    type: GET_TEMPLATE_BY_TYPE_FAIL,
    payload: error,
  })


  export const getAllTemplateById = (id) => ({
    type: GET_TEMPLATE_BY_ID,
    id
  })
  
  export const getAllTemplateByIdSuccess = templateData => ({
    type: GET_TEMPLATE_BY_ID_SUCCESS,
    payload: templateData,
  })
  
  export const getAllTemplateByIdFail = error => ({
    type: GET_TEMPLATE_BY_ID_FAIL,
    payload: error,
  })


  export const getAllTemplateByProjectId = (projectid, templateid) => ({
    type: GET_TEMPLATE_BY_PROJECT_ID,
    payload:{projectid,templateid}
  })
  
  export const getAllTemplateByProjectIdSuccess = projectTempData => ({
    type: GET_TEMPLATE_BY_PROJECT_ID_SUCCESS,
    payload: projectTempData,
  })
  
  export const getAllTemplateByProjectIdFail = error => ({
    type: GET_TEMPLATE_BY_PROJECT_ID_FAIL,
    payload: error,
  })
  

  export const CreateNewMasterEmail = (template, history) => {
    return {
      type: CREATE_MASTER_EMAIL_TEMPLATE,
      payload: { template, history },
    }
  }
  
  export const CreateNewMasterEmailSuccess = template => {
    return {
      type: CREATE_MASTER_EMAIL_TEMPLATE_SUCCESS,
      payload: template,
    }
  }
  
  
  export const CreateNewMasterEmailSuccessError = error => {
    return {
      type: CREATE_MASTER_EMAIL_TEMPLATE_FAIL,
      payload: error,
    }
  }


  export const getMasterTemp = (agentId) => ({
    type: GET_MASTER_EMAIL_TEMPLATE,
    agentId
  })
  
  export const getMasterTempSuccess = templates => ({
    type: GET_MASTER_EMAIL_TEMPLATE_SUCCESS,
    payload: templates,
  })
  
  export const getMasterTempFail = error => ({
    type: GET_MASTER_EMAIL_TEMPLATE_FAIL,
    payload: error,
  })


  export const getTempById = (id) => ({
    type: GET_MASTER_EMAIL_TEMPLATE_BY_ID,
    id
  })
  
  export const getTempByIdSuccess = datatemp => ({
    type: GET_MASTER_EMAIL_TEMPLATE_BY_ID_SUCCESS,
    payload: datatemp,
  })
  
  export const getTempByIdFail = error => ({
    type: GET_MASTER_EMAIL_TEMPLATE_BY_ID_FAIL,
    payload: error,
  })

  export const loadEmailMasterTemplate = (projectId, templateId) => ({
    type: LOAD_MASTER_EMAIL_TEMPLATE,
    payload:{projectId, templateId}
  })
  
  export const loadEmailMasterTemplateSuccess = masterData => ({
    type: LOAD_MASTER_EMAIL_TEMPLATE_SUCCESS,
    payload: masterData,
  })
  
  export const loadEmailMasterTemplateFail = error => ({
    type: LOAD_MASTER_EMAIL_TEMPLATE_FAIL,
    payload: error,
  })
  

  export const getLandingPageMasterTemp = (agentId) => ({
    type: GET_MASTER_LANDING_PAGE_TEMPLATE,
    agentId
  })
  
  export const getLandingPageMasterTempSuccess = pagetemplates => ({
    type: GET_MASTER_LANDING_PAGE_TEMPLATE_SUCCESS,
    payload: pagetemplates,
  })
  
  export const getLandingPageMasterTempFail = error => ({
    type: GET_MASTER_LANDING_PAGE_TEMPLATE_FAIL,
    payload: error,
  })



  export const loadlandingMasterTemplate = (projectId, templateId, agentId) => ({
    type: LOAD_MASTER_LANDINGPAGE_TEMPLATE,
    payload:{projectId, templateId, agentId}
  })
  
  export const loadlandingMasterTemplateSuccess = landingMasterData => ({
    type: LOAD_MASTER_LANDINGPAGE_TEMPLATE_SUCCESS,
    payload: landingMasterData,
  })
  
  export const loadlandingMasterTemplateFail = error => ({
    type: LOAD_MASTER_LANDINGPAGE_TEMPLATE_FAIL,
    payload: error,
  })


  export const loadEventMasterTemplate = (eventId, templateId) => ({
    type: LOAD_INVITE_DATA_TEMPLATE,
    payload:{eventId, templateId}
  })
  
  export const loadEventMasterTemplateSuccess = eventMasterData => ({
    type: LOAD_INVITE_DATA_TEMPLATE_SUCCESS,
    payload: eventMasterData,
  })
  
  export const loadEventMasterTemplateFail = error => ({
    type: LOAD_INVITE_DATA_TEMPLATE_FAIL,
    payload: error,
  })