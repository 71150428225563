import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map, size } from "lodash"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectFeature, getProjectFeatureAction, updateProjectFeature, deleteProjectFeature, getProjectFeatureStandard, updateProjectFeatureStandard, getProjectUUIDAction } from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import JoditEditor from 'jodit-react';
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,

  TabContent,
  FormGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Tooltip,
  Label
} from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";
import RsAdNew from "components/buttons/Adnew";
import SaveButton from "components/buttons/save";
import UpdateButton from "components/buttons/updateBtn";
import ActionEdit from "components/buttons/actionEdit";


class ProjectsFeature extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectFeature: "",
      projectStandardFeature:""

    }
    this.handleProjectFeature = this.handleProjectFeature.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleStandardRightCanvas = this.toggleStandardRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
  }
  handleProjectFeature(value) {
    this.props.addProjectFeature(value, this.props.history);
    this.toggleNew();
  }

  componentDidMount() {
    const { match: { params }, onGetProjectFeature, onGetProjectFeatureStandard, ongetProjectUUIDAction } = this.props;
    if (params  && params.projectId) {
      onGetProjectFeature(params.projectId);
      onGetProjectFeatureStandard(params.projectId)
      ongetProjectUUIDAction(params.projectId);
    } 
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { projectFeatures } = this.props;
    if (!isEmpty(projectFeatures) && size(prevProps.projectFeatures) !== size(projectFeatures)) {
      this.setState({ projectFeatures: {}, isEdit: false });
    }
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (projectStandardFeature) => {
    this.setState({ projectStandardFeature: projectStandardFeature });
    this.setState({ deleteModal: true });
  };

  handleDeleteFeature = () => {
    const { onDeleteFeature } = this.props;
    const { projectStandardFeature } = this.state;
    if (projectStandardFeature.id !== undefined) {
      console.log(projectStandardFeature)
      onDeleteFeature(projectStandardFeature);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleNew() {
    this.setState(prevState => ({
      isAddNew: !prevState.isAddNew
    }));
  }
  toggleStandard() {
    this.setState(prevState => ({
      isStandardRight: !prevState.isStandardRight
    }));
  }
  toggleAddNew() {
    this.setState({  isAddNew: !this.state.isAddNew });
    
  }
  toggleStandardRightCanvas() {
    this.setState({ projectStandardFeature: "", isStandardEdit: false, isStandardRight: !this.state.isStandardRight });
    this.toggleStandard();
  }
  toggleStandardRightCanvas = arg => {
    const projectStandardFeature = arg;

    this.setState({
      projectStandardFeature: {
        id: projectStandardFeature.id,
        projectAdditionalFeatureId: projectStandardFeature.projectAdditionalFeatureId,
        projectId: projectStandardFeature.projectId,
        projectAdditionalFeatureTitle: projectStandardFeature.projectAdditionalFeatureTitle,
        projectAdditionalFeatureDescription: projectStandardFeature.projectAdditionalFeatureDescription,
        projectAdditionalFeatureIsActive: projectStandardFeature.projectAdditionalFeatureIsActive,
      },
      isStandardEdit: true,
    });
    //console.log(projectStandardFeature)
    this.toggleStandard();
  };


  toggleRightCanvas() {
    this.setState({ projectFeature: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const projectFeature = arg;

    this.setState({
      projectFeature: {

        id: projectFeature.id,
        projectAdditionalFeatureId: projectFeature.projectAdditionalFeatureId,
        projectId: projectFeature.projectId,

        projectAdditionalFeatureTitle: projectFeature.projectAdditionalFeatureTitle,
        projectAdditionalFeatureDescription: projectFeature.projectAdditionalFeatureDescription,
        projectAdditionalFeatureIsActive: projectFeature.projectAdditionalFeatureIsActive,

      },
      isEdit: true,
    });
    this.toggle();
  };
  getFeatureTitle = (projectAdditionalFeatureTitle) => {
    if (projectAdditionalFeatureTitle === 'ProjectOverview') {
      return 'Amenities';
    } else if (projectAdditionalFeatureTitle === 'ProjectSummary') {
      return 'Brief intro';
    } else if (projectAdditionalFeatureTitle === 'ProjectHighlights') {
      return 'Project highlights';
    } else if (projectAdditionalFeatureTitle === 'ProjectPriceFee') {
      return 'Price Fee';
    } else if (projectAdditionalFeatureTitle === 'ProjectDepositStructure') {
      return 'Deposit Structure';
    }else if (projectAdditionalFeatureTitle === 'ProjectCurrentIncentive') {
      return 'Current Incentive';
    } else if (projectAdditionalFeatureTitle === 'ProjectInternationalDepositStructure') {
      return 'Current Incentive';
    }  else if (projectAdditionalFeatureTitle === 'ProjectLocationNeighbourhood') {
      return 'Locations and Neighborhood';
    } else if (projectAdditionalFeatureTitle === 'ProjectFeatureFinishes') {
      return 'Features & finishes';
    }else if (projectAdditionalFeatureTitle === 'ProjectDeveloper') {
      return 'About the developer';
    } else {
      return 'Default Title';
    }
  };
 
  render() {
    const { projectFeatures, onUpdateFeature, projectStandardFeatures, onUpdateFeatureStandard,projectData } = this.props;
    const { isEdit, deleteModal, isStandardEdit } = this.state;
    const projectFeature = this.state.projectFeature;
    const projectStandardFeature =  this.state.projectStandardFeature;
    const title = this.getFeatureTitle(projectStandardFeature.projectAdditionalFeatureTitle);
    return (

      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteFeature}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Features")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
                <SetupMenu />
                <Card>
              <CardBody>
              
                <Row>
                  <Col sm="6">
                  <h5 className="card-title mb20">Featured Content</h5>
                  </Col>
                  <Col sm="6">
                  <span className="float-end mb20">
                  <RsAdNew onClick={this.toggleAddNew}>Add New</RsAdNew>
                  
                  </span>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                   
                        
                        <div className="">
                         <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={projectStandardFeatures} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                              <Column  field="Title" header="Title" body={(rowData) =>   {
                                      if (rowData.projectAdditionalFeatureTitle == 'ProjectOverview') {
                                        return (<><strong>Amenities</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectSummary') {
                                        return (<><strong>Brief intro</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectHighlights') {
                                        return (<><strong>Project highlights</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectPriceFee') {
                                        return (<><strong>Price Fee</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectDepositStructure') {
                                        return (<><strong>Deposit Structure</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectCurrentIncentive') {
                                        return (<><strong>Current Incentive/</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectInternationalDepositStructure') {
                                        return (<><strong>International Deposit Structure</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectLocationNeighbourhood') {
                                        return (<><strong>Locations and Neighborhood</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectFeatureFinishes') {
                                        return (<><strong>Features & finishes</strong></>)
                                      } else if (rowData.projectAdditionalFeatureTitle == 'ProjectDeveloper') {
                                        return (<><strong>About the developer</strong></>)
                                      }else{
                                        return (<><strong>{rowData.projectAdditionalFeatureTitle}</strong></>)
                                      }
                                      
                                    }}></Column>
                              <Column  field="eventTitle" header="" body={(rowData) =>(
                                (rowData.projectAdditionalFeatureDescription != null && rowData.projectAdditionalFeatureDescription != '') ? rowData.projectAdditionalFeatureDescription.slice(0, 100).replace(/<[^>]*>?/gm, '') : 'No Content'
                              )}>  </Column>
                              <Column  field="eventTitle" header="" body={(rowData) => (
                                  <ActionEdit onClick={() => this.toggleStandardRightCanvas(rowData)} />
                                
                               )}>  </Column>

                          </DataTable>
                        </div>
                     
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
              </CardBody>
            </Card>
          
            </Col>
            </Row>
            
            <Offcanvas
              isOpen={this.state.isAddNew}
              direction="end"
              toggle={this.toggleAddNew}
              style={{width:600}}
            >
              <OffcanvasHeader toggle={this.toggleAddNew}>
                New Feature
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                      projectId: projectData.id,
                      projectGuid:projectData.preConsProjectGuid,
                      projectAdditionalFeatureTitle: '',
                      projectAdditionalFeatureDescription: ''
                    }}
                    validationSchema={Yup.object().shape({
                      projectAdditionalFeatureTitle: Yup.string().required("This is required")

                    })}

                    onSubmit={this.handleProjectFeature}

                  >
                    {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                      

                        <Row>

                          <Col md="12">
                            <FormGroup className="mb-3">
                            <Label htmlFor="projectAdditionalFeatureTitle">Title*</Label>
                              <Field
                                name="projectAdditionalFeatureTitle"
                                className={
                                  "form-control" +
                                  (errors.projectAdditionalFeatureTitle && touched.projectAdditionalFeatureTitle
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={handleChange}
                                type="text"
                              />
                              <ErrorMessage
                                name="projectAdditionalFeatureTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                            <FormGroup>
                              {/* <h6 style={{ marginTop: 30 }}>

                                <strong>{this.props.t("Content")}</strong>
                              </h6> */}
                              <Label htmlFor="projectAdditionalFeatureDescription">Content</Label>
                              <JoditEditor
                                value={values.projectAdditionalFeatureDescription} 
                                onBlur={(newContent) => {
                                  setFieldValue('projectAdditionalFeatureDescription', newContent);
                                }}
                                config={{
                                  readonly: false,
                                  placeholder: "Start typing here...",
                                  height: 400,
                                
                                }}
                              />
                            </FormGroup>
                          </Col>

                        </Row>






                        <div><SaveButton>Save</SaveButton></div>

                      </Form>
                    )}
                  </Formik>

              </OffcanvasBody>
            </Offcanvas>

            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Feature
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: projectFeature.id,
                    projectAdditionalFeatureId: projectFeature.projectAdditionalFeatureId,
                    projectId:  projectData.id,
                    projectAdditionalFeatureTitle: projectFeature.projectAdditionalFeatureTitle,
                    projectAdditionalFeatureDescription: projectFeature.projectAdditionalFeatureDescription || '',
                    projectAdditionalFeatureIsActive: projectFeature.projectAdditionalFeatureIsActive,
                  }}
                  validationSchema={Yup.object().shape({
                    projectAdditionalFeatureTitle: Yup.string().required("This is required")

                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateFeature = {
                        id: values.id,
                        projectAdditionalFeatureId: values.projectAdditionalFeatureId,
                        projectId: values.projectId,
                        projectAdditionalFeatureTitle: values.projectAdditionalFeatureTitle,
                        projectAdditionalFeatureDescription: values.projectAdditionalFeatureDescription,
                        projectAdditionalFeatureIsActive: values.projectAdditionalFeatureIsActive,
                      };

                      onUpdateFeature(updateFeature);
                    } else {

                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}

                >
                  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>

                        <Col md="12">
                          <FormGroup className="mb-3">
                            <h4>
                              <strong>{this.props.t("Title")}</strong>
                            </h4>
                            <Field
                              name="projectAdditionalFeatureTitle"
                              className={
                                "form-control" +
                                (errors.projectAdditionalFeatureTitle && touched.projectAdditionalFeatureTitle
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={handleChange}
                              type="text"
                            />
                            <ErrorMessage
                              name="projectAdditionalFeatureTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                          <FormGroup>
                            <h6 style={{ marginTop: 30 }}>

                              <strong>{this.props.t("Content")}</strong>
                            </h6>
                            <CKEditor
                              editor={ClassicEditor}
                              data={projectFeature.projectAdditionalFeatureDescription || ''}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('projectAdditionalFeatureDescription', data);

                              }}

                            />
                          </FormGroup>
                        </Col>

                      </Row>






                      <div><UpdateButton>Update</UpdateButton></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>


            <Offcanvas
              isOpen={this.state.isStandardRight}
              direction="end"
              toggle={this.toggleStandardRightCanvas}
              style={{width:600}}
            >
              <OffcanvasHeader toggle={this.toggleStandardRightCanvas}>
              {title}
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: projectStandardFeature.id,
                    projectAdditionalFeatureId: projectStandardFeature.projectAdditionalFeatureId,
                    projectId: projectData.id,
                    projectGuid:projectData.preConsProjectGuid,
                    projectAdditionalFeatureTitle: projectStandardFeature.projectAdditionalFeatureTitle,
                    projectAdditionalFeatureDescription: projectStandardFeature.projectAdditionalFeatureDescription || '' ,
                    projectAdditionalFeatureIsActive: projectStandardFeature.projectAdditionalFeatureIsActive,
                  }}
                  validationSchema={Yup.object().shape({
                    //projectAdditionalFeatureTitle: Yup.string().required("This is required")

                  })}

                  onSubmit={values => {
                    //console.log(isStandardEdit);
                    if (isStandardEdit) {
                      const updateStandFeature = {
                        id: values.id,
                        projectFeatureId: values.projectAdditionalFeatureId,
                        projectGuid:values.projectGuid,
                        projectId: values.projectId,
                        projectFeatureName: values.projectAdditionalFeatureTitle,
                        projectFeatureValue: values.projectAdditionalFeatureDescription,
                        
                      };
                      //console.log(values)
                      onUpdateFeatureStandard(updateStandFeature);
                    } else {

                    }

                    //this.setState({ selectedOrder: null });
                    this.toggleStandard();
                  }}

                >
                  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>

                        <Col md="12">
                      
                          <FormGroup>
                            <h6 style={{ marginTop: 30 }}>

                              <strong>{this.props.t("Add your content")}</strong>
                            </h6>
                            <JoditEditor
                            value={values.projectAdditionalFeatureDescription} 
                            onBlur={(newContent) => {
                              setFieldValue('projectAdditionalFeatureDescription', newContent);
                            }}
                            config={{
                              toolbar: true,
                              placeholder: "Type your text here...",
                              height: 400, 
                            }}
                          />
                          </FormGroup>
                        </Col>

                      </Row>






                      <div><UpdateButton>Update</UpdateButton></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


ProjectsFeature.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  addProjectFeature: PropTypes.func,
  match: PropTypes.object,
  projectFeatures: PropTypes.array,
  onGetProjectFeature: PropTypes.func,
  onUpdateFeature: PropTypes.func,
  loading: PropTypes.object,
  onDeleteFeature: PropTypes.func,
  onGetProjectFeatureStandard: PropTypes.func,
  projectStandardFeatures: PropTypes.func,
  onUpdateFeatureStandard:PropTypes.func,
  ongetProjectUUIDAction:PropTypes.func,
  projectData: PropTypes.object,
  //ProjectsFeature:PropTypes.object
}
const mapStateToProps = ({ Project }) => (
  {
    //projectFeatures: Project.projectFeatures,
    projectStandardFeatures: Project.projectStandardFeatures,
    projectData: Project.projectData,
    loading: Project.loading

  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectFeature: (data) => dispatch(addProjectFeature(data)),
  onGetProjectFeature: (projectId) => dispatch(getProjectFeatureAction(projectId)),
  onUpdateFeature: (data) => dispatch(updateProjectFeature(data)),
  onDeleteFeature: (id) => dispatch(deleteProjectFeature(id)),
  onGetProjectFeatureStandard: (projectId) => dispatch(getProjectFeatureStandard(projectId)),
  onUpdateFeatureStandard:(data) => dispatch(updateProjectFeatureStandard(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsFeature)))