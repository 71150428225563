import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

class SettingSidebar extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
       //console.log(matchingMenuItem);
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm2-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm2-active") // li
          parent3.childNodes[0].classList.add("mm2-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm2-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const userModule = localStorage.getItem('userModule');
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar" ref={this.refDiv}>
          <div id="sidebar-menu" className="setting-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to="/setting/agent-profile" className=" ">
                  <i className="bx bx-user-plus" />
          
                  <span>{this.props.t("Profile")}</span>
                </Link>
              </li>
              <li>
                <Link to="/setting/general/currency" className=" ">
                  <i className="bx bx-user-plus" />
          
                  <span>{this.props.t("General Setting")}</span>
                </Link>
              </li>
              <li>
                <Link to="/setting/membership" className=" ">
                  <i className="bx bx-user-plus" />
          
                  <span>{this.props.t("Membership Info")}</span>
                </Link>
              </li>
              <li>
                <Link to="/setting/support" className=" ">
                  <i className="bx bx-user-plus" />
          
                  <span>{this.props.t("Support Ticket")}</span>
                </Link>
              </li>
              <li className="mm-data">
                <Link to="/" className="has-arrow ">
                  <i className="bx bxs-building-house" />
          
                  <span>{this.props.t("Object")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                      <>
                  <li>

                  <Link to="/setting/object/manage-marketing">{this.props.t("Manage Marketing Sources")}</Link></li>
                  <li><Link to="/setting/object/review-distribution">{this.props.t("Review Distribution")}</Link></li>
                  </>
                     ) :'' 
                }
                  <li><Link to="/" className="has-arrow">{this.props.t("Appointment")}</Link>
                  
                  <ul className="sub-menu" aria-expanded="false">
                  { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                    <li><Link to="/setting/object/app-location-type">{this.props.t("Location Types")}</Link></li>
                    ) : '' 
                  }
                   { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                    <li><Link to="/setting/object/app-priority">{this.props.t("Priorities")}</Link></li>
                    ) : '' 
                  }
                   { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                    <li><Link to="/setting/object/app-type">{this.props.t("Type")}</Link></li>
                    ) : '' 
                  }
                    </ul>
                  </li>
                
                  
                  <li><Link to="/" className="has-arrow">{this.props.t("Contact")}</Link>
                  <ul className="sub-menu" aria-expanded="false">
                  { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                  <li><Link to="/setting/object/contact-level">{this.props.t("Contact Level")}</Link></li>
                     ) :'' 
                  }
                   { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                    <li><Link to="/setting/object/contact-level">{this.props.t("Contact Status")}</Link></li>
                  ) :'' 
                }
                 { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                  <li><Link to="/setting/object/contact-type">{this.props.t("Contact Type")}</Link></li>
                     ) : ''
                }
                  </ul>
                  
                  </li>

                  { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                      <>
                   <li><Link to="/" className="has-arrow">{this.props.t("Lead")}</Link>
                  <ul className="sub-menu" aria-expanded="false">
                  <li><Link to="/setting/object/lead-classification">{this.props.t("Classifications")}</Link></li>
                  <li><Link to="/setting/object/lead-source">{this.props.t("Sources")}</Link></li>
                  <li><Link to="/setting/object/lead-status">{this.props.t("Status")}</Link></li>
                  <li><Link to="/setting/object/lead-type">{this.props.t("Types")}</Link></li>
                  <li><Link to="/setting/object/lead-stage">{this.props.t("Stage")}</Link></li>
                 </ul>
                  </li>
                  </>
                ) : '' 
              }
                { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                      <>
                  <li><Link to="/setting/object/note-type">{this.props.t("Note Types")}</Link></li>
                  </>
                ) : '' 
              }
               { userModule.indexOf("BrokerAgent.RealtorSettings.Add")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Add")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Add") > -1  ?
                     ( 
                      <>
                  <li><Link to="/setting/object/project-doc-type">{this.props.t("Project Document Types")}</Link></li>
                  </>
                ) : '' 
              }
                
                  <li><Link to="/setting/object/project-status">{this.props.t("Project Status")}</Link></li>
                  <li><Link to="/setting/object/project-type">{this.props.t("Project Type")}</Link></li>
                  <li><Link to="/setting/object/service-type">{this.props.t("Service Type")}</Link></li>
                  <li><Link to="/setting/object/task-priority">{this.props.t("Task Priorities")}</Link></li>
                  <li><Link to="/setting/object/task-reminder">{this.props.t("Task Reminders")}</Link></li>
                  <li><Link to="/setting/object/task-status">{this.props.t("Task Status")}</Link></li>
                

                <li><Link to="/setting/object/transaction-type">{this.props.t("Transaction Type")}</Link></li>
                <li><Link to="/setting/object/transaction-comm-type">{this.props.t("Transaction Commission Type")}</Link></li>
                <li><Link to="/setting/object/transaction-ref-type">{this.props.t("Transaction Refrence Type")}</Link></li>
                <li><Link to="/setting/object/transaction-status-type">{this.props.t("Transaction Status Type")}</Link></li>

                <li><Link to="/setting/object/listing-type">{this.props.t("Listing Type")}</Link></li>
                <li><Link to="/setting/object/listing-status">{this.props.t("Listing Status")}</Link></li>
                <li><Link to="/setting/object/showing-status">{this.props.t("Showing Status")}</Link></li>

                <li><Link to="/setting/object/email-list-type">{this.props.t("Email List Type")}</Link></li>

                <li><Link to="/setting/object/email-list-target">{this.props.t("Email List Target")}</Link></li>
                <li><Link to="/setting/object/campaign-email-list">{this.props.t("Campaign Email List")}</Link></li>
                </ul>
                
              </li>
              <li><Link to="/setting/users"> <i className="bx bx-user-plus" /> {this.props.t("Users")}</Link></li>
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

SettingSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SettingSidebar))
