import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
import Dropzone from "react-dropzone"
import { addProjectGallery, getProjectGallary, deleteProjectGallery, getProjectUUIDAction } from "../../../../store/projects/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from "../../../../helpers/azureConfig";
import Swal from 'sweetalert2'
import {
  Row,
  Col,
  Card,
  Form
} from "reactstrap"
//Import Breadcrumb
import { classnames } from "helpers/helper";


class ProjectsExteriorGallery extends Component {
  constructor(props) {
    super(props)
    //this.imageInputRef = React.useRef();
    this.state = {
     
      selectedFiles: [],
      image:'',
      activeTab: "1",
      
    }
  
   
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (image) => {
    this.setState({ image: image });
    this.setState({ deleteModal: true });
  };

  handleDeleteGallery = () => {
    const { onDeleteGallery } = this.props;
    const { image } = this.state;
    //console.log(document);
    if (image.id !== undefined) {
      onDeleteGallery(image);
      this.setState({ deleteModal: false });
    }
  };
  handleAcceptedFiles = async (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map(file => {
      file.preview = URL.createObjectURL(file);
      file.formattedSize = this.formatBytes(file.size);
      return file;
    });

    this.setState({ selectedFiles: filesWithPreview });

    filesWithPreview.forEach(file => this.uploadFileToAzure(file));
  };

  uploadFileToAzure = async (file) => {
    const maxSize = 2 * 1024 * 1024; // 5 MB
    const allowedTypes = ['image/jpeg', 'image/png'];
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

  // Validate file type
  if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
    Swal.fire({
      title: 'File type or extension not allowed',
      text: 'Please upload a JPG, or PNG file.',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
    return;
  }

  // Validate file size
  if (file.size > maxSize) {
    Swal.fire({
      title: 'File size exceeded',
      text: 'Please upload a file smaller than 2MB.',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
    return;
  }
    const sasToken = azureConfig.sasToken
    const containerName = azureConfig.containerName;
    const accountName = azureConfig.storageAccountName;
   

    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net?${sasToken}`
    );
    const fileName = `${Date.now()}_${file.name}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      await blockBlobClient.uploadBrowserData(file);
    const fileUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}`;
    const data = {
      'projectId': parseInt(this.props.projectData.id),
      'projectGuid': this.props.projectData.preConsProjectGuid,
        'agentId': parseInt(localStorage.getItem('userId')),
        'projectGalleryTitle' : fileName,
        'projectGalleryLink' : fileUrl,
        'projectGalleryTypeId' : 1
    }
    this.props.addProjectGallery(data, this.props.history);
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage', error);
    }
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };


 
  render() {
   
      return (
      <React.Fragment>
        <Form>
                  <Dropzone onDrop={this.handleAcceptedFiles}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Drop files here or click to upload.</h4>
                <h5>Maximum upload file size: 2 MB.
                  <br />
                  Supported file formats: JPG/JPEG, PNG, GIF, 
                </h5>
              </div>
            </div>
          )}
                    </Dropzone>
                        {/* <div className="dropzone-previews mt-3" id="file-previews">
                          {this.state.selectedFiles.map((f, i) => (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + '-file'}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link to="#" className="text-muted font-weight-bold">
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          ))}
                        </div> */}
                    </Form>

                    
      
      </React.Fragment>
    )
  }
}

ProjectsExteriorGallery.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  match:PropTypes.object,
  addProjectGallery:PropTypes.func,
  images:PropTypes.array,
  onDeleteGallery:PropTypes.func,
  projectData:PropTypes.object

}

const mapStateToProps = ({ Project }) => ({
 
})

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
  
  onDeleteGallery: (id) => dispatch(deleteProjectGallery(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsExteriorGallery)))