import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBuilderDetail, addBuilderDepart, getProjectUUIDAction } from "../../../store/actions";
import ProjectList from "../../../components/VerticalLayout/ProjectList";
import performance_img from '../../../assets/img/performance.png'
import cost_pl_img from '../../../assets/img/cost_pl.png'
import {
  Col,
  Container,
  Row,
  Card,
  CardBody
} from "reactstrap"
import ProjectChart from "./detail-chart/project-chart";
import RsLink from "components/buttons/rsLink";
class ProjectBuilderDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      titles:[],
      departments:[]
      
    }
  }
 
  componentDidMount() {
    const { match: { params }, onGetProjectUUIDAction} = this.props;
    console.log(params);
    onGetProjectUUIDAction(params.projectId)
  }


  render() {
      const { projectData } =  this.props;
      
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Builder Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
            <Row>
            <Col sm='2' className="pe-0" >
                <ProjectList />
              </Col>  
              <Col sm='10' className="ps-0">
                <Row className="project-header">
                  <Col sm="6">
                  <h2 className="" style={{paddingTop:20, paddingBottom:20}}>{projectData.projectName}</h2>
                  </Col>

                  <Col sm="6">
                  <div className="pt-2 float-end mt-2">
                  <RsLink className="btn btn-light" iconClass="bx bx-link-external"  to={"/pre-construction/campaign/" + projectData.id + "/" + projectData.projectId }>View Project</RsLink>
                  </div>
                  </Col>
                </Row> 

                <ProjectChart />

                <Row style={{marginTop:40}}>
           
           
           <Col md="6">
             <Card className="mini-stats-wid" style={{borderBottom:0}}>
                     <CardBody>
                       <div className="d-flex">
                         <div className="flex-grow-1">
                           <h4 className="">
                               Marketing 
                           </h4>
                           <h5 className="mb-0">Facebook : $2500.00</h5>
                           <h5 className="mb-0">Google Ads : $4700.00</h5>
                           <h5 className="mb-0">Radio: $1250.00</h5>
                          
                         </div>
                         <div className="align-self-center">
                           <img src={performance_img} alt="performance PNG" width='50px'/>
                         </div>
                        
                       </div>
                     </CardBody>
                   </Card>
             
           </Col>
           <Col md="6" >
             <Card className="mini-stats-wid" style={{borderBottom:0}}>
                     <CardBody>
                       <div className="d-flex">
                         <div className="flex-grow-1">
                         <h4 className="">
                           Cost per lead
                           </h4>
                           <h5 className="mb-0">#Lead : 45<br/>
                              <small>Cost per lead $275.00</small>
                           </h5>
                           <h5 className="mb-0">#Clients : 15<br/>
                           <small>Cost per client $450.00</small>
                           </h5>
                         </div>
                         <div className="align-self-center">
                           <img src={cost_pl_img} alt="performance PNG" width='50px'/>
                         </div>
                       </div>
                     </CardBody>
                   </Card>
             
           </Col>
          

          
         </Row>
                </Col>


            
             </Row>
            
            
              
           
          </Container>
        </div>
      </React.Fragment>
    )
  }


}

ProjectBuilderDetail.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  projectData: PropTypes.object,
  match: PropTypes.object,
  onGetProjectUUIDAction: PropTypes.func,
  loading:PropTypes.object
}

//console.log(builders);

const mapStateToProps = ({ Project  }) => ({
  projectData: Project.projectData,
  loading:Project.loading
});

const mapDispatchToProps = dispatch => ({
  onGetProjectUUIDAction: id => dispatch(getProjectUUIDAction(id)),
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectBuilderDetail)))