import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import product1 from "../../../assets/img/pr-1.jpg";
import noImg  from "../../../assets/img/no-pr-img.jpg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBuilderDetail, getBuilderProject } from "../../../store/builder/actions";
import { isEmpty, map } from "lodash";
import axios from "axios"
import backIcon from "../../../assets/images/rsicons/backIcon.png"
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import BuilderChart from "./detail-chart/builder-chart";
class BuilderDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
   
  }
 

  componentDidMount() {
    const { match: { params }, onGetBuilderDetail, onGetBuilderProject } = this.props;
    if (params && params.id && params.builderId) {
     onGetBuilderDetail(params.builderId);
     onGetBuilderProject(params.id, localStorage.getItem('userId'))
    } 
  }


  render() {
    
    const { builderData, builderProjects } = this.props;
   
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Builder Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            {/* Render Breadcrumbs */}
            <Row className="project-header">
            <Link to={"/pre-construction/builder" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline" style={{marginTop:20}}/>
                </Link>
            <Breadcrumbs
              title={this.props.t("Builder")}
              breadcrumbItem={builderData.builderName}
            />
            </Row>
            <BuilderChart />
              <Row className="mt20">
                <Col sm="12"><h3>Current Projects</h3></Col>
              </Row>
            <Row className="">
            {map(builderProjects, (builderProject, prokey) => (
                <Col sm="2" key={prokey}>
                  <div className="card">
                  <div className="card-header">
                    <h4>{builderProject.projectName}</h4>
                  </div>  
                    <div className="card-body p-0">
                    <Link to={"/pre-construction/bproject-detail/" + builderProject.projectBuilder +"/"+ builderProject.projectId}> <img src={builderProject.projectLogoLink ? builderProject.projectLogoLink : noImg } alt="" style={{ width: '100%' }} /></Link>
                    </div>

                    <div className="bg-transparent border-top card-footer">
                        <div className="contact-links d-flex font-size-20">
                            <div className="flex-fill">
                                <Link to="#">
                                    Status: {builderProject.isActive ? 'Active' : 'In-active'}
                                </Link>
                            </div>
                    
                      <div className="float-end">
                      <Link to={"/pre-construction/bproject-detail/" + builderProject.projectBuilder +"/"+ builderProject.projectId}>
                            Detail
                      </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
           ))}
              
             
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }


}

BuilderDetail.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  builderData: PropTypes.object,
  match: PropTypes.object,
  onGetBuilderDetail: PropTypes.func,
  loading:PropTypes.object,
  onGetBuilderProject:PropTypes.func,
  builderProjects:PropTypes.array
}

//console.log(builders);

const mapStateToProps = ({ Builder }) => ({
  builderData: Builder.builderData,
  builderProjects:Builder.builderProjects,
  loading:Builder.loading
});

const mapDispatchToProps = dispatch => ({
  onGetBuilderDetail: id => dispatch(getBuilderDetail(id)),
  onGetBuilderProject: (id, agentId) => dispatch(getBuilderProject(id, agentId))
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(BuilderDetail)))