import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import * as moment from 'moment';
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllProjectAction, deleteProject, getSearchAllProject, updateProjectStatus  } from "../../../store/projects/actions"
import axios from "axios"
import DeleteModal from "../../../components/Common/DeleteModal";
import Switch from "react-switch"
import ProjectChart from "./chart/project-dashboard";
import ProjectMenu from "./menu/all-project"
import {
  Col,
  Container,
  Row,
  Table,
   Card,
  CardBody,
  Label,
  Input,
    FormGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    CardFooter,
    CardTitle,
    Form
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import Moment from 'react-moment';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RsCard from "components/Rscards/Rscard";
import RSBarChart from "components/charts/RSBarChart";
import RsAdNew from "components/buttons/Adnew";
import RsLink from "components/buttons/rsLink";
import { API_URL } from "helpers/app_url";

class ProjectCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProjects: {
        project1: "0",
        project2: "0",
        project3: "0",
        project4: "0",
      },
    }
   
    
  }
  filterProjectOptions = (currentKey) => {
    const { selectedProjects } = this.state;
    const { projects } = this.props;
    const selectedIds = Object.keys(selectedProjects)
      .filter((key) => key !== currentKey)
      .map((key) => selectedProjects[key]);
  
    return projects.filter((project) => !selectedIds.includes(project.projectId));
  };

  handleProjectChange = (projectKey, selectedValue) => {
    const { selectedProjects } = this.state;

    if (Object.values(selectedProjects).includes(selectedValue)) {
      alert("This project is already selected. Please choose another.");
      return;
    }

    this.setState(
      (prevState) => ({
        selectedProjects: {
          ...prevState.selectedProjects,
          [projectKey]: selectedValue,
        },
      }),
      this.submitToAPI 
    );
  };

  submitToAPI = () => {
    const { selectedProjects } = this.state;
    const projectIds = Object.values(selectedProjects).filter((id) => id !== "0");
  
    const payload = {
      brokerId: localStorage.getItem("brokerId"),
      projectIds: projectIds.join(","), 
      isActive: 1,
    };
  
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const queryString = new URLSearchParams(payload).toString();
  
    axios.get(`${API_URL}BrokerProjectExt/comparefulldetailforagent?${queryString}`, {
        headers,
      })
      .then((response) => {
        console.log("API Response:", response.data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };
  componentDidMount() { 
    const { onGetAllProject} = this.props;
    onGetAllProject(localStorage.getItem('userId'),localStorage.getItem('brokerId'));
  }
 
 
  render() {
    const userModule = localStorage.getItem('userModule');
    const { projects } = this.props;
    const { selectedProjects } = this.state;
    
    return (
      <React.Fragment>
           {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
          
       
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            {/* Render Breadcrumbs */}
           
           <Row className="project-header mb20">
              <Col lg="6">
              <Breadcrumbs
              title={this.props.t("Project")}
              breadcrumbItem={this.props.t("Compare")}
            />
              </Col>
	
             <Col lg="6">
             { userModule.indexOf("BrokerAgent.RealtorPreConsProjects.Add") > -1  ?
                  (
                    <div className="clearfix mt-2 pt-2">
                    <RsLink to="/pre-Construction/create-new-project" className="btn btn btn-light btn-md float-end" iconClass="bx bx-plus">Add New Project</RsLink>
                   
                 </div>
            ) : ''
        }
             </Col>
           </Row>
          
            <ProjectMenu />
            <Card>
                <CardBody>
                    <Row>
                        <Col sm="4">
                            <h4>Compare Project</h4>
                        </Col>
                        <Col sm="2">
    <h4>Project 1</h4>
    <select
      name="project1"
      className="form-control"
      value={this.state.selectedProjects.project1 || "0"}
      onChange={(e) => this.handleProjectChange("project1", e.target.value)}
    >
      <option value="0">Select Project</option>
      {this.filterProjectOptions("project1").map((project) => (
        <option value={project.projectId} key={project.projectId}>
          {project.projectName}
        </option>
      ))}
    </select>
  </Col>

  <Col sm="2">
    <h4>Project 2</h4>
    <select
      name="project2"
      className="form-control"
      value={this.state.selectedProjects.project2 || "0"}
      onChange={(e) => this.handleProjectChange("project2", e.target.value)}
    >
      <option value="0">Select Project</option>
      {this.filterProjectOptions("project2").map((project) => (
        <option value={project.projectId} key={project.projectId}>
          {project.projectName}
        </option>
      ))}
    </select>
  </Col>

  <Col sm="2">
    <h4>Project 3</h4>
    <select
      name="project3"
      className="form-control"
      value={this.state.selectedProjects.project3 || "0"}
      onChange={(e) => this.handleProjectChange("project3", e.target.value)}
    >
      <option value="0">Select Project</option>
      {this.filterProjectOptions("project3").map((project) => (
        <option value={project.projectId} key={project.projectId}>
          {project.projectName}
        </option>
      ))}
    </select>
  </Col>

  <Col sm="2">
    <h4>Project 4</h4>
    <select
      name="project4"
      className="form-control"
      value={this.state.selectedProjects.project4 || "0"}
      onChange={(e) => this.handleProjectChange("project4", e.target.value)}
    >
      <option value="0">Select Project</option>
      {this.filterProjectOptions("project4").map((project) => (
        <option value={project.projectId} key={project.projectId}>
          {project.projectName}
        </option>
      ))}
    </select>
  </Col>
                       
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="4">
                            <h3 style={{fontSize:14}}>Occupancy year</h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="4">
                            <h3 style={{fontSize:14}}>City</h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="4">
                            <h3 style={{fontSize:14}}>Unit costs starting from</h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="4">
                            <h3 style={{fontSize:14}}>Total marketing</h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="4">
                            <h3 style={{fontSize:14}}># Clients</h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="4">
                            <h3 style={{fontSize:14}}>Cost/client</h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                       
                        <h3></h3>
                        </Col>
                        <Col sm="2">
                        <h3></h3>
                        </Col>
                    </Row>
                    <hr />
              </CardBody>
              </Card>
            

    
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectCompare.propTypes = {
  
  className: PropTypes.any,
  t: PropTypes.any,
  projects:PropTypes.array,
  onGetAllProject:PropTypes.func,
  onDeleteProject: PropTypes.func,
  loading:PropTypes.object,
  setProjectSearchComplitionYear:PropTypes.func,
  setProjectSearchCity:PropTypes.func,
  onGetSearchAllProject:PropTypes.func,
  onUpdateStatus:PropTypes.func
}

const mapStateToProps = ({ Project }) => ({
  projects:Project.projects,
  loading:Project.loading
})

const mapDispatchToProps = dispatch => ({
  onGetAllProject:(agentId, brokerId) => dispatch(getAllProjectAction(agentId, brokerId)),
  onDeleteProject: (id) => dispatch(deleteProject(id)),
  onGetSearchAllProject:(data) => dispatch(getSearchAllProject(data)),
  onUpdateStatus:(data) => dispatch(updateProjectStatus(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectCompare)))