import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
import Dropzone from "react-dropzone"
import { addProjectGallery, getProjectGallary, deleteProjectGallery } from "../../../../store/projects/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from "../../../../helpers/azureConfig";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Card, FormGroup, Label } from "reactstrap";
import SaveButton from "components/buttons/save"
import Swal from 'sweetalert2'
class ProjectsFeaturedGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
        files: [],
        fileUploaded: false,
    };
    this.submitVideoUrl = this.submitVideoUrl.bind(this)
  }

 

  async handleFileChange(event, setFieldValue) {
    const file = event.currentTarget.files[0];
    if (file) {
      const maxSize = 2 * 1024 * 1024;
      const allowedTypes = ['image/jpeg', 'image/png'];
      const allowedExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
      if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          title: 'File type or extension not allowed',
          text: 'Please upload a JPG, or PNG file.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        //alert('Invalid file type. Please upload a PDF, DOC, JPG, or PNG file.');
        return;
      }
      if (file.size > maxSize) {
        Swal.fire({
          title: 'Size not allowed',
          text: 'Please Upload Maximum 2MB File',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
       
      }
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-06-22T02:18:02Z&st=2024-06-21T18:18:02Z&spr=https,http&sig=Srg7JmJ7EfJQWUNadO188IgXNeCFEzSmQQmctuyjGxA%3D";
      const containerName = "images";
      const storageAccountName = "realtyspace";
      const fileName = `${Date.now()}_${file.name}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlockBlobClient(fileName);

      try {
        await blobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type }
        });

        //alert('File uploaded successfully!');
        if (setFieldValue) {
          const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
          setFieldValue(`projectGalleryLink`, fileUrl);
          this.setState({ fileUploaded: true });
        }
      } catch (error) {
        console.error('Error uploading file:', error);

      }
    }
  }

 
  submitVideoUrl(value){
    const payload = {
      projectId: parseInt(this.props.projectData.id),
      projectGuid: this.props.projectData.preConsProjectGuid,
      agentId: parseInt(localStorage.getItem('userId')),
      projectGalleryTitle: 'Fetured Product',
      projectGalleryLink: value.projectGalleryLink,
      projectGalleryTypeId: 7
    }
    this.props.addProjectGallery(payload);
  }
  componentDidMount() {
   
  }

  render() {
    const {image} = this.props;
    const {  displayMode, fileUploaded } = this.state;
   
    //console.log(image);
    return (
      <React.Fragment>
    

       

    { image && image.length === 0 ? (
            <Formik
              initialValues={{ projectGalleryLink: "" }}
              validationSchema={Yup.object().shape({
                
              })}
              onSubmit={this.submitVideoUrl}
            >
              {({ errors, touched, handleChange, setFieldValue }) => (
                <Form className="needs-validation">
                  <Row>
                    <Col md="4">
                    <div className="form-group mb-3">
                                      <Label htmlFor="file">Feature Image</Label>
                                      <input
                                          type="file"
                                          className="form-control"
                                          onChange={event => this.handleFileChange(event, setFieldValue)}
                                        />
                                    </div>

                    </Col>
                    <Col md="2"><div style={{marginTop:30}}>{fileUploaded && <SaveButton>Save</SaveButton>}</div></Col>
                  </Row>
                  
                </Form>
              )}
            </Formik>
     ) : (
        <></>
    )}   
      </React.Fragment>
    );
  }
}

ProjectsFeaturedGallery.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addProjectGallery: PropTypes.func,
  image:PropTypes.array,
  projectData:PropTypes.object

};

const mapStateToProps = ({ Project }) => ({
 
});

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
 
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsFeaturedGallery)));
