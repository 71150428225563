import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class GeneralSettingMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'currency',
                label: 'Currency',
                link: `/setting/general/currency`
            },
            {
                id: 'signature',
                label: 'Email Signature',
                link: `/setting/general/signature`
            },
            {
                id: 'social-media',
                label: 'Social Media Link',
                link: `/setting/general/social-media`
            },
            {
                id: 'security',
                label: 'Security',
                link: `/setting/general/security`
            },
            {
                id: 'calendar',
                label: 'Calendars',
                link: `/setting/general/calendar`
            },
            {
                id: 'emailmapper',
                label: 'Email Mapper',
                link: `/setting/general/emailmapper`
            }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}

                />
            </React.Fragment>
        )
    }
}
GeneralSettingMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(GeneralSettingMenu)
