import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
//Lightbox
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import Dropzone from "react-dropzone"
import { addProjectGallery, getProjectGallary, deleteProjectGallery, getProjectUUIDAction } from "../../../store/projects/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from "../../../helpers/azureConfig";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Form
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";
import { API_URL } from 'helpers/app_url';
import axios from "axios"
import { toast } from "react-toastify";

class ProjectsAssign extends Component {
  constructor(props) {
    super(props)
    //this.imageInputRef = React.useRef();
    this.state = {

      selectedFiles: [],
      image: '',
      activeFilter: 'All',
      agents: [],
      selectedItems: [],
      selectAll: false,
      preSelectedIds: []
    }
  }

  handleSelectAll = () => {
    const { agents, selectAll } = this.state;
    if (selectAll) {
      this.setState({ selectedItems: [], selectAll: false });
    } else {
      const allIds = agents.map((item) => item.id);
      this.setState({ selectedItems: allIds, selectAll: true });
    }
  };

  handleCheckboxChange = (id) => {
    const { selectedItems } = this.state;
    const isSelected = selectedItems.includes(id);
    const updatedSelection = isSelected
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    this.setState({ selectedItems: updatedSelection });
  };

  handleButtonClick = () => {

    toast.loading("Please wait...");
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(`${API_URL}ProjectExt/guid/?projectId=` + this.props.match.params.projectId, {
      params: {
        clientId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(resP => {

        const { selectedItems } = this.state;
        selectedItems.map((item) => {
          const payload = {
            preConsProjectAgentLinkingId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            agentLinkingType: 0,
            createdBy: parseInt(localStorage.getItem("userId")),
            preConsProjectId: parseInt(resP.data.id),
            agentId: parseInt(item),
            brokerId: parseInt(localStorage.getItem("userId")),
            isActive: true,
            preConsProjectGuid: this.props.match.params.projectId
          }

          var authOptions = {
            method: 'post',
            url: `${API_URL}PreConsProjectAgentLinking`,
            data: payload,
            headers: { Authorization: str }
          };
          toast.loading("Please wait...");
          axios(authOptions)
            .then((response) => {
              toast.dismiss();
              toast.success("Link Created");
            })
            .catch((error) => {
              toast.dismiss();
              toast.success("Error" + error);
            })
        }
        );
      })
  };




  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;

    axios.get(`${API_URL}ProjectExt/guid/?projectId=` + this.props.match.params.projectId, {
      params: {
        clientId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(resP => {
        axios.get(`${API_URL}AgentExt/AllAgentByOffice`, {
          params: {
            clientId: localStorage.getItem("userId"),
          }, headers: { Authorization: str }
        })
          .then(res => {
            this.setState({ agents: res.data });
            axios.get(`${API_URL}PreConsProjectAgentLinking/allforagent?agentId=` + localStorage.getItem("userId"), {
              headers: { Authorization: str }
            })
              .then(res1 => {                
                console.log(resP.data.id);
                console.log(res1.data);
                let matchedIds = res.data.filter(item1 =>
                  res1.data.some(item2 => item2.agentId === item1.id && item2.preConsProjectId == parseInt(resP.data.id))
                ).map(item => item.id);
                console.log(matchedIds);
                this.setState({ preSelectedIds: matchedIds });
              });
          });
      })
  }

  render() {
    const { projectData } = this.props;
    const { deleteModal, activeFilter, agents } = this.state;
    const { selectedItems, selectAll, preSelectedIds } = this.state;
    //console.log(this.state.files);

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Mapping | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
            <Row>
              <Col sm='2' className="pe-0" >
                <ProjectSidebar />
              </Col>
              <Col sm='10' className="ps-0">
                <Row className="project-header">
                  <Col sm="7">
                    <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Gallery")} />
                  </Col>
                  <Col sm="4">
                    <h2 className="">{this.props.t(projectData.projectName)}</h2>
                  </Col>
                </Row>
                <SetupMenu />

                <Card>
                  <CardBody>


                    <Row>
                      <Col lg="12">
                        <Nav tabs className="projectTab projectNav">
                          <NavItem>
                            <NavLink
                              className={`nav-link ${activeFilter === 'All' ? 'active' : ''}`}
                              onClick={() => this.handleTabClick('All')}
                            >
                              <span className="d-none d-sm-block">{this.props.t("All")}</span>
                            </NavLink>
                          </NavItem>
                          {/*                           <NavItem>
                            <NavLink
                              className={`nav-link ${activeFilter === 'Branch 1' ? 'active' : ''}`}
                              onClick={() => this.handleTabClick('Branch 1')}
                            >
                              <span className="d-none d-sm-block">{this.props.t("Branch 1")}</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`nav-link ${activeFilter === 'Branch 2' ? 'active' : ''}`}
                              onClick={() => this.handleTabClick('Branch 2')}
                            >
                              <span className="d-none d-sm-block">{this.props.t("Branch 2")}</span>
                            </NavLink>
                          </NavItem>
 */}

                        </Nav>

                      </Col>

                    </Row>
                    <div className="table-responsive">
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th className="align-middle"><input
                              type="checkbox"
                              checked={selectAll}
                              onChange={this.handleSelectAll}
                            /></Th>

                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Phone</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="1">City</Th>
                          </Tr>
                        </Thead>
                        <Tbody className="tbody">

                          {map(agents, (data, prokey) => (
                            <Tr key={data.id}>
                              <Td>
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedItems.includes(data.id) ||
                                    preSelectedIds.includes(data.id)
                                  }
                                  onChange={() => this.handleCheckboxChange(data.id)}
                                />
                              </Td>
                              <Td>  {data.firstName}  </Td>
                              <Td>  {data.phone}  </Td>

                              <Td>      {data.primaryEmail}   </Td>
                              <Td>      {data.city}   </Td>
                            </Tr>
                          ))}

                        </Tbody>
                      </Table>
                      <div>
                        <button
                          style={{
                            marginTop: "10px",
                            padding: "10px",
                            backgroundColor: "blue",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={this.handleButtonClick}
                        >
                          Assign to Project
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsAssign.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addProjectGallery: PropTypes.func,
  images: PropTypes.array,
  onDeleteGallery: PropTypes.func,
  onGetProjectGallery: PropTypes.func,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  loading: PropTypes.object
}

const mapStateToProps = ({ Project }) => ({
  images: Project.images,
  projectData: Project.projectData,
  loading: Project.loading
})

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onGetProjectGallery: (projectId) => dispatch(getProjectGallary(projectId)),
  onDeleteGallery: (id) => dispatch(deleteProjectGallery(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsAssign)))