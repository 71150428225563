import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
import Dropzone from "react-dropzone"
import { addProjectGallery, getProjectGallary, deleteProjectGallery } from "../../../../store/projects/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from "../../../../helpers/azureConfig";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Card, FormGroup, Label } from "reactstrap";
import SaveButton from "components/buttons/save"
import Swal from 'sweetalert2'
class ProjectsVideoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      image: '',
      displayMode: 'link',
    };
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
   
    this.handleRadioChange = this.handleRadioChange.bind(this); 
    this.submitVideoUrl = this.submitVideoUrl.bind(this); 
  }

  handleRadioChange(e) {
    this.setState({ displayMode: e.target.value });
  }

  handleAcceptedFiles = async (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map(file => {
      file.preview = URL.createObjectURL(file);
      file.formattedSize = this.formatBytes(file.size);
      return file;
    });

    this.setState({ selectedFiles: filesWithPreview });
    filesWithPreview.forEach(file => this.uploadFileToAzure(file));
  };

  uploadFileToAzure = async (file) => {
    const maxSize = 10 * 1024 * 1024; // 50 MB
  const allowedTypes = ['video/mp4', 'video/webm', 'video/ogg'];
  const allowedExtensions = ['.mp4', '.webm', '.ogg'];
  const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

  // Validate file type
  if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
    Swal.fire({
      title: 'Invalid File Type',
      text: 'Please upload a video file in MP4, WebM, or OGG format.',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
    return;
  }

  // Validate file size
  if (file.size > maxSize) {
    Swal.fire({
      title: 'File Too Large',
      text: 'Please upload a video smaller than 10MB.',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
    return;
  }

    const { sasToken, containerName, storageAccountName } = azureConfig;
    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );
    const fileName = `${Date.now()}_${file.name}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      await blockBlobClient.uploadBrowserData(file);
      const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
      const data = {
        projectId: parseInt(this.props.match.params.id),
        agentId: parseInt(localStorage.getItem('userId')),
        projectGalleryTitle: fileName,
        projectGalleryLink: fileUrl,
        projectGalleryTypeId: 6
      };
      this.props.addProjectGallery(data);
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage', error);
    }
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  };
  submitVideoUrl(value){
    const payload = {
      projectId: parseInt(this.props.projectData.id),
      projectGuid: this.props.projectData.preConsProjectGuid,
      agentId: parseInt(localStorage.getItem('userId')),
      projectGalleryTitle: 'Project Video',
      projectGalleryLink: value.videoUrl,
      projectGalleryTypeId: 6
    }
    this.props.addProjectGallery(payload);
  }
  componentDidMount() {
   
  }

  render() {
 
    const {  displayMode } = this.state;

    return (
      <React.Fragment>
        <div className="btn-group" role="group">
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio4"
            autoComplete="off"
            value="link"
            checked={displayMode === 'link'}
            onChange={this.handleRadioChange}
          />
          <label className="btn btn-outline-secondary" htmlFor="btnradio4">Video Url</label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio5"
            value="upload"
            autoComplete="off"
            checked={displayMode === 'upload'}
            onChange={this.handleRadioChange}
          />
          <label className="btn btn-outline-secondary" htmlFor="btnradio5">Upload</label>
        </div>

        {displayMode === 'upload' && (
          <div id="upload">
            <Dropzone onDrop={this.handleAcceptedFiles}>
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                    <h5>Maximum upload file size: 10 MB.</h5>
                  </div>
                </div>
              )}
            </Dropzone>
            {/* <div className="dropzone-previews mt-3" id="file-previews">
              {this.state.selectedFiles.map((f, i) => (
                <Card key={i} className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img data-dz-thumbnail height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">{f.name}</Link>
                        <p className="mb-0"><strong>{f.formattedSize}</strong></p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ))}
            </div> */}
          </div>
        )}

        {displayMode === 'link' && (
          <div id="link">
            <Formik
              initialValues={{ videoUrl: "" }}
              validationSchema={Yup.object().shape({
                videoUrl: Yup.string().url("Please enter a valid URL").required("This is Required")
              })}
              onSubmit={this.submitVideoUrl}
            >
              {({ errors, touched, handleChange }) => (
                <Form className="needs-validation">
                  <Row>
                    <Col md="8">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addMessage">Add Url</Label>
                        <Field
                          type="text"
                          name="videoUrl"
                          onChange={handleChange}
                          className={"form-control" + (errors.videoUrl && touched.videoUrl ? " is-invalid" : "")}
                          id="videoUrl"
                        />
                        <ErrorMessage name="videoUrl" component="div" className="invalid-feedback" />
                      </FormGroup>
                    </Col>
                    <Col md="2"><div style={{marginTop:30}}><SaveButton>Save</SaveButton></div></Col>
                  </Row>
                  
                </Form>
              )}
            </Formik>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ProjectsVideoGallery.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addProjectGallery: PropTypes.func,
  projectData:PropTypes.object
};

const mapStateToProps = ({ Project }) => ({
 
});

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
 
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsVideoGallery)));
