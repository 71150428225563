import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import setup from "../../assets/img/setup.png"
import sales from "../../assets/img/sales.png"
import email from "../../assets/img/email.png"
import market from "../../assets/img/market.png"
import webpage from "../../assets/img/webpage.png"
import deleteImg from "../../assets/img/delete.png"

//i18n
import { withTranslation } from "react-i18next"

class ProjectSidebar extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
       console.log(matchingMenuItem);
        break
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("text-blue");
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm2-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm2-active") // li
          parent3.childNodes[0].classList.add("mm2-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm2-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const userModule = localStorage.getItem('userModule');
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar" ref={this.refDiv}>
          <div id="sidebar-menu" className="setting-menu">
           
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to={"/pre-construction/my-project" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
          
                  {/* <span><b>{this.props.t("Management")}</b></span> */}
                </Link>
              </li>
            <li>
                {/* <Link to={"/pre-construction/campaign/"   + this.props.match.params.projectId}  className=" "> */}
                  {/* <i className="bx bx-user-plus" /> */}
          
                  {/* <h2 className="ps-4"><b>{this.props.t("Management")}</b></h2> */}
                {/* </Link> */}
              </li>
              <li>
                <Link to={"/pre-construction/campaign/"   + this.props.match.params.projectId} className=" project-link ">
                  {/* <i className="bx bx-user-plus" /> */}
          
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link to={"/pre-construction/create-project/description/"  + this.props.match.params.projectId} className="project-link ">
                  <img src={setup} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
          
                  <span>{this.props.t("Setup")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
              
                  <li><Link className=" project-link" to={"/pre-construction/create-project/"  + this.props.match.params.projectId} > <span>{this.props.t("Description")}</span></Link></li>
                  <li><Link className=" project-link" to={"/pre-construction/create-project/feature/"  + this.props.match.params.projectId} ><span>{this.props.t("Features")}</span></Link></li>
                  <li><Link className=" project-link" to={"/pre-construction/create-project/contact/"   + this.props.match.params.projectId} ><span>{this.props.t("Contacts")}</span></Link></li>
                  <li><Link className=" project-link" to={"/pre-construction/create-project/media/"   + this.props.match.params.projectId}><span>{this.props.t("Documents")}</span></Link></li>
                  <li><Link className=" project-link" to={"/pre-construction/create-project/link/"   + this.props.match.params.projectId}><span>{this.props.t("Document links")}</span></Link></li>
                  <li><Link className=" project-link" to={"/pre-construction/create-project/gallery/"   + this.props.match.params.projectId}><span>{this.props.t("Gallery")}</span></Link></li>
                </ul> */}
                
              </li>
          
              <li>
                <Link to={"/pre-construction/campaign/current-prospects/"   + this.props.match.params.projectId} className=" project-link ">
                
                <img src={sales} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Activity")}</span>
                </Link>
              </li>
              <li>
                <Link to={"/pre-construction/campaign/marketing/"   + this.props.match.params.projectId} className=" project-link  ">
              
                <img src={market} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Marketing")}</span>
                </Link>
              </li>
              <li>
                <Link to={"/pre-construction/campaign/send-email/"   + this.props.match.params.projectId} className=" project-link ">
                  
                <img src={email} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Email Campaigns")}</span>
                </Link>
              </li>
             
              <li className="mm-data">
                <Link to={"/pre-construction/campaign/landing-page/"  + this.props.match.params.projectId}  className="project-link">
                  {/* <i className="bx bxs-building-house" /> */}
                  <img src={webpage} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Webpages")}</span>
                </Link>
              
              </li>
           
              {/* <li><Link  to='/pre-construction/my-project' className=" project-link">
               
                 <img src={deleteImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                 <span>{this.props.t("Delete Project")}</span></Link>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

ProjectSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  match:PropTypes.object,
}

export default withRouter(withTranslation()(ProjectSidebar))
