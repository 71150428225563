import React, { Component } from "react";
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Media, Row, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Label } from "reactstrap";
import axios from 'axios';
import { API_URL } from "helpers/app_url";
import avatar2 from "../../assets/img/email-profile.jpg";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import EmailSideBar from "./email-sidebar";


import EmailToolbar from "./email-toolbar";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import UpdateButton from "components/buttons/updateBtn";
import { toast, ToastContainer } from "react-toastify";

class EmailAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailData: null,
      loading: true,
      error: null,
      agentData: '',
      clients: [],
      extractData: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

    this.fetchEmailData();

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const userId = localStorage.getItem('userId');
    axios.get(`${API_URL}Agent/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      const agentData = res.data;
      this.setState({ agentData });
    })
    //parseInt(localStorage.getItem('userId'))
    axios.get(`${API_URL}Client/allforagent`, {
      params: {
        agentId: 3
      }, headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        const clients = res.data;
        this.setState({ clients });
      })
  }

  fetchEmailData = async () => {
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const url = `${API_URL}Gmail/GetGmailByMessageId/${id}`;
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
    try {
      const response = await axios.get(url, config);
      this.setState({
        emailData: response.data,
        loading: false
      });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false
      });
    }
    const url1 = `${API_URL}Gmail/GetTemplateDetails/${id}`;
    const config1 = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
    try {
      const response1 = await axios.get(url1, config1);
      this.setState({
        extractData: response1.data
      });
    } catch (error) {
    }
  }

  handleSubmit(value) {
    toast.loading("Please wait...");
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const payload = {
      emailId: id,
      address: value.mappingAddress,
      beds: value.mappingBed,
      baths: value.mappingBath,
      notes: value.mappingNote,
      price: value.mappingPrice,
      mLSNo: value.mappingMslID,
      city: value.mappingCity,
      clientId: parseInt(value.mappingClient),
      agentId: parseInt(localStorage.getItem('userId'))
    }

    axios.post(`${API_URL}Gmail/ConvertToClient`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.data.success) {
          toast.dismiss();
          toast.success("Client Assign Successfully!");
        }
        else {
          toast.dismiss();
          toast.success(response.data.validationErrors[0]);
        }
      })
      .catch(error => {
        toast.dismiss();
        toast.success("Error" + error);
      });
  }

  render() {

    //meta title
    document.title = "Email | Realty Space";
    const { emailData, loading, error, extractData } = this.state;
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000}></ToastContainer>
        {loading ? (
          <div className="rs-loader-overlay">
            <div className="rs-loader"></div>
          </div>
        ) : null}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Row className="project-header">
              <Col xl="7">
                <Breadcrumbs title="Email" breadcrumbItem="Assign To Client" />
              </Col>
              <Col xl="5">
                <div className="pt-2 float-end mt-2">

                  <div className="">


                  </div>

                </div>

              </Col>
            </Row>


            <Row className="mt-20">
              <Col xs="12">
                {/* Render Email SideBar */}
                <div className="email-leftbar card">
                  <EmailSideBar agentData={this.state?.agentData?.agentId} />
                </div>
                <div className="email-rightbar mb-3">
                  <Card>
                    {/* Render Email Top Tool Bar */}
                    <EmailToolbar />

                    <CardBody>
                      <div className="d-flex mb-4">

                        <img
                          className="d-flex me-3 rounded-circle avatar-sm"
                          src={avatar2}
                          alt="skote"
                        />
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 mt-3">
                            {emailData?.from}
                          </h5>
                          {/* <small className="text-muted">
                            support@domain.com
                          </small> */}
                        </div>
                      </div>

                      <h4 className="mt-0 font-size-16">
                        {emailData?.subject}
                      </h4>
                      <div dangerouslySetInnerHTML={{ __html: emailData?.message }}></div>

                      {emailData?.createEmailAttachmentDetailCommands.map((attachmentDetail, key) =>
                        <div className="" key={key}>
                          {attachmentDetail.filePath}
                        </div>
                      )}

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          mappingClient: emailData?.clientId,
                          mappingMslID: extractData.mlsNo,
                          mappingBed: extractData.beds,
                          mappingBath: extractData.baths,
                          mappingAddress: extractData.address,
                          mappingPrice: extractData.price,
                          mappingNote: extractData.notes,
                          mappingCity: extractData.city,
                        }}
                        validationSchema={Yup.object().shape({
                          mappingClient: Yup.string().required("This Field in required"),
                          mappingMslID: Yup.string().required("This Field in required"),
                          mappingBed: Yup.string().required("This Field in required"),
                          mappingBath: Yup.string().required("This Field in required"),
                          mappingAddress: Yup.string().required("This Field in required"),
                          mappingPrice: Yup.string().required("This Field in required"),
                          mappingNote: Yup.string().required("This Field in required"),
                          mappingCity: Yup.string().required("This Field in required")
                        })}

                        onSubmit={this.handleSubmit}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingClient">
                                        Client Name
                                      </Label>
                                      <Field as="select" name="mappingClient"
                                        className={
                                          "form-control" +
                                          (errors.mappingClient && touched.mappingClient
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={handleChange}>
                                        <option value="">Select Client</option>
                                        {
                                          this.state.clients
                                            .map(client =>
                                              <option key={client.id} value={client.id}>{client.firstName + ' ' + client.lastName}</option>
                                            )
                                        }
                                      </Field>

                                      <ErrorMessage
                                        name="mappingClient"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingMslID">
                                        MSL ID
                                      </Label>
                                      <Field
                                        name="mappingMslID"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingMslID && touched.mappingMslID
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingMslID"
                                      />
                                      <ErrorMessage
                                        name="mappingMslID"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingBed">
                                        #Beds
                                      </Label>
                                      <Field
                                        name="mappingBed"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingBed && touched.mappingBed
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingBed"
                                      />
                                      <ErrorMessage
                                        name="mappingBed"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingBath">
                                        #Baths
                                      </Label>
                                      <Field
                                        name="mappingBath"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingBath && touched.mappingBath
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingBath"
                                      />
                                      <ErrorMessage
                                        name="mappingBath"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingAddress">
                                        Address
                                      </Label>
                                      <Field
                                        name="mappingAddress"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingAddress && touched.mappingAddress
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingAddress"
                                      />
                                      <ErrorMessage
                                        name="mappingAddress"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingPrice">
                                        Price
                                      </Label>
                                      <Field
                                        name="mappingPrice"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingPrice && touched.mappingPrice
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingPrice"
                                      />
                                      <ErrorMessage
                                        name="mappingPrice"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingNote">
                                        Notes
                                      </Label>
                                      <Field
                                        name="mappingNote"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingNote && touched.mappingNote
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingNote"
                                      />
                                      <ErrorMessage
                                        name="mappingNote"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="mappingCity">
                                        City
                                      </Label>
                                      <Field
                                        name="mappingCity"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mappingCity && touched.mappingCity
                                            ? " is-invalid"
                                            : "")
                                        }
                                        id="mappingCity"
                                      />
                                      <ErrorMessage
                                        name="mappingCity"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="3">
                                    <UpdateButton btnClass="mt-27">Assign</UpdateButton>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>

        </div>
      </React.Fragment>
    );
  }
}
EmailAssign.propTypes = {
  match: PropTypes.obj,

}
export default EmailAssign;
