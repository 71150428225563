import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Row, Col, CardBody, Card, CardText, Container, FormGroup, CardTitle, Input, Label, Offcanvas, OffcanvasHeader, OffcanvasBody,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from "reactstrap"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import SaveButton from "components/buttons/save";
import RsAdNew from "components/buttons/Adnew";
import RSBarChart from "components/charts/RSBarChart";
import RSPieChart from "components/charts/RSPieChart";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { API_URL } from 'helpers/app_url';
import axios from "axios"
import { withRouter } from "react-router-dom";

class AgentDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedYear: 2025,
      agents: [],
      agentsTrans: [],
      monthSalesName: [],
      monthSalesCount: [],
      citySalesN: [],
      citySalesP: [],
      clients: [],
      agentName: "",
      searchClientTerm: '',      
      searchProjectTerm:''
    }
    this.viewDetailCanvas = this.viewDetailCanvas.bind(this);
    this.onSearchClientChang = this.onSearchClientChang.bind(this);         
    this.onSearchProjectChang = this.onSearchProjectChang.bind(this);
  }

  onSearchClientChang(event) {
    this.setState({ searchClientTerm: event.target.value });
  }

  onSearchProjectChang(event) {
    this.setState({ searchProjectTerm: event.target.value });
  }

  viewDetailCanvas() {
    this.setState({ isView: !this.state.isView });
  }
  // Column body with click event this.onColumnClick(rowData)
  nameTemplate = (rowData) => {
    return (
      <span
        style={{ cursor: 'pointer', color: 'blue' }}
        onClick={() => {
          const obj = JSON.parse(localStorage.getItem("authUser"));
          const token = obj.resultData.bearerToken;
          const str = 'Bearer ' + token;
          axios.get(`${API_URL}AgentExt/` + rowData.agentId, {
            headers: { Authorization: str }
          })
            .then(res => {
              this.setState({ agents: res.data });
            });
          this.setState({ agentsTrans: rowData });
          this.setState({ isView: true })
        }}
      >
        {rowData.agentName}
      </span>
    );
  };

  handleChange = (event) => {
    const path = window.location.pathname;
    const segments = path.split('/');
    const value = segments[2];

    this.setState({ selectedYear: event.target.value });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const { selectedYear } = this.state;
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(`${API_URL}client/clientCitiesMonthlySalesByOffice`, {
      params: {
        groupId: localStorage.getItem("userId"),
        agentId: value,
        year: selectedYear

      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ monthSalesName: res.data.months, monthSalesCount: res.data.counts });
      });
    axios.get(`${API_URL}client/clientCitiesDistributionByOffice`, {
      params: {
        groupId: localStorage.getItem("userId"),
        agentId: value,
        year: selectedYear

      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ citySalesN: res.data.cities, citySalesP: res.data.percentages });
      });
  };

  componentDidMount() {
    const path = window.location.pathname;
    const segments = path.split('/');
    const value = segments[2];

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(`${API_URL}client/clientCitiesMonthlySalesByOffice`, {
      params: {
        groupId: localStorage.getItem("userId"),
        agentId: value,
        year: 2025

      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ monthSalesName: res.data.months, monthSalesCount: res.data.counts });
      });
    axios.get(`${API_URL}client/clientCitiesDistributionByOffice`, {
      params: {
        groupId: localStorage.getItem("userId"),
        agentId: value,
        year: 2025

      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ citySalesN: res.data.cities, citySalesP: res.data.percentages });
      });

    axios.get(`${API_URL}client/clientListByOffice`, {
      params: {
        groupId: localStorage.getItem("userId"),
        agentId: value,
      }, headers: { Authorization: str }
    })
      .then(res => {
        console.log(res.data);
        this.setState({ clients: res.data });
      });


    axios.get(`${API_URL}AgentExt/` + value, {
      headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ agentName: res.data.firstName + " " + res.data.lastName });
      });

  }
  render() {
    const {searchProjectTerm,searchClientTerm,  monthSalesName, monthSalesCount, citySalesN, citySalesP, clients, agents, agentsTrans, agentName } = this.state;

    const filteredData = clients.filter(data => {
      
      const clientMatch = data.clientName && data.clientName.trim() !== ''
        ? data.clientName.toLowerCase().includes(searchClientTerm.toLowerCase())
        : false; // Exclude if null, undefined, or blank
    
      const projectMatch = data.projectName && data.projectName.trim() !== ''
        ? data.projectName.toLowerCase().includes(searchProjectTerm.toLowerCase())
        : false; // Exclude if null, undefined, or blank
    
      return clientMatch && projectMatch; // Use && if all terms are required
    });
    
    
    const barchartdata = {
      series: [
        {
          name: "Clients",
          data: monthSalesCount,
        }
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },

        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8", "#41B8D5"],
        xaxis: {
          categories: monthSalesName,
        },
      },
    };

    const leadSourceData = {
      series: citySalesP,
      options: {
        chart: {
          type: 'pie',
        },
        labels: citySalesN,
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Broker Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row className="project-header mb20">
              <Col xl="1">
                <a href="/agent" className="btn btn-primary" style={{ marginTop: '20px' }}>Back</a>
              </Col>
              <Col xl="6">
                <Breadcrumbs
                  title={this.props.t("Dachboard")}
                  breadcrumbItem={this.props.t("Agents Performance")}
                />
              </Col>
              <Col xl="5" >
                <h2 style={{ fontWeight: "bold", paddingTop: '20px', color: "#532A3E" }}>{agentName}</h2>
              </Col>
            </Row>
            <Row className="mt-20 mb-20">
              <Col sm="1">
                <select
                  name="year"
                  id="year"
                  className="form-control"
                  value={this.state.selectedYear}
                  onChange={this.handleChange}
                >
                  <option value="">Select Year</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </Col>
            </Row>
            <Row className="mb20">
              <Col lg={6}>

                <RSBarChart title="Monthly Sales" filter={false} height="370" series={barchartdata.series} options={barchartdata.options} />
              </Col>
              <Col lg={6}>
                <RSPieChart title="Client Cities Distribution" filter={false} height="420" series={leadSourceData.series} options={leadSourceData.options} />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="card-title mb-4 float-start">Client list</div>                    
                    <div className="row gy-2 gx-3 float-end mb20">
                      <div className="col-sm-auto">
                        <Input type="text" className="form-control" value={searchClientTerm} onChange={this.onSearchClientChang} id="autoSizingInputClient" placeholder="Search By Client Name" />
                      </div>
                    </div>
                    <div className="row gy-2 gx-3 float-end mb20">
                      <div className="col-sm-auto">
                        &nbsp;
                      </div>
                    </div>
                    <div className="row gy-2 gx-3 float-end mb20">
                      <div className="col-sm-auto">
                        <Input type="text" className="form-control" value={searchProjectTerm} onChange={this.onSearchProjectChang} id="autoSizingInputProject" placeholder="Search By Project Name" />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <DataTable value={filteredData} className="table table-bordered data-table align-middle table-nowrap  mb-4">                      
                      <Column field="clientName" header="Name" body={this.nameTemplate}></Column>
                      <Column field="transactionAmount" header="Deal Value" ></Column>
                      <Column field="commission" header="Commission" ></Column>
                      <Column field="closingDate" header="Closing Date" ></Column>
                      <Column field="city" header="City" ></Column>
                      <Column field="projectName" header="Project Name"></Column>
                      <Column field="clientType" header="Client Type"></Column>
                    </DataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>


        <Offcanvas
          isOpen={this.state.isView}
          direction="end"
          toggle={this.viewDetailCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.viewDetailCanvas}>
            Deal details
          </OffcanvasHeader>
          <OffcanvasBody>
            <Row className="mb-3">
              <Col sm={6}>
                <h5><strong>Agent :</strong> <span className="">{agentsTrans?.agentName}</span></h5>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>

            <Row className="mb-3">
              <Col sm={6}>
                <h5 className="main-heading"><strong>Principal applicant</strong></h5>
              </Col>

              <Col sm={6}>
                <h5 className="main-heading"><strong>Secondary applicant</strong></h5>
              </Col>

            </Row>

            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Name :</strong> <span className="">{agentsTrans?.agentName}</span></h6>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Address :</strong> <span className="">{agents?.agentAddress?.addressLine1},{agents?.agentAddress?.addressLine2},{agents?.agentAddress?.city},{agents?.agentAddress?.country}</span></h6>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Mobile :</strong> <span className="">{agents?.agentContact?.cContact?.mobile}</span></h6>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Secondary phone :</strong> <span className="">{agents?.agentContact?.cContact?.phone}</span></h6>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-3">
              <Col sm={6}>
                <h6><strong>Email :</strong> <span className="">{agents?.agentContact?.cContact?.email}</span></h6>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h5 className="main-heading"><strong>Deal Info</strong></h5>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Type :</strong> <span className="">{agentsTrans?.dealType}</span></h6>
              </Col>

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              {/* <Col sm={6}>
                        <h6><strong>Property address :</strong> <span className="">1815 Massachusetts Ave, Cambridge, MA 02140, USA</span></h6>
                      </Col> */}

              <Col sm={6}>

              </Col>

            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Project Name:</strong> <span className="">{agentsTrans?.projectName}</span></h6>
              </Col>

              <Col sm={6}>
                <h6><strong>Sale price :</strong> <span className="">{agentsTrans?.transactionAmount}</span></h6>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={6}>
                <h6><strong>Option :</strong> <span className="">{agentsTrans?.optionNumber}/{agentsTrans?.optionType}</span></h6>
              </Col>

              <Col sm={6}>
                <h6><strong>Inside SqFt/ Outside SqFt :</strong> <span className="">{agentsTrans?.inSideSqFt}/{agentsTrans?.outSideSqFt}</span></h6>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6}>
                <h6><strong>Allocation date :</strong> <span className="">{agentsTrans?.allocationDate}</span></h6>
              </Col>

              <Col sm={6}>
                <h6><strong>Closing date :</strong> <span className="">{agentsTrans?.closingDate}</span></h6>
              </Col>

            </Row>
            {/* 
        
                    <Row className="mb-2">
                      <Col sm={6}>
                        <h6><strong>Type :</strong> <span className="">Seller</span></h6>
                      </Col>
        
                      <Col sm={6}>
        
                      </Col>
        
                    </Row>
                    <Row className="mb-2">
                      <Col sm={6}>
                        <h6><strong>Property address :</strong> <span className="">1815 Massachusetts Ave, Cambridge, MA 02140, USA</span></h6>
                      </Col>
        
                      <Col sm={6}>
        
                      </Col>
        
                    </Row>
                    <Row className="mb-2">
                      <Col sm={6}>
                        <h6><strong>Sale price :</strong> <span className="">$69,9999</span></h6>
                      </Col>
        
                      <Col sm={6}>
        
                      </Col>
        
                    </Row>
                    <Row className="mb-3">
                      <Col sm={6}>
                        <h6><strong>Deal date :</strong> <span className="">Aug 10, 2024</span></h6>
                      </Col>
        
                      <Col sm={6}>
                        <h6><strong>Closing date :</strong> <span className="">Nov 14, 2024</span></h6>
                      </Col>
        
                    </Row>
                    <Row className="mb-2">
                      <Col sm={12}>
                        <h5><strong>Note</strong></h5>
                      </Col>
        
                    </Row> */}
          </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    )
  }
}
AgentDetail.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(AgentDetail))

