import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ProjectCount from "./ProjectCount";
import { getProjectUUIDAction} from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import ProjectChart from "./chart/project-dashboard";
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
  } from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "react-moment";

class Campaign extends Component {
    constructor(props) {
        super(props)
        this.state = {
          
			reports: [
        { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Performance",
          
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "# SRR",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "# Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "2",
        },
      ]
        }

     
      }

     
      componentDidMount() {

        const { match: { params }, ongetProjectUUIDAction } = this.props;
    
        if (params && params.projectId) {
          ongetProjectUUIDAction(params.projectId);
        }
      }  


      render() {
       
        const { projectData } = this.props;
        console.log(projectData)
        return (
          <React.Fragment>
              <div className="page-content project-page-content ">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7"  >
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Dashboard")} />
                </Col>
                <Col sm="4" className="">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
         

                <Row>
                  <Col md="9" className="pe-0">
                  <ProjectChart />
               
                <Card>
                <CardBody>
               <Row className="me-0">
                   
                   <Col lg="12">
                  
                      
                         
                           
                            
                               <h4 className="card-title mb-4">Activities </h4>

                               <Row className="mt20">
                               <Col md="12">
                                   

                                   <div className="table-rep-plugin">
                                               <div
                                                   className="table-responsive mb-0"
                                                   data-pattern="priority-columns"
                                               >
                                                  
                                                   <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={[{date:"2022-06-18T18:30:00",type:"Worksheet Uploaded ",email:"Demo@gamil.com "},{date:"2022-06-18T18:30:00",type:"Facebook Ad Spent ",email:"Demo@gamil.com "}]} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                                                      <Column  field="date" header="Date" body={(rowData) => (
                                                          rowData.date ? <Moment format="D MMM YY">{rowData.date}</Moment> : ''
                                                      )}></Column>
                                                      <Column  field="type" header="Type">  </Column>
                                                      <Column  field="email" header="Emails"></Column>
                                                  </DataTable>
                                               </div>
                                               </div>
                               </Col>
                         </Row>
                               
                               
                               
                          
                      
                   </Col>
               </Row>
               </CardBody>
               </Card>
               </Col>
               <Col sm="3" className="ps-0">
                <Card className="project-count-card">
                  <CardBody>
                  <ProjectCount />

                  </CardBody>

                </Card>
               </Col>
               </Row>
                <Row>
                   
                </Row>
                </Col>
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

Campaign.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
  }
  const mapStateToProps = ({ Project }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  });
export default connect( mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(Campaign)))
