import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class WebpageMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'landing-page',
                label: 'Landing Page',
                link: `/pre-construction/campaign/landing-page/${this.props.match.params.projectId}`
            },
            {
                id: 'worksheet',
                label: 'Worksheets Page',
                link: `/pre-construction/campaign/worksheet/${this.props.match.params.projectId}`
            },
            {
                id: 'open-house-page',
                label: 'Open House',
                link: `/pre-construction/campaign/open-house-page/${this.props.match.params.projectId}`
            },
            {
                id: 'download-price-page',
                label: 'Download Price',
                link: `/pre-construction/campaign/download-price-page/${this.props.match.params.projectId}`
            },

            {
                id: 'rsvp',
                label: 'Invite / RSVP',
                link: `/pre-construction/campaign/rsvp/${this.props.match.params.projectId}`
            },
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}

                />
            </React.Fragment>
        )
    }
}
WebpageMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(WebpageMenu)
