import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout";
import WorkSheetFront from "../pages/Clients/WorkSheet-form"

// Dashboard
import DashboardNew from "../pages/Dashboard/index"


import OurResource from "../pages/Office/Resource"
import Events from "../pages/Office/Events"
import Messages from "../pages/Office/message"

import Calendar from "../pages/Calendar";



//Transaction

import TransactionDashboard from "../pages/Transaction/index"
import ReportGoals from "../pages/Transaction/goal"

import UploadFile from "../pages/Lead/eligibility/upload-file";

//Project




import Project from "../pages/Pre-Construction/Projects"
import ProjectCompare from "../pages/Pre-Construction/Projects/compare"
import ProjectsCreate from "../pages/Pre-Construction/Projects/projects-create"
import ProjectUpdate from "../pages/Pre-Construction/Projects/projects-update"
import ProjectsFeature from "../pages/Pre-Construction/Projects/projects-feature"
import ProjectsContact from "../pages/Pre-Construction/Projects/projects-contact"
import ProjectsMedia from "../pages/Pre-Construction/Projects/projects-media"
import ProjectsGallery from "../pages/Pre-Construction/Projects/projects-gallery"
import ProjectLink from "../pages/Pre-Construction/Projects/projects-link"
import ProjectsMarketing from "../pages/Pre-Construction/Projects/projects-marketing"
import ProjectDetail from "../pages/Pre-Construction/Projects/project-detail"
import Campaign from "../pages/Pre-Construction/Projects/Campaign"
import ProjectIntegration from "../pages/Pre-Construction/Projects/Project-integration";
import ProjectLandingPage from "../pages/Pre-Construction/Projects/Project-landing-page";
import ProjectOpenHousePage from "../pages/Pre-Construction/Projects/Project-open-house-page";
import ProjectOpenHouseForm from "../pages/Pre-Construction/Projects/Project-open-house-form";
import ProjectDownloadPricePage from "../pages/Pre-Construction/Projects/Project-download-price-page";
import ProjectDownloadPriceForm from "../pages/Pre-Construction/Projects/Project-download-price-form";
import ProjectWorksheetForm from "../pages/Pre-Construction/Projects/Project-worksheet-form";
import ProjectWorksheetThank from "../pages/Pre-Construction/Projects/Project-worksheet-thank";

import projectTransferred from "../pages/Pre-Construction/Projects/ProjectTransferred";
import projectCurrent from "../pages/Pre-Construction/Projects/Current-Prospects";
import projectSrr from "../pages/Pre-Construction/Projects/project-srr";
import projectClient from "../pages/Pre-Construction/Projects/project-client";
import projectDownloadPrice from "../pages/Pre-Construction/Projects/project-download-price";
import projectMail from "../pages/Pre-Construction/Projects/project-mail";
import projectMarket from "../pages/Pre-Construction/Projects/project-market";
import srrCreate from "../pages/Pre-Construction/Projects/project-srr-create"
import landingPage from "../pages/Pre-Construction/Projects/landingPage";
//import createTemplate from "../pages/Pre-Construction/Projects/create-template";
import projectWorksheet from "../pages/Pre-Construction/Projects/project-worksheet"
import projectRsvp from "../pages/Pre-Construction/Projects/project-rsvp"
import massMailDashboard from "../pages/Pre-Construction/Projects/project-mail-dashboard"
import emaillandingPage from "../pages/Pre-Construction/Projects/project-email-landingpage"
import projectSelectTemp from "../pages/Pre-Construction/Projects/project-select-template-mail";
import projectMailEditor from "../pages/Pre-Construction/Projects/project-mail-editor"
import projectMailSubmit from "pages/Pre-Construction/Projects/project-mail-submit"
import projectLandingEditor from "../pages/Pre-Construction/Projects/landing-mail-editor"
import projectLandingSubmit from "../pages/Pre-Construction/Projects/landing-page-submit"
import projectsSetting from "../pages/Pre-Construction/Projects/projects-setting";

//Project Lead

import ProjectLead from "../pages/Pre-Construction/Lead/index"
import PendingWorksheetLead from "../pages/Pre-Construction/Lead/pending-worksheet"
import SubmittedWorksheetLead from "../pages/Pre-Construction/Lead/submitted-worksheet"

import ProjectClients from "../pages/Pre-Construction/Clients/index"





//Update Project
import ProjectsEdit from "../pages/Pre-Construction/Projects/editproject/projects-edit"
import ProjectEditFeature from "../pages/Pre-Construction/Projects/editproject/projects-feature-edit";
import ProjectEditContact from "../pages/Pre-Construction/Projects/editproject/projects-contact-edit";
import ProjectEditMedia from "../pages/Pre-Construction/Projects/editproject/projects-media-edit"
import ProjectsEditGallery from "../pages/Pre-Construction/Projects/editproject/projects-gallery-edit"
import ProjectEditMarketing from "../pages/Pre-Construction/Projects/editproject/projects-marketing-edit"
//Builder
import Builder from "../pages/Pre-Construction/Builder"
import BuilderDetail from "../pages/Pre-Construction/Builder/Builder-detail"
import BuilderCreate from "../pages/Pre-Construction/Builder/Builder-create"
import ProjectBuilderDetail from "../pages/Pre-Construction/Builder/Project-detail"



//Lead

import Dashboard from "../pages/Lead/index"
import LeadInsight from "../pages/Lead/lead-insight";
import LeadList from "../pages/Lead/Leadlist"
import LeadDetail from "../pages/Lead/Lead-detail"
import BuyerQuestionAnswer from "../pages/Lead/questions/buyer"
import SellerQuestionAnswer from "../pages/Lead/questions/seller"
import InvestorQuestionAnswer from "../pages/Lead/questions/investor"

import BuyerEligibility from "../pages/Lead/questions/buyer-eligibility"

import CreateLead from "../pages/Lead/forms/lead-create"
import AcquireLead from "../pages/Lead/acquire-lead";
import importLead from "../pages/Lead/import/import-lead";
import mapImportLead from "../pages/Lead/import/map-import-lead";
import FinalImportLead from "../pages/Lead/import/final-import-lead";
import webLeadForm from "../pages/Lead/web-lead-form";
import newWebLeadForm from "../pages/Lead/new-web-lead-form";
import newWebLeadFormDesign from "../pages/Lead/new-web-lead-form-design";
import LeadStagePage from "../pages/Lead/filter-menu/lead-stage";
import leadTimeline from "../pages/Lead/filter-menu/lead-timeline";
import leadPipeStatus from "../pages/Lead/lead-pipe-status";
import LeadEngageMentView from "../pages/Lead/filter-menu/lead-engagement-view";
import LeadStatusReport from "../pages/Lead/filter-menu/lead-report-status";
import LeadTypeReport from "../pages/Lead/filter-menu/lead-report-type";
import LeadGroupReport from "../pages/Lead/filter-menu/lead-report-group"
import LeadEligibility from "../pages/Lead/eligibility/index"
import DecisionEligibility from "../pages/Lead/eligibility/decision"
import MortgageEligibility from "../pages/Lead/eligibility/mortgage"
import ReasonEligibility  from "../pages/Lead/eligibility/reason"


//Activity

import Activity from "../pages/Activities"
import Cal from "../pages/Activities/cal";
import Appointment from "pages/Activities/Appointment"
import Notes from "pages/Activities/Notes"

import ourTask from "../pages/Task/index"
import TasksCreate from "../pages/Task/create-task"

//Contact


import Contacts from "../pages/Contacts"
import ImportContact from "../pages/Contacts/import/import-lead"
import MapImportContact from "../pages/Contacts/import/map-import-lead"
import FinalImportContact from "../pages/Contacts/import/final-import-lead"
import ContactDetail from "../pages/Contacts/Contact-detail"
import ContactBuyerQuestionAnswer from "../pages/Contacts/questions/buyer"
import ContactAdditionProfile from "../pages/Contacts/questions/profile"
import ContactSellerQuestionAnswer from "../pages/Contacts/questions/seller"
import ConatctInvestorQuestionAnswer from "../pages/Contacts/questions/investor"


import ContactReport from "../pages/Contacts/Contact-reporting"
import ContactCreate from "../pages/Contacts/contact-create"
import AllContacts from "../pages/Contacts/All"
import EditContact from "../pages/Contacts/contact-edit"
import ClassificationContact from "../pages/Contacts/filter-menu/classification"
import ClientActivity from "../pages/Contacts/filter-menu/activity"

import ContactReview from "../pages/Contacts/Contact-review"
import ContactRef from "../pages/Contacts/filter-menu/referral"

import ReviewHistory from "../pages/Contacts/review-history"
import EmailHistory from "../pages/Contacts/review-email-history"



//Email
import EmailComposer from "../pages/Email"
import EmailSec from "../pages/Email/email-sec"
import NewEmail from "../pages/Email/newemail"
//Clients

import Clients from "../pages/Clients"
import ImportClient from "../pages/Clients/import/import-client"
import MapImportClient from  "../pages/Clients/import/map-import-lead"
import FinalImportClient from  "../pages/Clients/import/final-import-lead"
import ClientCreate from "../pages/Clients/client-create";
import ClientDashbord from "../pages/Clients/client-dashboard"
import ClientProfile from "../pages/Clients/client-profile"
import clientSecProfile from "../pages/Clients/client-sec-profile";
import clientImpProfile from "../pages/Clients/client-imp-profile";
import clientFaimilyProfile from "../pages/Clients/client-faimily-profile";
import ClientMessage from "../pages/Clients/client-message"
import ClientDocument from "../pages/Clients/client-documents"
import ClientApp from "../pages/Clients/client-app"
import ClientDeal from "../pages/Clients/client-deal"
import ClientEngagement from "../pages/Clients/engagement"
import ClientNote from "../pages/Clients/client-note"
import ClientSale from "../pages/Clients/client-sale"
import ClientSalePref from "../pages/Clients/client-sale-pref"
import ClientSellerShow from "../pages/Clients/client-seller-showings"
import ClientSellerClose from "../pages/Clients/client-seller-closing"
import ClientSellerOpen from "../pages/Clients/client-seller-open"
import ClientSellerCheck from "../pages/Clients/client-seller-checklist"
import ClientSellerMarket from "../pages/Clients/client-seller-market"

import OpenList from '../pages/Clients/open-list'

import ClientLeadDetail from '../pages/Clients/client-open-lead'


import ClientBuyPref from "../pages/Clients/client-buyer-pref"
import ClientBuyerClose from "../pages/Clients/client-buyer-closing"
import ClientBuyerMarket from "../pages/Clients/client-buyer-market"
import ClientBuyerCheck from "../pages/Clients/client-buyer-checklist"
import ClientLeadForm from "../pages/Clients/Client-lead-form";


import ClientInvPref from "../pages/Clients/client-inv-pref"
import ClientInvProj from "../pages/Clients/client-inv-proj"
import ClientHomePref from "../pages/Clients/client-home-pref"

import clientListing from "../pages/Clients/client-listing";


import Test from "../pages/Contacts/test"

import ThemeOne from "../pages/Pre-Construction/Themes";
import ReferalSend from "../pages/Contacts/ReferalSend";
import ReferalTemplate from "../pages/Contacts/ReferalTemplate";
//import ReferalTemplateCreate from "../pages/Contacts/ReferalTemplateCreate";


import ReviewSend from "../pages/Contacts/ReviewSend";
import ReviewTemplate from "../pages/Contacts/ReviewTemplate";
//import ReviewTemplateCreate from "../pages/Contacts/ReviewTemplateCreate";

//Setting Page

import agentProfile from "../pages/Settings/agent-profile";
import GenralCurrency from "../pages/Settings/general/currency";
import EmailSignature from "../pages/Settings/general/signature";
import SocialMedia from "../pages/Settings/general/social-media";
import GenralSecurity from "../pages/Settings/general/security"
import GenralCalendar from "../pages/Settings/general/calender"
import EmailMapper from "../pages/Settings/general/emailmapper"
import memberInfo from "../pages/Settings/member-info";
import techSupport from "../pages/Settings/tech-support"
import settingObject from "../pages/Settings/setting-object";
import manageMarketing from "../pages/Settings/manage-marketing";
import reviewDistribution from "../pages/Settings/review-distribution";
import AppointmentLocationTypes from "../pages/Settings/AppointmentLocationTypes";
import AppointmentPriority from "../pages/Settings/AppointmentPriority";
import AppointmentType from "../pages/Settings/AppointmentType";
import ContactLevel from "../pages/Settings/ContactLevel";
import ContactStatus from "../pages/Settings/ContactStatus";
import ContactType from "../pages/Settings/ContactType";
import LeadClassifications from "../pages/Settings/LeadClassifications";
import LeadSource from "../pages/Settings/LeadSources";
import LeadStatus from "../pages/Settings/LeadStatuses";
import LeadType from "../pages/Settings/LeadTypes";
import LeadStage from "../pages/Settings/LeadStage";
import NoteType from "../pages/Settings/NoteTypes";
import ProjectDocumentType from "../pages/Settings/ProjectDocumentTypes";
import ProjectStatus from "../pages/Settings/ProjectStatuses";
import ProjectType from "../pages/Settings/ProjectTypes";
import ServiceType from "../pages/Settings/ServiceTypes";
import TaskPriority from "../pages/Settings/TaskPriorities";
import TaskReminder from "../pages/Settings/TaskReminders";
import TaskStatus from "../pages/Settings/TaskStatuses";
import TaskType from "../pages/Settings/TaskType";
import Users from "../pages/Settings/users";
import AddUser from "../pages/Settings/create-users";
import UserPermission from "../pages/Settings/permission"
import TransactionType from "../pages/Settings/TransactionType";
import TransactionCommType from "../pages/Settings/TransactionCommType";
import TransactionRefType from "../pages/Settings/TransactionRefType";
import TransactionStatusType from "../pages/Settings/TransactionStatusType";
import ListingType from "../pages/Settings/ListingTypes";
import ListingStatus from "../pages/Settings/ListingStatus";
import ShowingStatus from "../pages/Settings/ShowingStatus";
import EmailListType from "../pages/Settings/EmailListTypes";
import EmailListTarget from "../pages/Settings/EmailListTarget";
import CampaignEmailList from "../pages/Settings/CampaignEmailList";

//All Templates

import ourTemplates from "../pages/Templates/index";
import ReferalTemp from "../pages/Templates/referal-template"
import ReviewTemp from "../pages/Templates/review-template"
//import createNewTemplate from "../pages/Templates/create-new"
//import createNewLandinTemplate from "../pages/Templates/create-landinpage"
import formDesign from "../pages/Templates/form-design";



import CreateComp from "../pages/Campaign/index"
import CreateCompTemp from "../pages/Campaign/create"
import CreateCompAutoTemp from "../pages/Campaign/create-autoload"
import chooseTemplateComp from "../pages/Campaign/choose"
//import compEditor from "../pages/Campaign/editor";
import formDesignEdit from "../pages/Campaign/form-design-edit";
import compDetail from "../pages/Campaign/comp-detail";
import ReviewCamp from "../pages/Campaign/review"
import SurveyCamp from "../pages/Campaign/survey"
import ReffralCamp from "../pages/Campaign/referral"
import NewsletterCamp from "../pages/Campaign/newsletter"
import SellerCamp from "../pages/Campaign/seller"

import Calender from "../pages/Calendar/index"

import CollectionDashboard from "../pages/Collections/index"
import CollectionList from "../pages/Collections/collectionList"


//import masterLandingEditor from "../pages/Master/master-landing-editor";


//import createProjectEmailMaster from "../pages/Templates/create-email-master"
import CustomTemplates from "../pages/Editor/custome-template";
import UnlayerEditor from "../pages/Editor/index"
import LandingEditor from "../pages/Editor/landing" 

import Sendcamp from "../pages/Campaign/send-camp";

import ProjectInviteUser from "../pages/Pre-Construction/Projects/Project-Invite-User"
import projectInviteEditor from "../pages/Pre-Construction/Rsvp/project-invite-editor"
import SendInvite from "../pages/Pre-Construction/Rsvp/send-invite"

import MasterEmail from "../pages/Editor/master-email"

import PreviewLandingPage from "../pages/Pre-Construction/landing-page/preview-landing-page";
import editorLandingpage from "../pages/Pre-Construction/landing-page/editor-landingpage";
import PublishPage from "../pages/Pre-Construction/landing-page/publish-page";
import finalImportLead from "../pages/Lead/import/final-import-lead";

import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read"
import EmailSent from "../pages/Email/email-sent"
import GmailLanding from "../pages/Email/gmail"
import GoogleLanding from "../pages/Calendar/google"


//Branch

import OurBranch from "../pages/Branch/branch"
import projectsAssign from "pages/Pre-Construction/Projects/projects-assign";
//import worksheetStatus from "pages/Pre-Construction/Projects/worksheet-status";

import agentDashboard from "pages/Agent/index"
import agentDetail from "pages/Agent/detail"
import brokerClient from "pages/Client/client"
import EmailAssign from "pages/Email/email-assign";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardNew },
  { path: "/test", component: Test },
  { path: "/cal", component: Cal },
  { path: "/pre-construction/create-project/description/:projectId", component: ProjectUpdate },
  { path: "/pre-Construction/create-project/feature/:projectId", component: ProjectsFeature },
  { path: "/pre-Construction/create-project/contact/:projectId", component: ProjectsContact },
  { path: "/pre-Construction/create-project/media/:projectId", component: ProjectsMedia },
  { path: "/pre-Construction/create-project/link/:projectId", component: ProjectLink },
  { path: "/pre-Construction/create-project/gallery/:projectId", component: ProjectsGallery },
  { path: "/pre-Construction/create-project/assign/:projectId", component: projectsAssign },
  { path: "/pre-Construction/create-project/marketing/:projectId", component: ProjectsMarketing },
  { path: "/pre-construction/campaign/marketing/:projectId", component: projectMarket },
  { path: "/pre-construction/campaign/srr-filled/:projectId", component: projectSrr },
  //{ path: "/pre-construction/campaign/worksheet-status/:projectId", component: worksheetStatus },
  
 
  

  { path: "/pre-construction/campaign/rsvp/:projectId", component: projectRsvp },
  { path: "/pre-construction/campaign/transferred/:projectId", component: projectTransferred },  
  { path: "/pre-construction/campaign/current-prospects/:projectId", component: projectCurrent },
  { path: "/pre-construction/campaign/client/:projectId", component: projectClient },
  { path: "/pre-construction/campaign/download-price/:projectId", component: projectDownloadPrice },
  { path: "/pre-construction/campaign/integration/:projectId", component: ProjectIntegration },
  { path: "/pre-construction/campaign/send-email/:projectId", component: projectMail },
  { path: "/pre-construction/campaign/landing-page/:projectId", component: ProjectLandingPage },
  { path: "/pre-construction/campaign/worksheet/:projectId", component: projectWorksheet },
  { path: "/pre-construction/campaign/open-house-page/:projectId", component: ProjectOpenHousePage },
  { path: "/pre-construction/campaign/download-price-page/:projectId", component: ProjectDownloadPricePage },
  { path: "/pre-construction/campaign/setting/:projectId", component: projectsSetting },
  { path: "/Pre-Construction/campaign/:projectId", component: Campaign },
 
 
  { path: "/pre-construction/mail-submit/:projectId", component: projectMailSubmit },
  { path: "/pre-construction/mail-editor/:projectId/:templateId", component: projectMailEditor },
  { path: "/pre-construction/invite-editor/:invId/:templateId", component: projectInviteEditor },
  { path: "/pre-construction/landing-editor/:projectId/:templateId", component: editorLandingpage },
  { path: "/pre-construction/publish-page/:id/:projectId/:templateId", component: PublishPage },
  //{ path: "/pre-Construction/create-project/payment", component: ProjectsPayment },
  { path: "/pre-construction/mail-send/:projectId/:campId", component: Sendcamp },
  { path: "/pre-construction/invite-send/:invId/:campId", component: SendInvite },


  { path: "/pre-construction/builder/create", component: BuilderCreate },
  { path: "/Pre-Construction/my-project", component: Project },
  { path: "/Pre-Construction/compare-project", component: ProjectCompare },
  { path: "/Pre-Construction/create-new-project", component: ProjectsCreate },

  //{ path: "/pre-construction/builder/builder-detail", component: BuilderDetail },
  { path: "/pre-construction/builder/builder-detail/:id/:builderId", component: BuilderDetail },
  { path: "/pre-construction/builder/project-detail/:id/:builderId", component: ProjectBuilderDetail },
  //{ path: "/Pre-Construction/project-detail", component: ProjectDetail },
  { path: "/Pre-Construction/project-detail/:id", component: ProjectDetail },

  
  { path: "/pre-construction/campaign/send-email", component: projectMail },
  

  

 
  { path: "/Pre-Construction/srr-create", component: srrCreate },

  { path: "/landing-page", component: landingPage },
  //{ path: "/create-theme", component: createTemplate },
  { path: "/pre-construction/builder", component: Builder },
  { path: "/pre-construction/campaign/mass-email", massMailDashboard },
  { path: "/pre-construction/email/create-new", component: projectSelectTemp },
  { path: "/pre-construction/email", component: emaillandingPage },
 
  
  { path: "/pre-construction/landing-editor", component: projectLandingEditor },
  { path: "/pre-construction/landing-page-submit", component: projectLandingSubmit },


  //Project Lead
  
  {path:"/pre-construction/project/lead/worksheet-pending", component:PendingWorksheetLead },
  {path:"/pre-construction/project/lead/worksheet-submitted", component:SubmittedWorksheetLead },
  {path:"/pre-construction/project/lead", component:ProjectLead },
  
  {path:"/pre-construction/project/clients", component:ProjectClients },



  //Update Project


  { path: "/pre-Construction/edit-project/feature/:id/:projectId", component: ProjectEditFeature },
  { path: "/pre-Construction/edit-project/contact/:id/:projectId", component: ProjectEditContact },
  { path: "/pre-Construction/edit-project/media/:id/:projectId", component: ProjectEditMedia },
  { path: "/pre-Construction/edit-project/gallery/:id/:projectId", component: ProjectsEditGallery },
  { path: "/pre-Construction/edit-project/marketing/:id/:projectId", component: ProjectEditMarketing },
  { path: "/pre-Construction/edit-project/:id/:projectId", component: ProjectsEdit },

  { path: "/lead/lead-detail/:id/:leadId", component: LeadDetail },
  { path: "/lead/buyer-preference/:id/:leadId", component: BuyerQuestionAnswer },
  { path: "/lead/buyer-eligibility/:id/:leadId", component: BuyerEligibility },
  {path: "/lead/selling-preferences/:id/:leadId", component: SellerQuestionAnswer },
  {path: "/lead/investment-preferences/:id/:leadId", component: InvestorQuestionAnswer },
  
  { path: "/lead/report/pipeline-status", component: leadPipeStatus },
  { path: "/lead/report/activity-status", component: LeadEngageMentView },
  { path: "/lead/eligibility/question", component: LeadEligibility },
  { path: "/lead/eligibility/decision", component: DecisionEligibility },
  { path: "/lead/eligibility/mortgage", component: MortgageEligibility },
  { path: "/lead/eligibility/reason", component: ReasonEligibility },

  
  { path: "/lead/dashboard", component: Dashboard },
  { path: "/lead/insight", component: LeadInsight },
  { path: "/lead/lead-create", component: CreateLead },
  { path: "/lead/leadlist", component: LeadList },
  { path: "/lead/stage-view", component: LeadStagePage },
  { path: "/lead/timeline-view", component: leadTimeline },
  { path: "/lead/status-view", component: LeadStatusReport },
  { path: "/lead/type-view", component: LeadTypeReport },
  { path: "/lead/group-view", component: LeadGroupReport },
  
  { path: "/lead/acquire-lead", component: AcquireLead },
  { path: "/lead/import-lead", component: importLead },
  { path: "/lead/map-import-lead", component: mapImportLead },
  { path: "/lead/import-lead-submitted", component: FinalImportLead },
  { path: "/lead/web-form", component: webLeadForm },
  { path: "/lead/new-web-form", component: newWebLeadForm },

  { path: "/lead/form-design", component: newWebLeadFormDesign },



 

  { path: "/communication/message", component: Messages },
  { path: "/communication/resource", component: OurResource },
  { path: "/communication/events", component: Events },

 

  { path: "/all-templates", component: ourTemplates },
  { path: "/ref-templates/:type", component: ReferalTemp },
  { path: "/review-templates", component: ReviewTemp },
  //{ path: "/create/:type/:page", component: createNewTemplate },
  //{ path: "/create-landingpage/:type/:page", component: createNewLandinTemplate },
  { path: "/form-design/:type/:page", component: formDesign },

  { path: "/calendar", component: Calendar },

  { path: "/campaign", component: CreateComp },
  { path: "/reviews", component: ReviewCamp },
  { path: "/surveys", component: SurveyCamp },
  { path:"/referral", component:ReffralCamp},
  { path:"/newsletter", component:NewsletterCamp},
  { path:"/seller", component:SellerCamp},
  { path: "/create-campaign/:campType", component: CreateCompTemp },
  { path: "/create-new-campaign/:campType/:name", component: CreateCompAutoTemp },
  { path: "/create-campaign", component: CreateCompTemp },
  
  { path: "/choose-contact/:templateId", component: chooseTemplateComp },
  { path: "/campaign-detail/:campType", component: compDetail },

  //{path: "/editor/:templateId/:campId/:campuuid", component: compEditor },
  //{ path: "/editor/:templateId", component: compEditor },
  { path: "/form-editor", component: formDesignEdit },


  { path: "/collection/list", component: CollectionList },
  { path: "/collection", component: CollectionDashboard },


 
 
 { path: "/email-inbox", component: EmailInbox },
 { path: "/email-read/:id", component: EmailRead },
 { path: "/email-assign/:id", component: EmailAssign },

 { path: "/branches", component: OurBranch },

 { path: "/agent", component: agentDashboard },
 { path: "/agent-detail", component: agentDetail },
 { path: "/client", component: brokerClient },

 { path: "/activity", component: Activity },

   // Agent Setting Page
   { path: "/setting/object/manage-marketing", component: manageMarketing },
   { path: "/setting/object/review-distribution", component: reviewDistribution },
   { path: "/setting/object/app-location-type", component: AppointmentLocationTypes },
   { path: "/setting/object/app-priority", component: AppointmentPriority },
   { path: "/setting/object/app-type", component: AppointmentType },
   { path: "/setting/object/contact-level", component: ContactLevel },
   { path: "/setting/object/contact-status", component: ContactStatus },
   { path: "/setting/object/contact-type", component: ContactType },
   { path: "/setting/object/lead-classification", component: LeadClassifications },
   { path: "/setting/object/lead-source", component: LeadSource },
   { path: "/setting/object/lead-status", component: LeadStatus },
   { path: "/setting/object/lead-type", component: LeadType },
   { path: "/setting/object/lead-stage", component: LeadStage },
   { path: "/setting/object/note-type", component: NoteType },
   { path: "/setting/object/project-doc-type", component: ProjectDocumentType },
   { path: "/setting/object/project-status", component: ProjectStatus },
   { path: "/setting/object/project-type", component: ProjectType },
   { path: "/setting/object/service-type", component: ServiceType },
   { path: "/setting/object/task-priority", component: TaskPriority },
   { path: "/setting/object/task-reminder", component: TaskReminder },
   { path: "/setting/object/task-status", component: TaskStatus },
   { path: "/setting/object/task-type", component: TaskType },
   { path: "/setting/object/transaction-type", component: TransactionType },
   { path: "/setting/object/transaction-comm-type", component: TransactionCommType },
   { path: "/setting/object/transaction-ref-type", component: TransactionRefType },
   { path: "/setting/object/transaction-status-type", component: TransactionStatusType },
   { path: "/setting/object/listing-type", component: ListingType },
   { path: "/setting/object/listing-status", component: ListingStatus },
   { path: "/setting/object/showing-status", component: ShowingStatus },
   { path: "/setting/object/email-list-type", component: EmailListType },
   { path: "/setting/object/email-list-target", component: EmailListTarget },
   { path: "/setting/object/campaign-email-list", component: CampaignEmailList },
 
 
   { path: "/setting/agent-profile", component: agentProfile },
   { path: "/setting/general/currency", component: GenralCurrency },
   { path: "/setting/general/signature", component: EmailSignature },
   { path: "/setting/general/social-media", component: SocialMedia },
   { path: "/setting/general/security", component: GenralSecurity },
   { path: "/setting/general/calendar", component: GenralCalendar },
   { path: "/setting/general/emailmapper", component: EmailMapper },
   
   
   
   { path: "/setting/membership", component: memberInfo },
   { path: "/setting/support", component: techSupport },
   { path: "/setting/object", component: settingObject },
   { path: "/setting/users", component: Users },
   { path: "/setting/add-user", component: AddUser },
   { path: "/setting/permission", component: UserPermission },
 
   //Contact 

   { path: "/Contacts", component: Contacts },

   { path: "/all-contacts", component: AllContacts },
   { path: "/contact/contact-detail/:id/:contactid", component: ContactDetail },
   { path: "/contact/buyer-preference/:id/:contactid", component: ContactBuyerQuestionAnswer },
   { path: "/contact/additional-profile/:id/:contactid", component: ContactAdditionProfile },
   { path: "/contact/selling-preferences/:id/:contactid", component: ContactSellerQuestionAnswer },
   { path: "/contact/investment-preferences/:id/:contactid", component: ConatctInvestorQuestionAnswer },
   
   { path: "/contact/import-contact", component: ImportContact },
   { path: "/contact/map-import-contact", component: MapImportContact },
   { path: "/contact/import-contact-submitted", component: FinalImportContact },
   { path: "/contact/contact-report", component: ContactReport },
   { path: "/contact/contact-create", component: ContactCreate },
   { path: "/contact/contact-edit", component: EditContact },
   { path: "/contact/classification-view", component: ClassificationContact },
   { path: "/contact/activity", component: ClientActivity },
   { path: "/contact/referals", component: ContactRef },
   { path: "/contact/review", component: ContactReview },
   { path: "/contact/review-history", component: ReviewHistory },
   { path: "/contact/email-history", component: EmailHistory },
 
   { path: "/email-inbox", component: EmailInbox },
   { path: "/email-sent", component: EmailSent },
   { path: "/email-read/:id", component: EmailRead },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  

]

export { authProtectedRoutes, publicRoutes }
