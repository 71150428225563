import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle  } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import axios from "axios";
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from 'helpers/azureConfig';
import { loadlandingMasterTemplate as onGetTempById, getProjectUUIDAction as ongetProjectUUIDAction } from "../../../store/actions";

const LoadUnlayer = forwardRef(({ design }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectImageCallback, setSelectImageCallback] = useState(null);
  const editorRef = useRef(null);
  const { id, projectId, templateId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { landingMasterData, projectData, loading } = useSelector(state => ({
    landingMasterData: state.Template.landingMasterData,
    loading: state.Template.loading,
    projectData:state.Project.projectData
  }));
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (projectId && templateId && userId) {
      dispatch(onGetTempById(projectId, templateId, userId));
      dispatch(ongetProjectUUIDAction(projectId));
    }
  }, [dispatch, projectId, templateId, localStorage.getItem('userId')]);
  

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://editor.unlayer.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.unlayer.init({
        id: 'editor',
        projectId: 228097,
        displayMode: 'web',
        user: {
          id: 1
        },
        features: {
          stockImages: {
            enabled: false,
          },
          textEditor: {
            tables: true
          },
          sendTestEmail: true
        }
      });

      window.unlayer.registerProvider('userUploads', function (params, done) {
        const page = 1;
        const perPage = 50;
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        fetch(`https://rscoreapi.azurewebsites.net/api/ProjectGallery/allproject/?projectId=${id}&page=${page}&perPage=${perPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': str
          }
        })
          .then(response => response.json())
          .then(data => {
            const images = data.map((image, i) => ({
              id: image.id,
              location: image.projectGalleryLink, 
              width: 200,
              height: 200, 
              contentType: 'image/png',
              source: 'user'
            }));

            const total = data.total;
            const hasMore = total > page * perPage;

            done(images, { hasMore, page, perPage, total });
          })
          .catch(error => {
            console.error('Error fetching images:', error);
            done([], { hasMore: false, page, perPage, total: 0 });
          });
      });

      window.unlayer.registerCallback('image', async (file, done) => {
        const attachment = file.attachments[0];
        const sasToken = azureConfig.sasToken;
        const containerName = azureConfig.containerName;
        const storageAccountName = azureConfig.storageAccountName;
        const fileName = `${Date.now()}_${attachment.name}`;
        try {
          const blobServiceClient = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
          );
          const containerClient = blobServiceClient.getContainerClient(containerName);
          const blobClient = containerClient.getBlockBlobClient(fileName);
          console.log(file);
          await blobClient.uploadBrowserData(attachment);
          const fullPath = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
          done({ progress: 100, url: fullPath });
        } catch (error) {
          console.error('Error uploading file to Azure Blob Storage:', error);
        }
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [id]);

  useEffect(() => {
    if (landingMasterData && landingMasterData.projectTemplatePreContent) {
      const templateContent = landingMasterData.projectTemplatePreContent.templateContent;
      const templateCss = landingMasterData.projectTemplatePreContent.templateCSS;
      if (templateContent) {
        try {
        
          const parsedContent = JSON.parse(templateContent);
          window.unlayer.loadDesign(parsedContent);
        } catch (error) {
          console.error('Error parsing template content:', error);
        }
      }
    }
  }, [landingMasterData]);

  const fetchImages = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const response = await axios.get('https://rscoreapi.azurewebsites.net/api/ProjectGallery/allproject/', {
        headers: {
          'Authorization': str
        },
        params: {
          projectId: projectData.id
        }
      });
      
      const images = response.data.map(image => ({
        src: image.projectGalleryLink,
        alt: image.projectGalleryTitle
      }));

      setImageList(images);
    } catch (error) {
      console.error('Error fetching images', error);
    }
  };

  const handleImageSelect = (src) => {
    if (selectImageCallback) {
      selectImageCallback({ url: src });
      setIsModalOpen(false);
    }
  };

  const exportHtml = (callback) => {
    if (window.unlayer) {
      window.unlayer.exportHtml((data) => {
        if (callback) {
          callback(data);
        }
      });
    }
  };
  useImperativeHandle(ref, () => ({
    exportHtml: (callback) => {
      if (window.unlayer) {
        window.unlayer.exportHtml((data) => {
          if (callback) {
            callback(data);
          }
        });
      }
    }
  }));
  const saveDesign = () => {
    try {
      if (window.unlayer && editorRef.current) {
        const designState = window.unlayer.saveDesign();
        return designState;
      }
      return null;
    } catch (error) {
      console.error('Error saving design:', error);
      return null;
    }
  };

  return (
    <div>
      <div id="editor" style={{ height: '800px' }} />
      {/* <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
          Select an Image
        </ModalHeader>
        <ModalBody>
          <div className="images">
            {imageList.map((image) => (
              <img
                key={image.src}
                src={image.src}
                alt={image.alt}
                onClick={() => handleImageSelect(image.src)}
                style={{ cursor: 'pointer', margin: '5px', maxWidth: '100px' }}
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal> */}
    </div>
  );
});

LoadUnlayer.displayName = 'LoadUnlayer';

LoadUnlayer.propTypes = {
  design: PropTypes.shape({
    html: PropTypes.string.isRequired
  }),
};

export default LoadUnlayer;
