import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import ProjectTabNavigation from "components/tablink/projecttablink"

class SetupMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const { helpmenu } = this.props;
        const navItems = [
            {
                id: 'description',
                label: 'Description',
                link: `/pre-construction/create-project/description/${this.props.match.params.projectId}`
            },
            {
                id: 'feature',
                label: 'Features',
                link: `/pre-construction/create-project/feature/${this.props.match.params.projectId}`
            },          
            {
                id: 'media',
                label: 'Documents',
                link: `/pre-construction/create-project/media/${this.props.match.params.projectId}`
            },
            {
                id: 'link',
                label: 'Document links',
                link: `/pre-construction/create-project/link/${this.props.match.params.projectId}`
            },
            {
                id: 'gallery',
                label: 'Gallery',
                link: `/pre-construction/create-project/gallery/${this.props.match.params.projectId}`
            },
            {
                id: 'contact',
                label: 'Contacts',
                link: `/pre-construction/create-project/contact/${this.props.match.params.projectId}`
            },
            {
                id: 'assign',
                label: 'Project Assign',
                link: `/pre-construction/create-project/assign/${this.props.match.params.projectId}`
            }
        ];
        return (
            <React.Fragment>
                <ProjectTabNavigation navItems={navItems}

                />
               
            </React.Fragment>
        )
    }
}
SetupMenu.propTypes = {
    match: PropTypes.object,
    helpmenu:PropTypes.object
}
export default withRouter(SetupMenu)
