import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';


const TabNavigation = ({ navItems }) => {
  const location = useLocation();

  return (
    <Row>
      <Col lg="12">
        <Nav tabs className="projectTab projectNav">
       {/* <NavItem>
              <Link 
                className={`nav-link active-help`} 
                to="#"
              >
                <span className="d-none d-sm-block"><i className='bx bxs-right-arrow' style={{verticalAlign:'middel'}}></i></span>
              </Link>
            </NavItem>  */}
          {navItems.map((item, index) => (
            <NavItem key={index}>
              <Link 
                className={`nav-link ${location.pathname.includes(item.id) ? 'active' : ''}`} 
                to={item.link}
              >
                <span className="d-none d-sm-block">{item.label}</span>
              </Link>
            </NavItem>
          ))}
        </Nav>
      </Col>
     
    </Row>
  );
};

TabNavigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default TabNavigation;
