import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import PermissionAlert from "../../components/Common/permissionalert";
import RsCard from "components/Rscards/Rscard";
import RSPieChart from "components/charts/RSPieChart";
import RSBarChart from "components/charts/RSBarChart";
import RsAdNew from "components/buttons/Adnew";
import CreateLead from './lead-create'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  Input,
  CardBody,
  CardTitle,
  Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Label
} from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { API_URL } from 'helpers/app_url';
import axios from "axios"
import Moment from 'react-moment';
import {

  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from "reactstrap"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      LeadData: [],
      topAgents: [],
      LeadSourceTitles: [],
      LeadPercentages: [],
      searchTerm: '',
      reports: [
        { title: "Won vs lost %", iconClass: "bx bx-copy-alt", description: "5" },

        {
          title: "Hot leads",
          iconClass: "bx bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Total Leads",
          iconClass: "bx bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Pipeline value",
          iconClass: "bx bx-plus-medical",
          description: "20",
        },
      ]
    }

    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.tranferCanvas = this.tranferCanvas.bind(this);
    this.onSearchChang = this.onSearchChang.bind(this);
  }
  onSearchChang(event) {
    this.setState({ searchTerm: event.target.value });
  }

  componentDidMount() {
    //'https://rscoreapi.azurewebsites.net/api/ProjectEmailTemplate/alltemplates' 0;//parseInt(localStorage.getItem("userId")),
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get(`${API_URL}AgentExt/topFiveAgentByOffice`, {
      params: {
        clientId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ topAgents: res.data });
      });

    axios.get(`${API_URL}Lead/leadListByOffice`, {
      params: {
        groupId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ LeadSourceTitles: res.data.leadSourceTitles, LeadPercentages: res.data.leadPercentages });
      });

    axios.get(`${API_URL}Lead/allforabroker`, {
      params: {
        brokerId: localStorage.getItem("userId"),
      }, headers: { Authorization: str }
    })
      .then(res => {
        this.setState({ LeadData: res.data });
      });
  }
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });

  }
  tranferCanvas() {
    this.setState({ isTransfer: !this.state.isTransfer });
  }
  render() {
    const {searchTerm, topAgents, LeadPercentages, LeadSourceTitles, LeadData } = this.state;

    const filteredData = LeadData.filter(data => {
      const nameMatch = data.firstName && data.firstName.trim() !== ''
        ? data.firstName.toLowerCase().includes(searchTerm.toLowerCase())
        : false; // Exclude if null, undefined, or blank    
      return nameMatch; // Use && if all terms are required
    });
    
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const userModule = localStorage.getItem('userModule');
    const leadSourceData = {
      series: LeadPercentages,
      options: {
        chart: {
          type: 'pie',
        },
        labels: LeadSourceTitles,
        colors: ["#6CE5E8", "#41B8D5", "#2D8BBA", "#2F5F98"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };


    return (
      <React.Fragment>

        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            <Row className="project-header">
              <Col xl="7">
                <Breadcrumbs
                  title={this.props.t("Lead")}
                  breadcrumbItem={this.props.t("Lead Dashboard")}
                />
              </Col>
              <Col xl="5">
                <div className="pt-2 float-end mt-2">
                  <div className="">

                    <RsAdNew to="/lead/import-lead" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Lead</RsAdNew>
                    <RsAdNew to="#" onClick={this.toggleAddNew} className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
                  </div>

                </div>
              </Col>
            </Row>
            <Row className="mt-20 mb-20">

              <Col xl="12">

                {/* <Row className="mb20" style={{marginTop:20}}> 
            {this.state.reports.map((report, key) => (
            <Col md="3" className="" key={key}>
                <RsCard title={report.title} description={report.description} iconClass={report.iconClass} />
              </Col>
            ))}
            </Row> */}


                <Row>
                  <Col lg={6}>
                    <RSPieChart title="Lead Source" filter={false} height="310" series={leadSourceData.series} options={leadSourceData.options} />
                  </Col>
                  <Col lg="6">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title mb-4 float-start">Top 5 lead converting agents</div>
                        <div className="clearfix"></div>
                        <DataTable value={topAgents} className="table table-bordered data-table align-middle table-nowrap  mb-4">
                          <Column field="firstName" header="Name"></Column>
                          <Column field="phone" header="Phone" ></Column>
                          <Column field="primaryEmail" header="Email" ></Column>
                          <Column field="city" header="Branch" ></Column>
                          <Column field="noOfLead" header="#Office leads" ></Column>
                          <Column field="noOfClient" header="#Clients"></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Col>

                </Row>

              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 float-start">Leads</CardTitle>
                    <div className="row gy-2 gx-3 float-end mb20">
                      <div className="col-sm-auto">                        
                        <Input type="text" className="form-control" value={searchTerm} onChange={this.onSearchChang} id="autoSizingInputProject" placeholder="Search By Name" />
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    <DataTable value={filteredData} className="table table-bordered data-table align-middle table-nowrap  mb-4" paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   ">
                      <Column field="firstName" header="Name"></Column>
                      <Column field="phone" header="Phone" ></Column>
                      <Column field="email" header="Email" ></Column>
                      <Column field="lastContact" header="Last Contact" body={(rowData) => (
                        rowData.lastContact ? <Moment format="D MMM YY">{rowData.lastContact}</Moment> : ''
                      )}></Column>
                      <Column field="leadSourceId" header="Source" body={(rowData) => (
                        rowData.leadSourceId ? localization["LeadSource_" + rowData.leadSourceId] : 'N/A'
                      )}></Column>
                      <Column field="leadStatusId" header="Status" body={(rowData) => (
                        rowData.leadStatusId ? localization["LeadStatus_" + rowData.leadStatusId] : 'N/A'
                      )}></Column>
                      <Column header="Action" body={(rowData) => (
                        <UncontrolledDropdown direction="up">
                          <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                            Action <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem tag={Link} to={"/lead/lead-detail/" + rowData.id + "/" + rowData.leadId}>Edit</DropdownItem>
                            {userModule.indexOf("BrokerAgent.RealtorLeads.Delete") > -1 ?
                              <DropdownItem href="#" onClick={() => this.onClickDelete(rowData)}>Delete</DropdownItem>
                              : ''
                            }

                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}></Column>
                    </DataTable>


                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>


        <Offcanvas
          isOpen={this.state.isAddNew}
          direction="end"
          toggle={this.toggleAddNew}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleAddNew}>
            New Lead
          </OffcanvasHeader>
          <OffcanvasBody>
            <CreateLead toggleAddNew={this.toggleAddNew} />
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isTransfer}
          direction="end"
          toggle={this.tranferCanvas}
        >
          <OffcanvasHeader toggle={this.tranferCanvas}>
            Transfer Lead
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                agentId: '',


              }}
              validationSchema={Yup.object().shape({

                agentId: Yup.string().required("This Field in required"),
              })}

              onSubmit={this.handleTransferSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Created by  :</strong> <span className="">Rohit Grover</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Source  :</strong> <span className="">Website</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Contact info  :</strong> <span className=""></span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Name  :</strong> <span className="">Hoe Alva</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Address  :</strong> <span className="">1815 Ironstone Manor, Pickering, ON L1W 3W9, Canada</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Mobile  :</strong> <span className="">1234567890</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Secondary phone  :</strong> <span className="">9876543210</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Email :</strong> <span className="">mohit@esimplified.ca</span></h6>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={12}>
                      <h6><strong>Notes :</strong> <span className=""></span></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="eventTitle">
                          {this.props.t("Transfer to agent")}
                        </Label>
                        <Field
                          name="eventTitle"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.eventTitle && touched.eventTitle
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="eventTitle"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>



                  </Row>
                  <div><button type="submit" className="btn btn-primary">Save</button></div>

                </Form>
              )}
            </Formik>

          </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    )
  }
}
Dashboard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(Dashboard))
